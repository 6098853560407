import { useCallback, useEffect, useRef, useState } from 'react';
import { InputNumber, Button } from 'antd';
import styles from './when-panel.module.scss';
import { CalendarOutlined, LeftOutlined, MinusOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import toast from '@/utils/toast.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useResponsive } from 'ahooks';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const Dates = 'Dates';
const Flexible = 'Flexible';
const types = [
  { value: Dates, label: 'Choose dates' },
  { value: Flexible, label: 'I’m flexible' }
];
const months = ['March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January', 'February'];

function WhenPanel({ onChange, initialDays = 7, values = {} }) {
  const [type, setType] = useState('Dates');
  const [month, setMonth] = useState(dayjs().format('MMMM'));
  const [days, setDays] = useState(initialDays);

  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);
  const { md } = useResponsive();
  const [range, setRange] = useState({ from: null, to: null });
  const [hoveredDate, setHoveredDate] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (values?.start_date) {
      if (dayjs(values?.start_date).isValid()) {
        setType(Dates);
        setRange({
          from: dayjs(values.start_date).toDate(),
          to: dayjs(values.start_date)
            .add(values.duration_in_days - 1, 'days')
            .toDate()
        });
      } else {
        setType(Flexible);
        setMonth(values.start_date);
        setDays(values.duration_in_days);
      }
    }
    const month = dayjs().month();
    if (month >= 8 || month <= 2) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slideNext();
      setIsEnd(true);
      setIsBeginning(false);
    } else {
      setIsBeginning(true);
      setIsEnd(false);
    }
  };

  const onPrev = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slidePrev();
    }
  };

  const onNext = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      swiperInstance.slideNext();
    }
  };

  const onSwiperChange = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      setIsBeginning(swiperInstance.isBeginning);
      setIsEnd(swiperInstance.isEnd);
    }
  };

  const onTabChange = useCallback(value => {
    setType(value);
  }, []);

  const onChangeCustomDays = useCallback(value => {
    setDays(value);
  }, []);

  const onAddDay = useCallback(() => {
    setDays(prev => prev + 1);
  }, []);

  const onMinusDay = useCallback(() => {
    setDays(prev => prev - 1);
  }, []);

  const onDone = () => {
    let start_date = '';
    let duration_in_days = '';
    let desc = '';
    if (type === 'Dates') {
      if (!range || !range.from) {
        toast.info('Please select a date range.');
        return;
      }
      if (!range.to) {
        toast.info('Please select end date.');
        return;
      }
      console.log('range', range);
      start_date = dayjs(range.from).format('YYYY-MM-DD');
      duration_in_days = dayjs(range.to).diff(dayjs(range.from), 'days') + 1;
      desc = `${dayjs(range.from).format('MMMM DD, YYYY')} - ${dayjs(range.to).format('MMMM DD, YYYY')}`;
    } else {
      start_date = month;
      duration_in_days = days;
      desc = `${days} days in ${month}`;
    }
    if (duration_in_days > 14) {
      toast.info('Only supported for 14 days.');
      return;
    }
    onChange({
      start_date,
      duration_in_days,
      desc
    });
  };

  // const onDayClick = date => {
  //   console.log('range-->', range);
  //   if (range.form && range.to) {
  //     setRange({ from: date, to: null });
  //   } else if (!range.form && !range.to) {
  //     setRange({ from: date, to: null });
  //   } else if (!range.from || dayjs(date).isSameOrBefore(range.from)) {
  //     setRange({ from: date, to: range.to });
  //   } else if (!range.to || dayjs(date).isSameOrAfter(range.to)) {
  //     setRange({ from: range.from, to: date });
  //   }
  // };

  const onSelectRange = (values, day) => {
    // 如果 `from` 和 `to` 都未设置，或已经选择了开始和结束日期，重置选择
    if (!range?.from || (range?.from && range?.to)) {
      setRange({ from: day, to: undefined });
    } else if (range?.from && dayjs(day).isSame(range?.from, 'day')) {
      setRange({ from: day, to: day });
    } else {
      // 如果只选中了 `from`，点击第二个日期时设置为 `to`
      console.log('values-->', values);
      setRange(values);
    }
  };

  return (
    <div className={`flex flex-col items-center pb-3 ${styles.when}`}>
      <div className={`${styles.tabs} w-64 mt-4`}>
        {types.map(item => {
          return (
            <span key={item.value} className={`${styles.tab} ${type == item.value ? styles.active : ''}`} onClick={() => onTabChange(item.value)}>
              {item.label}
            </span>
          );
        })}
      </div>
      <div className={'w-[90vw] md:w-[700px] flex justify-center'}>
        <DayPicker
          className={`my-8 ${type === Dates ? 'flex items-center' : 'hidden'}`}
          numberOfMonths={md ? 2 : 1}
          // onDayClick={onDayClick}
          mode={'range'}
          selected={range}
          onSelect={onSelectRange}
          startMonth={new Date()}
          components={{
            PreviousMonthButton: ({ disabled, onClick }) => (
              <Button type={'text'} shape={'circle'} icon={<LeftOutlined />} disabled={disabled} onClick={onClick} />
            ),
            NextMonthButton: ({ disabled, onClick }) => (
              <Button type={'text'} shape={'circle'} icon={<RightOutlined />} disabled={disabled} onClick={onClick} />
            )
          }}
          onDayMouseEnter={date => {
            setHoveredDate(date);
          }}
          onDayMouseLeave={() => setHoveredDate(null)}
          modifiers={{
            hover: [hoveredDate]
            // startDay: [range.from],
            // middleDay: [
            //   {
            //     from: dayjs(range.from)
            //       .add(1, 'day')
            //       .toDate(),
            //     to: dayjs(range.to)
            //       .subtract(1, 'day')
            //       .toDate()
            //   }
            // ],
            // startEnd: [range.to]
          }}
          modifiersClassNames={{
            hover: 'hovered-day'
            // startDay: 'rdp-range_start',
            // middleDay: 'rdp-range_middle',
            // startEnd: 'rdp-range_end'
          }}
        />
        <div className={`flex flex-col items-center mt-10 mb-12 w-full ${type === 'Flexible' ? 'block' : 'hidden'}`}>
          <span className={'font-medium'}>How many days?</span>
          <span className={'f-center mt-3'}>
            <Button disabled={days <= 1} icon={<MinusOutlined />} shape="circle" size={'small'} onClick={onMinusDay} />
            <InputNumber
              value={days}
              className={'!mx-3 !f-center'}
              onChange={onChangeCustomDays}
              placeholder={'Days'}
              controls={false}
              min={1}
              max={30}
            />
            <Button size={'small'} icon={<PlusOutlined />} shape="circle" onClick={onAddDay} disabled={days >= 30} />
          </span>
          <span className={'font-medium mt-10'}>Travel in {month}</span>
          <div className={'mt-3 w-full flex items-center'}>
            <Button
              type={'text'}
              disabled={isBeginning}
              shape="circle"
              icon={<LeftOutlined />}
              className="!bg-gray-500] mr-3 !hidden md:!block"
              onClick={onPrev}
            />
            <Swiper
              ref={swiperRef}
              slidesPerView={md ? 6 : 4}
              slidesPerGroup={md ? 6 : 4}
              spaceBetween={12}
              speed={800}
              pagination={{
                clickable: true
              }}
              onSlideChange={onSwiperChange}
              className={`${styles.swiper}`}
            >
              {months.map(item => {
                return (
                  <SwiperSlide key={item}>
                    <span
                      onClick={() => setMonth(item)}
                      className={`text-[14px] py-3 shrink-0 cursor-pointer border ${
                        item === month ? 'border-black' : ''
                      } rounded-xl flex flex-col items-center`}
                      style={{
                        borderWidth: item === month ? 1.5 : 1
                      }}
                      key={item}
                    >
                      <CalendarOutlined style={{ fontSize: 20 }} />
                      <span className="mt-2">{item}</span>
                    </span>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <Button
              type={'text'}
              disabled={isEnd}
              shape="circle"
              icon={<RightOutlined />}
              className="!bg-gray-500] ml-3 !hidden md:!block"
              onClick={onNext}
            />
          </div>
        </div>
      </div>
      <Button type="primary" className={'!rounded-[22px] !px-10'} onClick={onDone}>
        Done
      </Button>
    </div>
  );
}

export default WhenPanel;
