import { memo, useCallback, useState } from 'react';
import styles from './rich-message.module.scss';
import { Popover } from 'antd';
import { ZZEmittedEvent, ZZMessageType } from '@/utils/const.js';
import useSource from '@/hooks/useSource.js';
import { isEmpty } from 'lodash-es';
import { useResponsive } from 'ahooks';
import YoutubeCover from '@/components/commons/YoutubeCover/index.jsx';
import emitter from '@/utils/emitter.js';
import AttractionPopContent from '@/components/home-v4/chat-elements/attraction-pop-content.jsx';
import { SourceLogo } from './attraction-pop-content.jsx';
import Typing from './typing.jsx';

function RichMessage({ message }) {
  const { onClickSource } = useSource();
  const onClick = useCallback(source => {
    console.log('source-->', source);
    if (!source) {
      return;
    }
    onClickSource({ source });
  }, []);

  return (
    <div className={'max-w-full'}>
      {message.messages?.map(item => {
        return (
          <>
            {item.type === ZZMessageType.text && !item.isRich && item.data}
            {item.type === ZZMessageType.text && item.isRich && <span dangerouslySetInnerHTML={{ __html: item.data }} />}
            {item.type === ZZMessageType.video && <VideosPanel videos={item.data} />}
            {item.type === ZZMessageType.place && <SourceEmbedV2 data={item.data} isPlace={true} onClickSource={onClick} />}
            {item.type === ZZMessageType.search_source && <SourceEmbedV2 data={item.data} isSource={true} onClickSource={onClick} />}
            {item.type === ZZMessageType.chips && <ChipsPanel data={item.data} />}
          </>
        );
      })}
      <Typing className={`${message.isFinished == false ? '' : '!hidden'}`} />
    </div>
  );
}

function ChipsPanel({ data }) {
  const [isDisabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState('');

  const onClickChip = chip => {
    if (isDisabled) {
      return;
    }
    setDisabled(true);
    setSelected(chip);
    emitter.emit(ZZEmittedEvent.clickChip, chip);
  };

  return (
    <div className={styles.chips}>
      {data.map(chip => (
        <span key={chip} className={`grayBorder ${styles.chip} ${chip === selected ? styles.selected : ''}`} onClick={() => onClickChip(chip)}>
          {chip}
        </span>
      ))}
    </div>
  );
}

function SourceEmbedV2({ data, isPlace, isSource, onClickSource }) {
  const { md } = useResponsive();
  return (
    <Popover
      trigger={md ? 'hover' : 'contextMenu'}
      content={<AttractionPopContent data={data} isPlace={isPlace} onClickSource={onClickSource} />}
      overlayInnerStyle={{
        padding: 0,
        overflow: 'hidden'
      }}
    >
      <span
        className={`inline-flex items-center bg-[#f2f3f5] hover:bg-gray-200 px-1.5 text-[12px] rounded-[9px] cursor-pointer ${styles.embed} translate-y-[2px]`}
      >
        <SourceLogo type={isSource ? data?.type : data?.place_type} url={data?.url} />
        <span className={'mr-1 font-bold whitespace-nowrap'}>{isPlace ? data.name : isSource ? data.title : ''}</span>
      </span>
    </Popover>
  );
}

// function PopoverContent({ data, isPlace, onClickSource }) {
//   const { getSourceName } = useSource();
//
//   const onClick = useCallback(() => {
//     onClickSource(data);
//   }, []);
//
//   return isPlace ? (
//     <AttractionPopContent place={data} />
//   ) : (
//     <div className={'flex flex-col max-w-[400px] min-w-[200px] p-3 cursor-pointer'}>
//       <div className={'flex items-center font-medium text-[12px] text-[#777]'}>
//         <SourceLogo type={data.type} url={data?.url} />
//         <span>{getSourceName({ source: data })}</span>
//       </div>
//       <div className={`mt-1 text-[13px] font-medium ellipsis-1`} onClick={onClick}>
//         {data.title}
//       </div>
//       <div className={`mt-1 text-[13px] ellipsis-4`} onClick={onClick}>
//         {data.content}
//       </div>
//     </div>
//   );
// }

function VideosPanel({ videos }) {
  const { md } = useResponsive();

  const onPlay = video => {
    if (md) {
      return;
    }
    emitter.emit(ZZEmittedEvent.showPlayer, {
      title: video.title,
      url: video.link
    });
  };

  return !isEmpty(videos) ? (
    <div className={'flex items-center w-full overflow-x-auto py-2'}>
      {videos.map(video => {
        return (
          <div key={video.link.slice(-6)} onClick={() => onPlay(video)} className={styles.videoItem}>
            <YoutubeCover item={video} className={'w-full h-full flex-shrink-0'} key2={video.link.slice(-6)} />
          </div>
        );
      })}
    </div>
  ) : null;
}

const RichMessageMemo = memo(
  RichMessage,
  (prev, next) => prev.message.messages === next.message.messages && prev.message.sources === next.message.sources
);

export default RichMessageMemo;
