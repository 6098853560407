import { useLocation, useNavigate } from 'react-router-dom';
import UserEntryV2 from '@/components/commons/UserEntryV2/index.jsx';

function Header({ className }) {
  const navigate = useNavigate();
  const location = useLocation();

  const onAdmin = () => {
    navigate(`/`, {
      replace: true
    });
  };

  return (
    <header
      className={`w-full flex justify-between items-center ${
        location.pathname == '/' ? 'text-white' : 'text-[#313131]'
      }  z-20 py-2 px-3 md:px-5 ${className}`}
    >
      <span className="f-center" onClick={onAdmin}>
        <img
          src={
            location.pathname == '/'
              ? 'https://storage.googleapis.com/solaris_melange/resources/logo_h_white.png'
              : 'https://storage.googleapis.com/solaris_melange/resources/logo_h.png'
          }
          alt=""
          className={'w-32 cursor-pointer'}
        />
      </span>
      <UserEntryV2 />
    </header>
  );
}

export default Header;
