import { useState } from 'react';
import { Select, Space } from 'antd';
import airlines from '@/utils/airlines.json';

function AirlineSearchBox(props) {
  const [options, setOptions] = useState(airlines);

  const onSearch = text => {
    const results = airlines.filter(
      airport => airport.name.toLowerCase().includes(text.toLowerCase()) || airport.code.toLowerCase().includes(text.toLowerCase())
    );
    setOptions(results);
  };

  const onSelect = value => {
    if (props.onChange) {
      props.onChange(value.data.code);
    }
  };

  return (
    <Select
      {...props}
      showSearch
      options={options}
      onSearch={onSearch}
      filterOption={false}
      optionRender={option => (
        <Space size={'middle'} onClick={() => onSelect(option)}>
          <span className={'font-medium'}>{option.data.code}</span>
          <span className={'text-[#666] text-[13px]'}>{option.data.name}</span>
        </Space>
      )}
    />
  );
}

export default AirlineSearchBox;
