import { Outlet, useLocation } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { getTmpToken } from '@/apis/user.js';
import cache from '@/utils/cache.js';
import Header from '@/layouts/header.jsx';
import { LoadingOutlined } from '@ant-design/icons';
import useTripStoreV4 from '@/stores/useTripStore_v4.js';
import { logEvent, tagEvent } from '@/utils/ga.js';
import BookingModal from '@/components/home-v4/modals/booking-modal.jsx';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import InfoDrawer from '@/components/home-v4/drawers/info-drawer.jsx';
import ImagesDrawer from '@/components/home-v4/drawers/images-drawer.jsx';
import UploadModal from '@/components/home-v4/modals/upload-modal.jsx';
import LoginModal from '@/components/home-v4/modals/login-modal.jsx';
import YoutubePlayerModal from '@/components/home-v4/modals/youtube-player-modal.jsx';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { useUserStoreSelectors } from '@/stores/useUserStore.js';
import loadImage from 'image-promise';
import { logOnCall } from '@/utils/log.js';

export const GlobalContext = createContext(null);

const ZZNoHeaderPaths = ['/AgodaPartnerVerification', '/privacy_app'];

function Layout() {
  const isShowBooking = useGlobalStoreSelectors.use.isShowBooking();
  const isShowPlaceInfo = useGlobalStoreSelectors.use.isShowPlaceInfo();
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();
  const isShowLogin = useUserStoreSelectors.use.isShowLogin();
  const setIsShowLogin = useUserStoreSelectors.use.setIsShowLogin();
  const isLogin = useUserStoreSelectors.use.isLogin();
  const [isShowPhotos, setIsShowPhotos] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const isShowUpload = useGlobalStoreSelectors.use.isShowUpload();
  const [isShowPlayer, setIsShowPlayer] = useState(false);
  const [hasAuth, setHasAuth] = useState(false);
  const [operateSource, setOperateSource] = useState({});
  const { pathname } = useLocation();
  const state = useRef({
    interactionCount: 0,
    staySeconds: 0,
    reported: false,
    showPlayerTimes: 0 //看video的次数
  });

  useEffect(() => {
    init();
    initEvent();
    return () => {
      emitter.off(ZZEmittedEvent.showPlayer);
      emitter.off(ZZEmittedEvent.showPhotos);
    };
  }, []);

  useEffect(() => {
    const handleInteraction = () => {
      state.current.interactionCount++;
      onReport();
    };
    document.addEventListener('click', handleInteraction);
    return () => {
      document.removeEventListener('click', handleInteraction);
    };
  }, []);

  const initEvent = useCallback(() => {
    emitter.on(ZZEmittedEvent.showPlayer, onShowPlayer);
    emitter.on(ZZEmittedEvent.showPhotos, images => {
      onShowPhotos(images);
    });
  }, []);

  const init = async () => {
    loadImages();
    try {
      if (!cache.getToken()) {
        const token = await getTmpToken({
          session_id: useTripStoreV4.getState().sessionId
        });
        cache.setToken(token);
        setHasAuth(true);
      } else {
        setHasAuth(true);
      }
      const t = setInterval(() => {
        state.current.staySeconds++;
        if (state.current.staySeconds >= 30) {
          clearInterval(t);
          onReport();
        }
      }, 1000);
    } catch (e) {
      console.log('e', e);
      logOnCall({
        message: 'get tmp token error...'
      });
    }
  };

  const onShowPlayer = video => {
    state.current.showPlayerTimes += 1;
    if (!isLogin && state.current.showPlayerTimes >= 3) {
      setIsShowLogin(true);
      return;
    }
    setOperateSource(video);
    setIsShowPlayer(true);
  };

  const onShowPhotos = images => {
    setIsShowPhotos(true);
    setAllImages(images);
  };

  const onReport = () => {
    if (state.current.interactionCount >= 5 && state.current.staySeconds >= 30 && !state.current.reported) {
      console.log('Deep Interaction 30S');
      state.current.reported = true;
      logEvent({
        name: 'deep_interaction'
      });
      tagEvent('event', 'conversion', {
        send_to: 'AW-16706051350/MUijCIGWktUZEJa6iJ4-'
      });
    }
  };

  const loadImages = () => {
    loadImage(['https://storage.googleapis.com/solaris_melange/resources/blur_itinerary.webp']);
  };

  return (
    <Div100vh style={{ backgroundColor: 'white' }} className={`f-center w-full`}>
      <GlobalContext.Provider value={{}}>
        <div className="h-full w-full flex flex-col">
          {ZZNoHeaderPaths.includes(pathname) ? null : <Header />}
          {hasAuth ? (
            <div className="w-full h-0 flex-grow overflow-y-auto">
              <Outlet />
            </div>
          ) : (
            <div className={'h-full f-center'}>
              <LoadingOutlined style={{ fontSize: 36, color: 'black' }} spin />
            </div>
          )}
        </div>
      </GlobalContext.Provider>
      <BookingModal open={isShowBooking} />
      <InfoDrawer
        open={isShowPlaceInfo}
        onClose={() => {
          toggleIsShowPlaceInfo({
            flag: false,
            placeId: null
          });
        }}
      />
      <LoginModal open={isShowLogin} />
      <ImagesDrawer images={allImages} open={isShowPhotos} title={'Show all photos'} onClose={() => setIsShowPhotos(false)} />
      <UploadModal open={isShowUpload} />
      <YoutubePlayerModal open={isShowPlayer} onOk={() => setIsShowPlayer(false)} video={operateSource} />
    </Div100vh>
  );
}

export default Layout;
