import { ZZUploadType } from '@/utils/const.js';
import styles from './flights-panel.module.scss';
import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Button, Modal } from 'antd';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { deleteFlight } from '@/apis/trips_v4.js';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import IconFlight from '../../../assets/svgs/icon_flight_line.svg?react';
import IconNoFlight from '../../../assets/svgs/icon_no_flight.svg?react';
import { isEmpty } from 'lodash-es';
import { useResponsive } from 'ahooks';

function FlightsPanel({ className }) {
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const toggleIsShowUpload = useGlobalStoreSelectors.use.toggleIsShowUpload();
  const fetchItineraryAndIntent = useTripStoreSelectorsV4.use.fetchItineraryAndIntent();
  const [modal, contextHolder] = Modal.useModal();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const { md } = useResponsive();

  const onUpload = () => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    toggleIsShowUpload(true, ZZUploadType.flight);
  };

  const getDates = item => {
    const formatDateTime = dateTime => `${dateTime.date.year}-${dateTime.date.month}-${dateTime.date.day} ${dateTime.hour}:${dateTime.minute}`;
    const formatDay = dateTime => dayjs(formatDateTime(dateTime)).format('ddd, MMM D');
    const formatTime = dateTime => dayjs(formatDateTime(dateTime)).format('h:mm A');
    const depart_day = formatDay(item.departure_time);
    const arrive_day = formatDay(item.arrival_time);
    const depart_time = formatTime(item.departure_time);
    const arrive_time = formatTime(item.arrival_time);
    return depart_day === arrive_day
      ? `${depart_day}  •  ${depart_time} - ${arrive_time}`
      : `${depart_day} • ${depart_time}  -  ${arrive_day} • ${arrive_time}`;
  };

  const onConfirm = flightId => {
    modal.confirm({
      centered: true,
      title: 'Delete Confirm',
      content: 'Are you sure to delete this flight?',
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      onOk: async close => {
        await onDelete(flightId);
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onDelete = async flightId => {
    const { sessionId, itineraryId } = useTripStoreV4.getState();
    await deleteFlight({
      session_id: sessionId,
      itinerary_id: itineraryId,
      flight_id: flightId
    });
    toast.success('Flight deleted successfully');
    fetchItineraryAndIntent();
  };

  return (
    <div className={`py-2 px-3 ${className}`}>
      {isEmpty(itinerary?.flights) ? (
        <div className="f-center flex-col">
          <IconNoFlight className={'mt-4'} />
          <div className={'text-[15px] md:text-[20px] font-medium'}>You don’t have any flights yet for this trip</div>
          <div className={'text-[15px] md:text-[20px] mb-6'}>Please add or book first</div>
          <Button onClick={onUpload} className={'mb-4 !font-medium'} icon={<IconFlight />}>
            Add Flight
          </Button>
        </div>
      ) : (
        <div className={'flex flex-col'}>
          {itinerary?.flights?.map(item => {
            return (
              <div key={item.id} className={`${styles.item} relative flex flex-col md:flex-row`}>
                <div className={`w-full md:w-0 md:flex-grow flex flex-col ${styles.left} md:border-r pr-7 md:pr-4`}>
                  <div className={'flex items-center'}>
                    <span className={'font-medium text-[14px] md:text-[16px]'}>{item.origin.name}</span>
                    <ArrowRightOutlined style={{ fontWeight: 600, fontSize: 18, margin: '0 12px' }} />
                    <span className={'font-medium text-[14px] md:text-[16px]'}>{item.destination.name}</span>
                  </div>
                  <span className={'text-[13px] mt-1 md:mt-2'}>{getDates(item)}</span>
                  {/*<span className={'text-[13px] mt-2'}>$ {item.cost}</span>*/}
                  <span className={'text-[13px] mt-1 md:mt-2'}>
                    {item.airline} {item.flight_number}
                  </span>
                </div>
                <div className={`flex flex-col w-[30%] md:pl-4 mt-2 md:mt-0`}>
                  <span className={styles.label}>confirmation #</span>
                  <span className={`${styles.notes} md:mb-3`}>{item.confirmation_number || '--'}</span>
                </div>
                <Button
                  type="text"
                  className={`${styles.delete} !absolute right-2 top-2 md:!hidden`}
                  onClick={() => onConfirm(item.id)}
                  icon={<DeleteOutlined />}
                />
              </div>
            );
          })}
        </div>
      )}
      {contextHolder}
    </div>
  );
}

export default FlightsPanel;
