import { onCall } from '@/apis/global.js';
import useTripStoreV4 from '@/stores/useTripStore_v4.js';

export function logOnCall(event_content) {
  const obj = {
    session_id: useTripStoreV4.getState()?.sessionId || '',
    message: JSON.stringify({
      type: 'frontend',
      url: window.location.href,
      event_content
    })
  };
  console.log('import.meta.env.MODE', import.meta.env.MODE, obj);
  if (import.meta.env.MODE === 'dev') {
    return;
  }
  onCall(obj);
}
