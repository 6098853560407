import { useToggle } from 'ahooks';
import { Drawer } from 'antd';
import styles from './map-entry.module.scss';
import { MessageOutlined } from '@ant-design/icons';
import LeftPanel from '@/components/home-v4/left-panel.jsx';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent } from '@/utils/ga.js';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';

function MapEntry({ backText = 'Chat' }) {
  const [isShow, { setLeft, setRight }] = useToggle();
  const location = useLocation();

  //share的话，默认打开itinerary
  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (location.pathname.includes('/share')) {
      setRight();
    }
    emitter.on(ZZEmittedEvent.mobileShowItinerary, () => {
      setRight();
    });
  };

  const onShow = () => {
    logEvent({
      name: 'chatPage_click_itinerary'
    });
    setRight();
  };

  const onHide = () => {
    logEvent({
      name: 'itinerary_click_chat'
    });
    setLeft();
  };

  return (
    <>
      <span className={'px-4 py-1 self-center my-3 font-normal text-[13px] bg-black text-white rounded-[16px] f-center'} onClick={onShow}>
        <i className={'iconfont icon-map1 mr-2'} />
        <span>Itinerary</span>
      </span>
      <Drawer
        className={styles.mapDrawer}
        open={isShow}
        title={null}
        mask={false}
        destroyOnClose={true}
        placement={'bottom'}
        height={'100%'}
        onClose={setLeft}
        closeIcon={false}
      >
        {/*<MapPanel {...props} />*/}
        <LeftPanel />
        <span
          className={
            'absolute bottom-8 left-1/2 -translate-x-1/2 px-4 py-1.5 font-normal text-[13px] bg-black text-white rounded-[16px] f-center z-[1000] cursor-pointer'
          }
          onClick={onHide}
        >
          <MessageOutlined className={'text-[16px] mr-2'} />
          <span>{backText}</span>
        </span>
      </Drawer>
    </>
  );
}

export default MapEntry;
