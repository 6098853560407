export function getTripDestination(pickedIntent) {
  try {
    if (!pickedIntent) {
      return '';
    }
    const cities = pickedIntent.destination_places.map(o => o.name);
    let title = cities.join(', ');
    if (cities.length > 2) {
      const index = title.lastIndexOf(', ');
      title = title.substring(0, index) + ' and ' + title.substring(index + 1);
    }
    return title;
  } catch (e) {
    return pickedIntent.destination;
  }
}
