export default function UseProtocol() {
  const onPrivacy = () => {
    window.open(window.location.origin + '/privacy', '_blank');
  };

  const onTerms = () => {
    window.open(window.location.origin + '/terms', '_blank');
  };

  return {
    onPrivacy,
    onTerms
  };
}
