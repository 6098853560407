import { ZZGoogleMapKey } from '@/utils/config.js';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { customAlphabet } from 'nanoid';

dayjs.extend(advancedFormat);

export function isMobile() {
  console.log('navigator.userAgent', navigator.userAgent);
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
}

export const isPhone = isMobile();

export function getMinutesDuration(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  let hoursStr = hours > 0 ? `${hours} ${hours === 1 ? 'hour' : 'hours'}` : '';
  let minutesStr = remainingMinutes > 0 ? `${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}` : '';
  if (hours > 0 && remainingMinutes > 0) {
    return `${hoursStr} ${minutesStr}`;
  } else if (hours > 0) {
    return hoursStr;
  } else {
    return minutesStr;
  }
}

export function getTourDay(minutes) {
  const hours = minutes / 60; // 将分钟转换为小时
  const days = hours / 4; // 将小时除以每天的小时数来计算天数
  const roundedDays = Math.round(days * 2) / 2;
  // 以 0.5 天为单位进行四舍五入
  const dayLabel = roundedDays <= 1 ? 'day' : 'days';
  return `${roundedDays} ${dayLabel}`; // 返回拼接的字符串
}

//根据地址解经纬度
export const fetchGeocoding = async cityName => {
  console.log('cityName', cityName);
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(cityName)}&key=${ZZGoogleMapKey}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    console.log('data-->', data);
    if (data.results && data.results.length > 0) {
      return data.results[0].geometry.location;
    } else {
      console.warn('Error:no results');
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

//根据经纬度解地址
export const fetchGeocoder = async location => {
  await window.google.maps.importLibrary('geocoding');
  const geocoder = new window.google.maps.Geocoder();
  const { results = [] } = await geocoder.geocode({ location });
  if (results.length) {
    return results[0];
  } else {
    return null;
  }
};

export function calculateBounds(coordinates) {
  if (coordinates.length === 0) {
    return null;
  }

  let northEastLat = -Infinity;
  let northEastLng = -Infinity;
  let southWestLat = Infinity;
  let southWestLng = Infinity;

  coordinates.forEach(coord => {
    if (coord.lat > northEastLat) {
      northEastLat = coord.lat;
    }
    if (coord.lng > northEastLng) {
      northEastLng = coord.lng;
    }
    if (coord.lat < southWestLat) {
      southWestLat = coord.lat;
    }
    if (coord.lng < southWestLng) {
      southWestLng = coord.lng;
    }
  });
  return {
    northEast: { lat: northEastLat, lng: northEastLng },
    southWest: { lat: southWestLat, lng: southWestLng }
  };
}

/**
 * 计算两个坐标点的中间点，并可以控制中间点的偏移方向。
 * @param {Object} pointA 第一个点，包含纬度lat和经度lng。
 * @param {Object} pointB 第二个点，包含纬度lat和经度lng。
 * @param {String} direction 控制中间点偏移的方向，'up'或'down'。
 * @param {Number} offset 偏移量的大小，表示纬度的变化。
 * @returns {Object} 包含中间点纬度和经度的对象。
 */
export function calculateMidpoint(pointA, pointB, direction, offset) {
  // 计算平均纬度和经度
  let midLat = (pointA.lat + pointB.lat) / 2;
  const midLng = (pointA.lng + pointB.lng) / 2;

  // 根据方向调整纬度
  if (direction === 'up') {
    midLat += offset; // 向北偏移
  } else if (direction === 'down') {
    midLat -= offset; // 向南偏移
  }

  // 返回计算后的中间点
  return { lat: midLat, lng: midLng };
}

export function formatNumber(num) {
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return num != null ? num.toString() : '-';
}

export function getQueryParam(url, param) {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get(param);
}

//处理[source:1,2,3,4等这种情况]
export function resolveChatMessage(input) {
  if (!input) {
    return input;
  }
  let result = input;
  // 处理[source:x,y,z,...] 或 [Source:x,y,z,...]， [source: x,y,z,...]情况
  const regex1 = /\[source:\s*([\d\s,]+)]/gi;
  result = result.replace(regex1, (match, numbers) => {
    // 将逗号分隔的数字拆分并重新组合为单个 [source:n] 模式
    return numbers
      .split(',')
      .map(num => `[source:${num}]`)
      .join(',');
  });
  //处理md的链接
  const regex2 = /\[([^\]]+)]\((https?:\/\/[^)]+)\)/g;
  result = result.replace(regex2, '<a href="$2" target="_blank" style="margin-left: 4px">$1</a>');
  return result;
}

export function getImageUrl(url, size) {
  if (!url) {
    return 'https://storage.googleapis.com/solaris_melange/resources/placeholer.jpg';
  }
  if (url.includes('http')) {
    // 检查是否是外部链接，且包含 maps.googleapis.com 并带有 key 参数
    if (url && url.includes('maps.googleapis.com')) {
      const hasKeyParam = /[?&]key=([^&]*)/i.test(url); // 检查 key 参数
      if (hasKeyParam) {
        // 使用正则替换 key 参数的值，仅限域名下访问的
        url = url.replace(/([?&]key=)[^&]*/i, `$1${import.meta.env.VITE_IMAGE_KEY}`);
      }
    }
    // 检查链接是否包含 maxWidth 和 maxHeight 参数
    const hasMaxWidth = /maxwidth=\d+/i.test(url);
    const hasMaxHeight = /maxheight=\d+/i.test(url);
    if (!hasMaxWidth || !hasMaxHeight || !size) {
      return url;
    }
    const updatedUrl = url
      .replace(/(maxwidth=)\d+/i, `$1${size}`) // 替换 maxWidth
      .replace(/(maxheight=)\d+/i, `$1${size}`); // 替换 maxHeight
    return updatedUrl;
  }
  let link = `https://storage.googleapis.com/solaris_melange/images${url}`;
  if (size > 300 && size <= 800) {
    link = link.replace('_small', '_medium');
  } else if (size > 800 || !size) {
    link = link.replace('_small', '_large');
  }
  return link;
}

export function isEmptyFunction(func) {
  if (typeof func !== 'function') {
    return false;
  }
  const funcStr = func.toString().replace(/\s+/g, '');
  // 对于普通函数和箭头函数进行检查
  return funcStr === 'function(){}' || funcStr === '()=>' || funcStr === '()=>{}';
}

export function convertMinutesToHours(minutes) {
  let hours = minutes / 60;
  // 将小时数向上取整到最近的0.5小时
  let roundedHours = Math.ceil(hours * 2) / 2;
  // 根据小时数选择合适的单复数
  let hourText = roundedHours === 1 ? 'hour' : 'hours';
  return `${roundedHours} ${hourText}`;
}

export function getRatingLevel(score) {
  if (score >= 4.5) {
    return 'Excellent';
  } else if (score >= 4.0) {
    return 'Very Good';
  } else if (score >= 3.0) {
    return 'Good';
  } else if (score >= 2.0) {
    return 'Fair';
  } else if (score >= 1.0) {
    return 'Poor';
  } else {
    return 'Very Poor';
  }
}

export function formatTimeRange(timeRange, is12HourFormat = true) {
  if (!timeRange.includes('-')) {
    return timeRange;
  }
  // 分割时间段
  const [startTime, endTime] = timeRange.split(' - ');

  // 根据是否需要12小时制格式进行转换
  const startTimeFormatted = is12HourFormat ? _convertTo12Hour(startTime) : _convertTo24Hour(startTime);
  const endTimeFormatted = is12HourFormat ? _convertTo12Hour(endTime) : _convertTo24Hour(endTime);
  return `${startTimeFormatted} - ${endTimeFormatted}`;
}

function _convertTo12Hour(time) {
  let [hours, minutes] = time.split(':');
  hours = parseInt(hours);

  // 判断AM或PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // 转换为12小时制
  hours = hours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
}

function _convertTo24Hour(time) {
  // 如果是00:00则转换为24:00
  if (time === '00:00') {
    return '24:00';
  }
  return time;
}

export const formatItineraryDate = date => {
  if (date) {
    return dayjs(`${date.year}-${date.month}-${date.day}`).format('MMM D, YYYY');
  }
};

export function getOriginalCoordinatesOfMostFrequent(coordinates) {
  // 检查传入参数是否为非空数组
  if (!Array.isArray(coordinates) || coordinates.length === 0) {
    throw new Error('请传入一个包含经纬度的非空数组');
  }
  // 创建一个 Map 来存储经纬度的出现次数及其原始值
  const coordMap = new Map();
  // 遍历经纬度数组，取小数点后2位并记录原始值和次数
  coordinates.forEach(coord => {
    const latitude = coord.latitude.toFixed(2);
    const longitude = coord.longitude.toFixed(2);
    const key = `${latitude},${longitude}`;
    if (coordMap.has(key)) {
      coordMap.get(key).count += 1;
      coordMap.get(key).originals.push(coord);
    } else {
      coordMap.set(key, { count: 1, originals: [coord] });
    }
  });
  // 找出出现次数最多的经纬度
  let maxFrequency = 0;
  let mostFrequentOriginals = [];
  coordMap.forEach(value => {
    if (value.count > maxFrequency) {
      maxFrequency = value.count;
      mostFrequentOriginals = value.originals;
    }
  });
  // 返回出现次数最多的经纬度对应的原始经纬度数组
  return mostFrequentOriginals;
}

export function getUniqueId(size = 5) {
  // 仅使用字母字符集
  const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  // 生成长度为 5 的纯字母字符串
  const nanoid = customAlphabet(alphabet, size);
  return nanoid();
}

export function ensureHttps(url) {
  url = url || '';
  // 如果URL不以 "http://" 或 "https://" 开头
  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url;
  }
  return url;
}

export function getArray(length, start = 0) {
  let list = [];
  for (let i = 0; i < length; i++) {
    list.push(start + i);
  }
  return list;
}

export function getCurrencySymbol(currencyCode) {
  const currencySymbols = {
    USD: '$', // 美元
    EUR: '€', // 欧元
    GBP: '£', // 英镑
    JPY: '¥', // 日元
    CNY: '¥', // 人民币
    AUD: 'A$', // 澳元
    CAD: 'C$', // 加元
    CHF: 'CHF', // 瑞士法郎
    SEK: 'kr', // 瑞典克朗
    NZD: 'NZ$', // 新西兰元
    KRW: '₩', // 韩元
    SGD: 'S$', // 新加坡元
    INR: '₹', // 印度卢比
    MXN: '$', // 墨西哥比索
    BRL: 'R$', // 巴西雷亚尔
    ZAR: 'R' // 南非兰特
  };
  return currencySymbols[currencyCode] || '$'; // 如果没有匹配到，返回空字符串
}

export default function extractVideoId(url) {
  const urlObj = new URL(url);
  let videoId = '';
  if (urlObj.hostname === 'youtu.be') {
    videoId = urlObj.pathname.slice(1); // Remove the leading slash
  } else if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtube.com') {
    videoId = urlObj.searchParams.get('v');
  }
  return videoId;
}
