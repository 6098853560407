import { create } from 'zustand';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import { subscribeWithSelector } from 'zustand/middleware';

const useGenerateStore = create(
  subscribeWithSelector(set => ({
    isCollecting: false,
    isGenerating: false,
    // chips: [],

    reset: () => {
      set(() => ({
        isGenerating: false
        // chips: []
      }));
    },

    setIsGenerating: flag => {
      set(() => ({
        isGenerating: flag
      }));
    },

    setIsCollecting: flag => {
      set(() => ({
        isCollecting: flag
      }));
    }
  }))
);

export default useGenerateStore;
export const useGenerateStoreSelectors = createSelectorFunctions(useGenerateStore);
