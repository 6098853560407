import { Button, Modal, Select, Spin } from 'antd';
import styles from './add-place-modal.module.scss';
import { LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import toast from '@/utils/toast.js';
import { useMemo, useRef, useState } from 'react';
import { useResponsive } from 'ahooks';
import usePlaceV4 from '@/hooks/usePlace_v4.jsx';
import { getNearbyPlaces } from '@/apis/trips_v4.js';
import useTripStore_v4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { debounce, get, uniqBy } from 'lodash-es';
import { formatNumber, getImageUrl } from '@/utils/index.js';
import client from '@/utils/algolia.js';
import IconStar from '../../../assets/svgs/icon_star.svg?react';
import ImageWrapper from '@/components/commons/ImageWrapper/index.jsx';

function AddPlaceModal({ onOk, dayIndex, onCancel, ...props }) {
  const { md } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [list, setList] = useState([]);
  const pickedIntent = useTripStoreSelectorsV4.use.pickedIntent();
  const sessionId = useTripStoreSelectorsV4.use.sessionId();
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [input, setInput] = useState(null);

  const { onAddPlace } = usePlaceV4();
  const state = useRef({
    place: null
  });

  const onPlaceChange = async (_, option) => {
    try {
      state.current.place = option;
    } catch (e) {
      console.log('e-->', e);
    }
  };

  const fetchList = async () => {
    try {
      setIsLoading(true);
      const destination = pickedIntent.destination_places[0];
      console.log('destination', destination);
      const data = await getNearbyPlaces({
        session_id: sessionId,
        place_type: 'ATTRACTION',
        latitude: Number(destination?.latitude),
        longitude: Number(destination?.longitude)
      });
      const results = checkExist(data);
      setList(results);
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const checkExist = list => {
    const { itinerary } = useTripStore_v4.getState();
    return list.filter(item => {
      const foundDayIndex = itinerary.daily_itineraries.findIndex(day => {
        const idList = day.places.map(p => p.place_id);
        return idList.includes(item.place_id);
      });
      return foundDayIndex == -1;
    });
  };

  const debounceSearch = useMemo(() => {
    const onSearch = async text => {
      try {
        setFetching(true);
        const destination = pickedIntent.destination_places[0];
        const { results } = await client.search({
          requests: [
            {
              indexName: 'france_attraction',
              query: text,
              restrictSearchableAttributes: ['name'],
              hitsPerPage: 15,
              aroundLatLng: `${destination.latitude},${destination.longitude}`,
              aroundRadius: 500000 // 半径设置为500km（单位：米）
            }
          ]
        });
        console.log('results', results);
        let list = results[0]?.hits || [];
        list = uniqBy(list, item => item.place_id);
        list = checkExist(list);
        list = list.map(o => {
          return {
            value: o.place_id,
            label: o.name,
            ...o
          };
        });
        setOptions(list);
      } catch (e) {
        console.log('e', e);
      }
    };
    return debounce(onSearch, 400);
  }, []);

  const onSubmit = async place => {
    try {
      if (isSubmitting) {
        return;
      }
      if (!place && !state.current.place) {
        toast.info('Please search attraction');
        return;
      }
      setIsSubmitting(true);
      await onAddPlace(null, place || state.current.place, {
        isShowLoading: false,
        dayIndex: (dayIndex || 0) + 1
      });
      onOk && onOk();
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onAfterOpenChange = open => {
    if (open) {
      fetchList();
    } else {
      setList([]);
      setOptions([]);
      setInput(null);
    }
  };

  return (
    <Modal
      className={`${styles.addModal} ${md ? styles.pc : ''}`}
      {...props}
      centered
      width={md ? 460 : '100%'}
      onOk={onSubmit}
      okText="Submit"
      footer={null}
      onCancel={onCancel}
      zIndex={2000}
      destroyOnClose
      afterOpenChange={onAfterOpenChange}
    >
      <div>
        <div className={styles.title}>Add Attraction</div>
        <Select
          showSearch
          onSelect={onPlaceChange}
          options={options}
          onSearch={debounceSearch}
          filterOption={false}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          value={input}
          placeholder={'Attraction name'}
          className={`w-full ${styles.select} !mb-3`}
          onChange={setInput}
          size={'large'}
          suffixIcon={<SearchOutlined className={'text-[20px]'} />}
          optionRender={option => (
            <div className={`flex items-center ${styles.item} ${styles.option}`}>
              <ImageWrapper src={getImageUrl(get(option, 'data.photos.0.url'), 300)} alt="" className={'w-10 h-10 rounded-md mr-3 object-cover'} />
              <div className="flex flex-col w-0 flex-grow mr-3">
                <span className={'text-[14px] font-medium'}>{option.data.name}</span>
                <span className={'text-[13px] text-[#666] ellipsis-1'}>{option.data.city || option.data.region || option.data.country}</span>
              </div>
            </div>
          )}
        />
        {isLoading ? (
          <div className={'f-center h-[488px]'}>
            <Spin spinning={isLoading} indicator={<LoadingOutlined className={'!text-[28px] text-black'} />} />
          </div>
        ) : (
          <div className={`flex flex-col h-[488px] overflow-y-auto ${styles.list}`}>
            {list.map(item => {
              return (
                <div key={item.place_id} className={`flex items-center ${styles.item}`}>
                  <ImageWrapper
                    src={getImageUrl(get(item, 'photos.0.url'), 300)}
                    alt=""
                    className={'w-10 h-10 rounded-md mr-3 object-cover'}
                    placeId={item?.place_id}
                  />
                  <div className="flex flex-col w-0 flex-grow mr-3">
                    <span className={'text-[14px] font-medium'}>{item.name}</span>
                    <span className="flex items-center text-[13px]">
                      <span className={'font-medium text-[#374151]'}>{item?.user_rating}</span>
                      <IconStar className={'ml-0.5 mr-1 mb-0.5'} />
                      {<span className={'text-[#374151]'}>({formatNumber(item?.total_user_reviews)} reviews)</span>}
                    </span>
                  </div>
                  <Button
                    icon={<PlusOutlined />}
                    shape={'circle'}
                    className={`grayBorder ${styles.addBtn} md:invisible`}
                    onClick={() => onSubmit(item)}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div className={'flex items-center justify-between mt-3'}>
          <Button onClick={onCancel} className={'flex-1 !h-11 mr-5'}>
            Cancel
          </Button>
          <Button onClick={() => onSubmit()} type={'primary'} className={'flex-1 !h-11'} loading={isSubmitting}>
            Add
          </Button>
        </div>
      </div>
      <div className={'flex items-center justify-between overflow-y-auto'}></div>
    </Modal>
  );
}

export default AddPlaceModal;
