import ajax from '@/utils/ajax';

//初始化itinerary
export function initItinerary(data) {
  return ajax({
    // url: '/api/itinerary',
    url: '/ice/create_itinerary',
    data
  });
}

//itinerary和travel_intent获取
export function getItineraryIntent(data) {
  return ajax({
    url: '/ice/get_itinerary',
    data
  });
}

//itinerary和travel_intent更新
export function updateItineraryIntent(data) {
  return ajax({
    url: '/api/itinerary/update',
    data
  });
}

//添加航班
export function addFlight(data) {
  return ajax({
    url: '/ice/add_flight',
    data
  });
}

//添加酒店
export function addHotel(data) {
  return ajax({
    url: '/ice/add_hotel',
    data
  });
}

//优化itinerary
export function optimizeItinerary(data) {
  return ajax({
    url: '/api/optimize_itinerary',
    data
  });
}

//feedback
export function doFeedback(data) {
  return ajax({
    url: '/api/feedback',
    data
  });
}

export function deleteFlight(data) {
  return ajax({
    url: '/api/flight/remove',
    data
  });
}

export function deleteHotel(data) {
  return ajax({
    url: '/api/hotel/remove',
    data
  });
}

export function searchPlace(data) {
  return ajax({
    url: '/api/search/google_place',
    data
  });
}

export function suggest_itinerary(data) {
  return ajax({
    url: '/ice/suggest_itinerary',
    data
  });
}

export function copy_itinerary(data) {
  return ajax({
    url: '/ice/copy_itinerary',
    data
  });
}

export function add_attraction(data) {
  return ajax({
    url: '/ice/add_attraction',
    data
  });
}

export function clear_day(data) {
  return ajax({
    url: '/ice/clear_day',
    data
  });
}

export function delete_day(data) {
  return ajax({
    url: '/ice/delete_day',
    data
  });
}

export function insert_day(data) {
  return ajax({
    url: '/ice/insert_day',
    data
  });
}

export function swap_day(data) {
  return ajax({
    url: '/ice/swap_day',
    data
  });
}

export function getPlaces(data) {
  return ajax({
    url: '/tkb/get_places',
    data
  });
}

export function updateAttraction(data) {
  return ajax({
    url: '/ice/update_attraction',
    data
  });
}

export function updateDay(data) {
  return ajax({
    url: '/ice/update_day',
    data
  });
}

export function get_videos(data) {
  return ajax({
    url: '/tkb/get_videos',
    data
  });
}

export function updateHotel(data) {
  return ajax({
    url: '/ice/update_hotel',
    data
  });
}

export function swapAttraction(data) {
  return ajax({
    url: '/ice/swap_attraction',
    data
  });
}

export function getProduct(data) {
  return ajax({
    url: '/tkb/get_product',
    data
  });
}

export function getNearbyPlaces(data) {
  return ajax({
    url: '/tkb/get_nearby_places',
    data
  });
}
