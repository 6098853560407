import { Button, Dropdown, Modal } from 'antd';
import { useEffect, useState } from 'react';
import styles from './day-item.module.scss';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import MustDosPanel from '@/components/home-v4/itinerary-elements/must-dos-panel.jsx';
import { convertMinutesToHours, formatItineraryDate } from '@/utils/index.js';
import { get, last } from 'lodash-es';
import { nanoid } from 'nanoid';
// import DayFlights from '@/components/home-v4/itinerary-elements/day-flights.jsx';
import DayHotels from '@/components/home-v4/itinerary-elements/day-hotels.jsx';
import IconMore from '../../../assets/svgs/icon_more.svg?react';
import IconCamera from '../../../assets/svgs/icon_camera_black.svg?react';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import { useResponsive } from 'ahooks';
import DirectionBox from '@/components/home-v4/itinerary-elements/direction-box.jsx';
import { useUserStoreSelectors } from '@/stores/useUserStore.js';

function DayItem({ index, day, onAddPlace, onEditDuration, onEditDayTitle }) {
  const { md } = useResponsive();
  const isLogin = useUserStoreSelectors.use.isLogin();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const [modal, contextHolder] = Modal.useModal();
  const deleteDay = useTripStoreSelectorsV4.use.deleteDay();
  const clearDay = useTripStoreSelectorsV4.use.clearDay();
  const [currentDayHotel, setCurrentDayHotel] = useState(null);
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const insertDay = useTripStoreSelectorsV4.use.insertDay();

  useEffect(() => {
    initData();
  }, [day]);

  const initData = () => {
    const currentDayHotel = getDayHotel(day);
    setCurrentDayHotel(currentDayHotel);
  };

  const getDayHotel = day => {
    if (day?.hotel) {
      return {
        id: nanoid(4),
        place_type: 'hotel',
        ...day.hotel
      };
    } else if (day?.place_to_stay) {
      return {
        id: nanoid(4),
        place_type: 'place_to_stay',
        ...day.place_to_stay
      };
    }
    return null;
  };

  const onAddDayBefore = () => {
    modal.confirm({
      centered: true,
      title: 'Add Confirm',
      content: 'Are you sure to add it before this day?',
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      onOk: async close => {
        // await insertDay(index + 1);
        await insertDay(index + 1, 'before');
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onAddDayAfter = () => {
    modal.confirm({
      centered: true,
      title: 'Add Confirm',
      content: 'Are you sure to add it after this day?',
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      onOk: async close => {
        // await insertDay(index + 2); //后台第一天是1
        await insertDay(index + 1, 'after');
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onDeleteDay = () => {
    modal.confirm({
      centered: true,
      title: 'Delete Confirm',
      content: 'Are you sure to delete this day?',
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      onOk: async close => {
        await deleteDay(index + 1);
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onClearDay = async () => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    modal.confirm({
      centered: true,
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      title: 'Clear Confirm',
      content: 'Are you sure to clear this day?',
      okText: 'Confirm',
      onOk: async close => {
        await clearDay(day.dayIndex + 1);
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onUpdateTitle = e => {
    e.stopPropagation();
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    onEditDayTitle(day);
  };

  const menus = {
    items: [
      {
        key: '1',
        label: (
          <div className="py-1" onClick={onAddDayBefore}>
            Add day before
          </div>
        )
      },
      {
        key: '2',
        label: (
          <div className="py-1" onClick={onAddDayAfter}>
            Add day after
          </div>
        )
      },
      {
        key: '3',
        label: (
          <div className="py-1" onClick={onDeleteDay}>
            Delete day
          </div>
        )
      },
      {
        key: '4',
        label: (
          <div className="py-1" onClick={onClearDay}>
            Clear day
          </div>
        )
      },
      {
        key: '5',
        label: (
          <div className="py-1" onClick={onUpdateTitle}>
            Edit title
          </div>
        )
      }
    ]
  };

  const getTotalHours = () => {
    const totalMinutes = day.places?.reduce((acc, cur) => {
      return acc + (cur.duration_minutes || 120);
    }, 0);
    return convertMinutesToHours(totalMinutes);
  };

  return (
    <div className={`flex flex-col ${styles.dayItem} p-0 px-2 pb-2 md:px-4 md:pb-4 mb-3`} id={`itinerary-day-${index}`}>
      <div className="header flex items-center justify-between mb-3">
        <div className={`f-center flex-shrink flex flex-col w-0 flex-grow ${styles.header}`}>
          <div className={'mb-1 self-stretch flex items-center justify-between font-medium text-[16px] md:text-[24px]'}>
            <div className={'flex items-center mr-3 w-0 flex-grow'}>
              <span className={'shrink-0'}>Day {day.dayIndex + 1}</span>
              {day.introduction ? <span className={styles.dayTitle}> - {day.introduction}</span> : null}
            </div>
            {!isReadOnly && (
              <Dropdown menu={menus} placement={'bottomRight'}>
                <IconMore className={`${styles.more} md:hidden cursor-pointer`} />
              </Dropdown>
            )}
          </div>
          <div className={'self-stretch flex items-center text-[14px]'}>
            {day.date && (
              <template>
                <span>{formatItineraryDate(day.date)}</span>
                <span className={'text-[18px] mx-2 text-[#6B7280]'}>·</span>
              </template>
            )}
            <span>{day.places?.length || 0} attractions</span>
            <span className={'text-[18px] mx-2 text-[#6B7280]'}>·</span>
            <span>{getTotalHours()}</span>
          </div>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid #E5E7EB' }}>
        <DayHotels dayIndex={day.dayIndex} className={'mb-5'} place={currentDayHotel} />
        <DirectionBox
          isShowContent={!!get(day.places, '0.previous_route')}
          route={get(day.places, '0.previous_route')}
          isShowFromText={true}
          dayIndex={index}
          attractionIndex={0}
          type="previous_route"
        />
        <MustDosPanel dayIndex={day.dayIndex} day={day} onEditDuration={onEditDuration} />
        <DirectionBox
          route={last(day.places)?.next_route}
          isShowContent={!!last(day.places)?.next_route}
          isShowEndText={true}
          dayIndex={index}
          attractionIndex={day.places?.length - 1}
          type="next_route"
        />
      </div>
      {isLogin && (
        <div className={'w-full mt-4 py-2 f-center'}>
          <Button icon={<IconCamera />} className={`${isReadOnly ? '!hidden' : ''} self-center mt-2`} onClick={() => onAddPlace(day)}>
            Add Attractions
          </Button>
        </div>
      )}
      {contextHolder}
    </div>
  );
}

export default DayItem;
