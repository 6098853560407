import { memo, useEffect, useRef, useState } from 'react';
import { Button, Image, Input, Spin } from 'antd';
import styles from './chat-panel.module.scss';
import { nanoid } from 'nanoid';
import useWebSocket from 'react-use-websocket';
import { LoadingOutlined } from '@ant-design/icons';
import { ZZEmittedEvent, ZZMessageType, ZZSocketTaskType } from '@/utils/const.js';
import PlaceMessage from '@/components/home-v4/chat-elements/place-message.jsx';
import emitter from '@/utils/emitter.js';
import RichMessage from '@/components/home-v4/chat-elements/rich-message.jsx';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { cloneDeep, get, isEmpty, throttle } from 'lodash-es';
import WorkflowMessage from '@/components/home-v4/chat-elements/workflow-message.jsx';
import MapMessage from '@/components/home-v4/chat-elements/map-message.jsx';
import FeedbackModal from '@/components/home-v4/modals/feedback-modal.jsx';
import MapEntry from '@/components/home-v4/mobiles/map-entry.jsx';
import { useResponsive } from 'ahooks';
import { firebase_addDoc, firebase_getDocs, store_conversation_prefix } from '@/utils/firebase.js';
import { orderBy } from 'firebase/firestore';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserStoreSelectors } from '@/stores/useUserStore.js';
import { logEvent } from '@/utils/ga.js';
import cache from '@/utils/cache.js';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import { last as lastItem } from 'lodash-es';
import Typing from '@/components/home-v4/chat-elements/typing.jsx';
import { logOnCall } from '@/utils/log.js';

function ChatPanel({ className }) {
  const [params] = useSearchParams();
  const [isConnecting, setIsConnecting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const scrollRef = useRef(null);
  const [isAnswering, setIsAnswering] = useState(false);
  const [isShowFeedback, setIsShowFeedback] = useState(false);
  const [operateOptions] = useState(null);
  const updateIntent = useTripStoreSelectorsV4.use.updateIntent();
  const insertSuggestDay = useTripStoreSelectorsV4.use.insertSuggestDay();
  const setPickedIntent = useTripStoreSelectorsV4.use.setPickedIntent();
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();
  const addMapPlaces = useTripStoreSelectorsV4.use.addMapPlaces();
  const setIsCollecting = useGenerateStoreSelectors.use.setIsCollecting();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const setIsGenerating = useGenerateStoreSelectors.use.setIsGenerating();
  const sessionId = useTripStoreSelectorsV4.use.sessionId();
  const [scrollHeight, setScrollHeight] = useState(0);
  const scrollTimeoutRef = useRef(null);
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isLogin = useUserStoreSelectors.use.isLogin();
  const { md } = useResponsive();
  const setIsShowLogin = useUserStoreSelectors.use.setIsShowLogin();
  const navigate = useNavigate();

  const state = useRef({
    messages: [],
    mode: 0, //0-普通chat模式，1-预设plan模式
    isFirstConnect: true,
    isAnswering: false,
    isBottom: true,
    chipIndex: 0, //chip问题出现的顺序,
    sendTimes: 0,
    isReceivingDayTitle: false //是否正在接收新一天的标题
  });

  useEffect(() => {
    initData();
    initEvents();
    return () => {
      emitter.off(ZZEmittedEvent.addAIMessage);
      emitter.off(ZZEmittedEvent.afterPreference);
      emitter.off(ZZEmittedEvent.clickChip);
    };
  }, []);

  useEffect(() => {
    const updateScrollHeight = throttle(() => {
      if (scrollRef.current) {
        const { scrollHeight } = scrollRef.current;
        setScrollHeight(scrollHeight);
      }
    }, 200); // 200ms 的节流间隔，可以根据需要调整
    // 初次渲染时获取scrollHeight
    updateScrollHeight();
    // 监听div内容的变化
    const observer = new MutationObserver(() => {
      updateScrollHeight();
    });
    if (scrollRef.current) {
      observer.observe(scrollRef.current, {
        childList: true,
        subtree: true,
        characterData: true
      });
    }
    // 组件卸载时断开观察器
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    scrollBottom();
  }, [scrollHeight]);

  const triggerPlan = () => {
    logEvent({
      name: 'suggest_itinerary_start'
    });
    setIsGenerating(true);
    const generateParams = cache.getGenerateParams() || {};
    const p = {
      session_id: sessionId,
      task_type: ZZSocketTaskType.suggest_itinerary,
      task_id: dayjs().valueOf(),
      requirements: '',
      is_stream: true,
      ...generateParams
    };
    if (generateParams.travel_intent) {
      p.task_type = ZZSocketTaskType.collect_preferences;
      p.msg = '';
      setIsCollecting(true);
    }
    console.log('params', p);
    setIsAnswering(true);
    state.current.isAnswering = true;
    state.current.isBottom = true;
    // state.current.mode = 1;
    sendMessage(JSON.stringify(p));
  };

  const initData = async () => {
    if (params.get('type') == 'generate') {
      //生成的话，全部以socket返回的为准
      return;
    }
    setMessages([]);
    if (sessionId) {
      let historyMessages = [];
      historyMessages = await firebase_getDocs(`${store_conversation_prefix}${sessionId}`, {
        orderBy: orderBy('createdAt', 'asc')
      });
      if (isEmpty(historyMessages)) {
        const one = getMessageTemplate();
        one.isWaiting = false;
        one.isFinished = true;
        one.messages = [
          {
            type: ZZMessageType.text,
            id: nanoid(4),
            data: `Tell me your travel ideas and create the most perfect travel experience.`
          }
        ];
        state.current.messages = [one];
      } else {
        let places = [];
        for (const message of historyMessages) {
          message.isFinished = true;
          if (message.isPlaces) {
            places = places.concat(message.places);
          } else {
            places = message.messages?.filter(item => item.type === ZZMessageType.place).map(item => item.data);
          }
          addMapPlaces(places);
        }
        state.current.messages = historyMessages;
      }
    }
    console.log('state.current.messages-->', state.current.messages);
    setMessages(state.current.messages);
  };

  const initEvents = () => {
    emitter.on(ZZEmittedEvent.addAIMessage, onAddAiMessage);
    emitter.on(ZZEmittedEvent.afterPreference, onAfterPreference);
    emitter.on(ZZEmittedEvent.clickChip, chip => {
      onSend(chip);
    });
  };

  const onAfterPreference = async preference => {
    await updateIntent(preference);
    onAddAiMessage({
      messageType: 'afterPreference',
      message: `Help me find things to do based on my preferences.`
    });
  };

  const onAddAiMessage = object => {
    if (object.messageType === 'afterPreference') {
      onSend(object.message);
    }
  };

  const onReceiveMessage = event => {
    try {
      const res = JSON.parse(event.data);
      console.log('res-->', res);
      const { type, data, task_type } = res;
      //suggest itinerary的消息
      if (task_type === ZZSocketTaskType.suggest_itinerary) {
        setIsCollecting(false);
        resolveGenerateMessage(res);
        return;
      }
      if (task_type === ZZSocketTaskType.collect_preferences) {
        if (type === ZZMessageType.action) {
          if (data?.new_message) {
            logEvent({
              name: 'suggest_itinerary_start_after_chips'
            });
            let latest = lastItem(state.current.messages);
            latest.isFinished = true;
            state.current.messages[state.current.messages.length - 1] = cloneDeep(latest);
            firebase_addDoc(`${store_conversation_prefix}${sessionId}`, latest);
            const message = getMessageTemplate();
            state.current.messages.push(message);
            setMessages([...state.current.messages]);
            return;
          }
        }
      }
      if (!state.current.isAnswering) {
        return;
      }
      let latest = lastItem(state.current.messages);
      if (type === ZZMessageType.tool_parameter) {
        // latest.isWaiting = false;
      } else {
        latest.isWaiting = false;
      }
      if (type === ZZMessageType.text) {
        latest.messages.push({
          id: nanoid(4),
          type: ZZMessageType.text,
          data: data.text
        });
      } else if (type === ZZMessageType.place) {
        latest.messages.push({
          id: nanoid(4),
          type: ZZMessageType.place,
          data: data.place
        });
        addMapPlaces([data.place]);
      } else if (type === ZZMessageType.search_source) {
        latest.messages.push({
          id: nanoid(4),
          type: ZZMessageType.search_source,
          data: data
        });
      } else if (type === ZZMessageType.tool_parameter) {
        if (data.step) {
          latest.step = data.step;
        }
        if (data.tool === 'map_tool') {
          latest.isMap = true; //是地图的消息
        }
      } else if (type === ZZMessageType.tool_result) {
        if (!isEmpty(data.places)) {
          latest.isPlaces = true;
          latest.places = data.places.filter(item => !!item?.place_id);
          addMapPlaces(latest.places);
        } else if (!isEmpty(data.sources)) {
          if (latest.step) {
            latest.isWorkFlow = true; //是agentFlow的消息
          }
          latest.sources = data.sources;
        }
      } else if (type == ZZMessageType.chips) {
        state.current.chipIndex += 1;
        logEvent({
          name: `chatPage_chips_show_${state.current.chipIndex}`
        });
        latest.messages.push({
          id: nanoid(4),
          type: ZZMessageType.chips,
          data: data.chips || []
        });
      } else if (res.status == 201) {
        console.log('stream ending');
        setIsAnswering(false);
        state.current.isAnswering = false;
        latest.isFinished = true;
        if (!isReadOnly) {
          firebase_addDoc(`${store_conversation_prefix}${sessionId}`, latest);
        }
      }
      state.current.messages[state.current.messages.length - 1] = cloneDeep(latest);
      setMessages([...state.current.messages]);
    } catch (e) {
      let latest = lastItem(state.current.messages);
      latest.isFinished = true;
      if (latest) {
        state.current.messages[state.current.messages.length - 1] = cloneDeep(latest);
        setMessages([...state.current.messages]);
        console.log('e-->', e);
      }
    }
  };

  //处理suggest plan的消息
  const resolveGenerateMessage = res => {
    const { itineraryId, sessionId, itinerary } = useTripStoreV4.getState();
    const { status, data = {}, type } = res;
    let latest = lastItem(state.current.messages);
    latest.isWaiting = false;
    if (status == 500) {
      // state.current.mode = 0;
      state.current.isReceivingDayTitle = false;
      setIsGenerating(false);
      logEvent({
        name: 'suggest_itinerary_error'
      });
      logOnCall({
        message: `Generate itinerary 500 error,sessionId-${sessionId}`
      });
      toast.error('Oops,Error generating, please modify intent and try again.');
      navigate('/', {
        replace: true
      });
    } else if (status == 201) {
      logEvent({
        name: 'suggest_itinerary_end'
      });
      latest.isFinished = true;
      state.current.isReceivingDayTitle = false;
      state.current.messages[state.current.messages.length - 1] = cloneDeep(latest);
      setMessages([...state.current.messages]);
      emitter.emit(ZZEmittedEvent.addMyTrip);
      emitter.emit(ZZEmittedEvent.focusTargetDay, 'All');
      setIsAnswering(false);
      setIsGenerating(false);
      state.current.isAnswering = false;
      firebase_addDoc(`${store_conversation_prefix}${sessionId}`, latest);
      window.history.replaceState({}, '', `/chat?session_id=${sessionId}&id=${itineraryId}`);
      toast.success('The plan has been generated successfully.');
    } else {
      let isUpdate = false;
      if (type === ZZMessageType.action) {
        if (data?.action == 'new_day') {
          state.current.isReceivingDayTitle = true; //是否正在接收每天的
        }
        if (data?.new_message) {
          latest.isFinished = true;
          state.current.messages[state.current.messages.length - 1] = cloneDeep(latest);
          firebase_addDoc(`${store_conversation_prefix}${sessionId}`, latest);
          const message = getMessageTemplate();
          state.current.messages.push(message);
          setMessages([...state.current.messages]);
        }
      } else if (type === ZZMessageType.text) {
        if (data.text.includes('\n')) {
          // debugger;
          state.current.isReceivingDayTitle = false;
        }
        latest.messages.push({
          id: nanoid(4),
          type: ZZMessageType.text,
          data: state.current.isReceivingDayTitle ? `<span style="font-size:18px;font-weight: 500 ">${data.text}</span>` : data.text,
          isRich: state.current.isReceivingDayTitle
        });
        isUpdate = true;
      } else if (type === ZZMessageType.place) {
        latest.messages.push({
          id: nanoid(4),
          type: ZZMessageType.place,
          data: data.place
        });
        addMapPlaces([data.place]);
        isUpdate = true;
      } else if (type === ZZMessageType.video) {
        latest.messages.push({
          id: nanoid(4),
          type: ZZMessageType.video,
          data: data.videos || []
        });
        isUpdate = true;
      } else if (type == ZZMessageType.daily_itinerary) {
        if (!md && isEmpty(itinerary?.daily_itineraries)) {
          console.log('ZZEmittedEvent.mobileShowItinerary');
          emitter.emit(ZZEmittedEvent.mobileShowItinerary);
        }
        insertSuggestDay(data.daily);
        toast.success(`Day ${data.day} itinerary has been planned.`);
      } else if (type == ZZMessageType.full_itinerary) {
        if (!data.itinerary) {
          logOnCall({
            message: `full_itinerary is empty,sessionId-${sessionId}`
          });
          return;
        }
        updateItinerary(data.itinerary, false);
      } else if (type == ZZMessageType.travel_intent) {
        setPickedIntent(data.travel_intent);
      }
      if (isUpdate) {
        state.current.messages[state.current.messages.length - 1] = cloneDeep(latest);
        setMessages([...state.current.messages]);
      }
    }
  };

  const { sendMessage } = useWebSocket(`wss://${import.meta.env.VITE_SOCKET_HOST}/websocket/ws_task_v2`, {
    onOpen: () => {
      console.log('opened');
      setIsConnecting(false);
      if (state.current.isFirstConnect && params.get('type') == 'generate') {
        state.current.isFirstConnect = false;
        const one = getMessageTemplate();
        // one.isGenerate = true;
        state.current.messages = [one];
        setMessages([one]);
        triggerPlan();
      }
    },
    onClose: () => {
      console.log('closed');
      setIsAnswering(false);
      setIsConnecting(false);
      state.current.isAnswering = false;
    },
    onError: e => {
      console.log('socket error--->', e);
      setIsConnecting(false);
    },
    onReconnectStop: () => {
      console.log('reconnect');
      setIsConnecting(true);
    },
    onMessage: onReceiveMessage,
    shouldReconnect: () => true
  });

  const onSend = (input, event) => {
    state.current.sendTimes += 1;
    console.log('state.current.sendTimes', state.current.sendTimes);
    if (!isLogin && state.current.sendTimes >= 4) {
      setIsShowLogin(true);
      return;
    }
    const { pickedIntent, itinerary, sessionId } = useTripStoreV4.getState();
    let latest = lastItem(state.current.messages);
    logEvent({
      name: 'chat_send'
    });
    event?.preventDefault();
    if (!input || state.current.isAnswering) {
      return;
    }
    setIsAnswering(true);
    state.current.isAnswering = true;
    state.current.isBottom = true;
    const toMessage = {
      messages: [
        {
          id: nanoid(4),
          type: ZZMessageType.text,
          data: input
        }
      ],
      id: nanoid(5),
      isSelf: true
    };
    if (isReadOnly) {
      logEvent({
        name: 'share_chat_send'
      });
    } else {
      firebase_addDoc(`${store_conversation_prefix}${sessionId}`, toMessage);
    }
    state.current.messages = state.current.messages.concat([toMessage, getMessageTemplate()]);
    setMessages(state.current.messages);
    const conversation_context = {
      travel_intent: pickedIntent,
      itinerary: itinerary
    };
    const params = {
      session_id: sessionId,
      msg: input,
      task_type: ZZSocketTaskType.chat,
      task_id: dayjs().valueOf(),
      conversation_context
    };
    if (latest.messages.find(o => o.type == ZZMessageType.chips) != null) {
      const generateParams = cache.getGenerateParams() || {};
      params.travel_intent = generateParams.travel_intent;
      params.task_type = ZZSocketTaskType.collect_preferences;
      logEvent({
        name: `chatPage_chip_${state.current.chipIndex}_click`
      });
    }
    console.log('params-->', params);
    sendMessage(JSON.stringify(params));
    setInput('');
  };

  const onStopAnswer = () => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    let latest = lastItem(state.current.messages);
    latest.isWaiting = false;
    state.current.messages[state.current.messages.length - 1] = cloneDeep(latest);
    setMessages([...state.current.messages]);
    setIsAnswering(false);
    state.current.isAnswering = false;
  };

  const scrollBottom = () => {
    if (state.current.isBottom) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const onScroll = () => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = setTimeout(() => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        state.current.isBottom = scrollTop + clientHeight >= scrollHeight - 30;
      }
    }, 200); // 200ms后没有触发scroll事件，认为滚动结束
  };

  const onFeedbackFinished = message => {
    let latest = state.current.messages.find(o => o.id == message.id);
    latest.isDisLiked = true;
    const index = state.current.messages.indexOf(latest);
    state.current.messages[index] = cloneDeep(latest);
    setMessages([...state.current.messages]);
  };

  const onKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend(input, e);
    }
  };

  return (
    <div className={`h-full flex flex-col relative rounded-md md:rounded-2xl ${className} px-3 py-3 ${styles.chatPanel}`}>
      <Spin
        spinning={isConnecting || isEmpty(messages)}
        style={{ height: '100%' }}
        wrapperClassName={styles.spinWrapper}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
              color: 'black'
            }}
            spin
          />
        }
      >
        <div className="h-0 flex-grow overflow-y-auto w-full md:pr-2 pb-3" ref={scrollRef} onWheel={onScroll}>
          {messages.map(item => {
            return <MessageContentMemo message={item} key={item.id} />;
          })}
        </div>
        <div className={'flex flex-col items-stretch'}>
          {!md && <MapEntry />}
          <div className={`flex flex-col px-3 border border-black rounded-xl`}>
            <Input.TextArea
              rows={2}
              value={input}
              onKeyDown={onKeyDown}
              onChange={e => setInput(e.target.value)}
              placeholder="Please enter any questions about your trip."
              variant={'borderless'}
              style={{
                padding: '8px 4px',
                marginRight: 4,
                fontSize: 14,
                height: '100%',
                resize: 'none'
              }}
            />
            <div className={'flex items-center justify-between pb-2 pt-1'}>
              <span />
              {isAnswering ? (
                <i className={'iconfont icon-stop1 text-[34px] cursor-pointer'} onClick={onStopAnswer} />
              ) : (
                <Button
                  disabled={!input}
                  style={{ border: 'none' }}
                  shape="circle"
                  className={styles.sendBtn}
                  onClick={() => onSend(input)}
                  icon={<i className="iconfont icon-send text-[20px] mt-1 mr-0.5" />}
                />
              )}
            </div>
          </div>
        </div>
      </Spin>
      <FeedbackModal open={isShowFeedback} options={operateOptions} onCancel={() => setIsShowFeedback(false)} onFinished={onFeedbackFinished} />
    </div>
  );
}

function MessageContent({ message }) {
  const user = useUserStoreSelectors.use.user();
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const renderContent = () => {
    if (message.isWaiting) {
      // return <LoadingOutlined className={'text-theme text-[28px] font-semibold !text-black'} spin />;
      return <Typing />;
    } else if (message.isPlaces) {
      return <PlaceMessage message={message} />;
    } else if (message.isMap) {
      return <MapMessage message={message} />;
    } else if (message.isWorkFlow) {
      return <WorkflowMessage message={message} />;
    } else {
      return <RichMessage message={message} />;
    }
  };

  return (
    <div className={`${styles.item} px-0 md:px-3 ${message.isSelf ? styles.isSelf : ''} ${message.isWaiting ? styles.isWaiting : ''}`}>
      <>
        {message.isSelf ? (
          <div className={'w-10 h-10 rounded-full bg-white border text-black font-medium f-center ml-2 mb-1 hidden md:flex'}>
            {user ? <Image src={user.extra.avatar} width="100%" height="100%" className={'rounded-full'} /> : 'You'}
          </div>
        ) : (
          <img
            src={get(
              itinerary,
              'yt_video.snippet.channel.thumbnails.default.url',
              'https://storage.googleapis.com/solaris_melange/resources/ai-avatar.jpeg'
            )}
            alt=""
            className={'w-10 h-10 rounded-full mr-2 mb-1 shrink-0'}
          />
        )}
      </>
      <div className={`${styles.message}`}>{renderContent(message)}</div>
    </div>
  );
}

// 返回 true 时，React 会跳过组件的重新渲染；当返回 false 时，React 会重新渲染组件
const MessageContentMemo = memo(MessageContent, ({ message: message1 }, { message: message2 }) => {
  return (
    message1.isWaiting === message2.isWaiting &&
    message1.messages === message2.messages &&
    message1.places === message2.places &&
    message1.step === message2.step &&
    message1.sources === message2.sources
  );
});

export default ChatPanel;

function getMessageTemplate() {
  return {
    id: nanoid(5),
    messages: [],
    isSelf: false,
    isWaiting: true,
    isFinished: false,
    sources: [],
    step: null,
    isWorkFlow: false
  };
}
