import MapPanel from '@/components/home-v4/map-panel.jsx';
import ItineraryContainer from '@/components/home-v4/itinerary-elements/itinerary-container.jsx';
import styles from './left-panel.module.scss';
import IconMap from '../../assets/svgs/icon_map.svg?react';
import IconList from '../../assets/svgs/icon_list.svg?react';
import { useEffect, useRef, useState } from 'react';
import { logEvent } from '@/utils/ga.js';
import useGenerateStore, { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import UserEntryV2 from '@/components/commons/UserEntryV2/index.jsx';
import useTripStore_v4 from '@/stores/useTripStore_v4.js';
import { debounce } from 'lodash-es';
import useGlobalStore from '@/stores/useGlobalStore.js';

function LeftPanel() {
  const [activeTab, setActiveTab] = useState(1);
  const isCollecting = useGenerateStoreSelectors.use.isCollecting();
  const scrollContainerRef = useRef(null); // 引用滚动容器

  useEffect(() => {
    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);
    handleScroll(); // 初始化调用以检查初始可见状态
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = debounce(() => {
    const { setFocusPlayVideoKey } = useGlobalStore.getState();
    const { itinerary } = useTripStore_v4.getState();
    const { isGenerating } = useGenerateStore.getState();
    if (!itinerary || isGenerating) {
      return;
    }
    const container = scrollContainerRef.current;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const containerCenter = containerRect.top + containerRect.height / 2;
      let closestKey = null;
      let closestDistance = Infinity;
      itinerary?.daily_itineraries?.forEach(day => {
        day?.places?.forEach(place => {
          const placeElement = document.getElementById(`youtube-cover-${place.key}`);
          if (!placeElement) return;
          const rect = placeElement.getBoundingClientRect();
          const videoCenter = rect.top + rect.height / 2;
          const distance = Math.abs(videoCenter - containerCenter);

          if (distance < closestDistance) {
            closestDistance = distance;
            closestKey = place.key;
          }
        });
      });
      setFocusPlayVideoKey(closestKey);
    }
  }, 400);

  const onSwitch = type => {
    setActiveTab(type);
    const name = `itinerary_tab_${type == 0 ? 'map' : 'list'}_click`;
    console.log('name', name);
    logEvent({ name });
  };

  return (
    <div className={'h-full overflow-y-auto relative'} ref={scrollContainerRef}>
      <span className={`${styles.tabs} absolute left-4 top-2 md:top-4 md:right-4 md:left-auto md:translate-x-0 ${isCollecting ? '!hidden' : ''}`}>
        <span className={`${styles.tab} ${activeTab == 0 ? styles.active : ''}`} onClick={() => onSwitch(0)}>
          <IconMap className={'mr-1'} />
          Map View
        </span>
        <span className={`${styles.tab} ${activeTab == 1 ? styles.active : ''}`} onClick={() => onSwitch(1)}>
          <IconList className={'mr-1'} />
          List View
        </span>
      </span>
      <span className={'absolute right-3 top-2.5 z-20 md:hidden'}>
        <UserEntryV2 />
      </span>
      <MapPanel className={`${activeTab == 0 ? '' : 'hidden'}`} />
      <ItineraryContainer className={`${activeTab == 1 ? '' : 'hidden'}`} />
    </div>
  );
}

export default LeftPanel;
