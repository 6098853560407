import ajax from '@/utils/ajax';

export function addMyTrip(data) {
  return ajax({
    url: '/api/trip',
    data
  });
}

export function updateTrip(data) {
  return ajax({
    url: '/api/trip',
    data
  });
}

export function deleteTrip(data) {
  return ajax({
    url: '/api/trip',
    data
  });
}

export function getMyTrips(params) {
  return ajax({
    url: '/api/trip',
    params,
    method: 'GET'
  });
}

export function getTripInfo(params) {
  return ajax({
    url: '/api/trip',
    params,
    method: 'GET',
    hideLoading: true
  });
}

export function searchYoutube(data) {
  return ajax({
    url: '/api/search_youtube',
    data
  });
}

export function getDirections(data) {
  return ajax({
    url: '/tkb/get_directions',
    data
  });
}

//后台自己组装route
export function addRouteTrip(data) {
  return ajax({
    url: '/api/add_route_trip',
    data
  });
}

//获取iframely数据
export function getIframelyData(params) {
  return ajax({
    url: '/api/iframe',
    params,
    method: 'GET'
  });
}

export function addItineraryPlace(data) {
  return ajax({
    url: '/api/user_places',
    data
  });
}

export function removeItineraryPlace(data) {
  return ajax({
    url: '/api/user_places',
    data
  });
}

export function getItineraryPlaces(params) {
  return ajax({
    url: '/api/user_places',
    params,
    method: 'GET'
  });
}

export function getPlaceInfo(params) {
  return ajax({
    url: '/api/detailed',
    params,
    method: 'GET'
  });
}
