import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/tailwind.scss';
import './styles/antd.scss';
import './styles/googlemap.scss';
import 'react-photo-view/dist/react-photo-view.css';
import { initGA } from '@/utils/ga.js';
import App from './app.jsx';
import '@/utils/firebase.js';
import * as Sentry from '@sentry/react';

// import { logOnCall } from '@/utils/log.js';
// import { debounce } from 'lodash-es';

const originalConsoleError = console.error;

// 自定义 console.error 方法
console.error = function(...args) {
  // 定义需要屏蔽的错误信息或关键词
  const blockedErrors = ['findDOMNode is deprecated', 'Support for defaultProps will be removed from memo components in a future major release'];

  // 检查错误信息是否包含在屏蔽列表中
  const shouldBlock = args.some(arg => {
    return typeof arg === 'string' && blockedErrors.some(blockedError => arg.includes(blockedError));
  });
  // 如果错误信息不在屏蔽列表中，则调用原始的 console.error 方法
  if (!shouldBlock) {
    originalConsoleError.apply(console, args);
  }
};

Sentry.init({
  dsn: 'https://ea189a637fa302586d96b59be0f743a3@o1217849.ingest.us.sentry.io/4508007970570240',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.MODE !== 'dev'
});

initGA();

// const debouncedLogOnCall = debounce(logOnCall, 1000); // 设置为 1 秒防抖

// window.onerror = function(message, source, lineno, colno, error) {
//   debouncedLogOnCall({
//     message,
//     source,
//     lineno,
//     colno,
//     stack: error.stack
//   });
//   // 防止默认处理（如控制台输出）
//   return import.meta.env.MODE !== 'dev';
// };

// window.addEventListener('unhandledrejection', event => {
//   console.log('unhandledrejection--->', event);
//   debouncedLogOnCall({
//     type: 'unhandledrejection',
//     message: event.reason.message,
//     stack: event.reason.stack
//   });
// });

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
