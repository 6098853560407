import { Button, Dropdown, Input, Modal, Popover, Skeleton } from 'antd';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import styles from './itinerary-container.module.scss';
import { useEffect, useRef, useState } from 'react';
import ItineraryPanel from '@/components/home-v4/itinerary-elements/itinerary-panel.jsx';
import FlightsPanel from '@/components/home-v4/itinerary-elements/flights-panel.jsx';
import HotelsPanel from '@/components/home-v4/itinerary-elements/hotels-panel.jsx';
import { ClockCircleOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { useResponsive, useToggle } from 'ahooks';
import { copy_itinerary } from '@/apis/trips_v4.js';
import cache from '@/utils/cache.js';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { ZZEmittedEvent, ZZUploadType } from '@/utils/const.js';
import emitter from '@/utils/emitter.js';
import PreviewModal from '@/components/home-v4/modals/preview-modal.jsx';
import { useUserStoreSelectors } from '@/stores/useUserStore.js';
import { getArray, getUniqueId } from '@/utils/index.js';
import { logEvent, tagEvent } from '@/utils/ga.js';
import { getTripDestination } from '@/utils/trip.js';
import IconLocation from '../../../assets/svgs/icon_location.svg?react';
import IconFlight from '../../../assets/svgs/icon_flight.svg?react';
import IconHotel from '../../../assets/svgs/icon_hotel.svg?react';
import IconCamera from '../../../assets/svgs/icon_camera.svg?react';
import IconShare from '../../../assets/svgs/icon_share.svg?react';
import IconLink from '../../../assets/svgs/icon_link.svg?react';
import IconImage from '../../../assets/svgs/icon_image.svg?react';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import { isEmpty } from 'lodash-es';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';

function ItineraryContainer({ className = '' }) {
  const [tab, setTab] = useState('Itinerary');
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const isLogin = useUserStoreSelectors.use.isLogin();
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const pickedIntent = useTripStoreSelectorsV4.use.pickedIntent();
  const attraction_count = useTripStoreSelectorsV4.use.attraction_count();
  const [modal, contextHolder] = Modal.useModal();
  const [isCopying, setIsCopying] = useState(false);
  const { md } = useResponsive();
  const [, copyToClipboard] = useCopyToClipboard();
  const [isShowPreview, { setLeft, setRight }] = useToggle();
  const toggleIsShowUpload = useGlobalStoreSelectors.use.toggleIsShowUpload();
  const setIsShowLogin = useUserStoreSelectors.use.setIsShowLogin();

  const state = useRef({
    inputName: ''
  });

  useEffect(() => {
    initEvent();
    return () => {
      emitter.off(ZZEmittedEvent.changeTripTab);
    };
  }, []);

  const initEvent = () => {
    emitter.on(ZZEmittedEvent.changeTripTab, tab => {
      setTab(tab);
    });
  };

  const onTabChange = key => {
    if (key == 'Hotels') {
      logEvent({
        name: 'itinerary_hotelTab_click'
      });
    }
    setTab(key);
  };

  const onChange = e => {
    state.current.inputName = e.target.value;
  };

  const onEditTitle = async () => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    const { itinerary } = useTripStoreV4.getState();
    state.current.inputName = itinerary?.title;
    await modal.confirm({
      centered: true,
      width: md ? 750 : '90vw',
      className: 'confirmModal full-footer',
      icon: null,
      closable: true,
      title: 'Edit itinerary title',
      content: (
        <>
          <Input
            defaultValue={itinerary?.title}
            placeholder="Please input trip name."
            onChange={onChange}
            allowClear
            size={'large'}
            style={{
              height: 52,
              marginTop: 8,
              fontSize: 15
            }}
          />
        </>
      ),
      onOk: async close => {
        itinerary.title = state.current.inputName;
        updateItinerary(itinerary);
        toast.success('Edit trip title success.');
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onBeforeFetchShareLink = async () => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    tagEvent('event', 'conversion', {
      send_to: 'AW-16706051350/J7-qCP6VktUZEJa6iJ4-'
    });
    if (isLogin) {
      onFetchShareLink();
    } else {
      setIsShowLogin(true, () => {
        onFetchShareLink();
      });
    }
  };

  const onFetchShareLink = async () => {
    try {
      setIsCopying(true);
      const { sessionId, itineraryId } = useTripStoreV4.getState();
      const new_session_id = `${cache.getDeviceId()}_${getUniqueId(6)}`;
      const data = await copy_itinerary({
        old_session_id: sessionId,
        new_session_id: new_session_id,
        itinerary_id: itineraryId
      });
      // await firebase_copyCollection(`${store_conversation_prefix}${sessionId}`, `${store_conversation_prefix}${new_session_id}`);
      if (isReadOnly) {
        window.location.href = `/chat?session_id=${data.session_id}&id=${data.itinerary_id}&type=duplicate`;
      } else {
        const url = `${window.location.origin}/share?session_id=${data.session_id}&id=${data.itinerary_id}&type=share`;
        await copyToClipboard(url);
        toast.success('Link copied.');
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsCopying(false);
    }
  };

  const onShowPreview = () => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    tagEvent('event', 'conversion', {
      send_to: 'AW-16706051350/J7-qCP6VktUZEJa6iJ4-'
    });
    if (isLogin) {
      setRight();
    } else {
      setIsShowLogin(true, () => {
        setRight();
      });
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <span onClick={onBeforeFetchShareLink} className={'flex items-center py-1'}>
          <IconLink className={'mr-3'} />
          Copy Link
        </span>
      )
    },
    {
      key: '2',
      label: (
        <span onClick={onShowPreview} className={'flex items-center py-1'}>
          <IconImage className={'mr-3'} />
          Generate a image
        </span>
      )
    }
  ].filter(item => {
    return !(item.key == 2 && !md);
  });

  const onSave = () => {
    tagEvent('event', 'conversion', {
      send_to: 'AW-16706051350/aB1VCL6p2tQZEJa6iJ4-'
    });
    onFetchShareLink();
  };

  const onUpload = type => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    toggleIsShowUpload(true, type);
  };

  return (
    <div className={`${className} relative h-full`}>
      {isEmpty(itinerary?.daily_itineraries) ? (
        <ItineraryEmpty />
      ) : (
        <>
          <div className={`w-full h-full flex flex-col pt-16 md:pt-4 px-2`}>
            <div className={'flex items-center'}>
              {isReadOnly ? (
                <Button type={'primary'} shape={'round'} className="mt-3 md:mt-1 mr-3" onClick={onSave} loading={isCopying}>
                  Save to my itinerary
                </Button>
              ) : (
                <Dropdown trigger={['hover', 'click']} menu={{ items }} placement="bottomLeft">
                  <Button type="text" loading={isCopying} icon={<IconShare />} className={`!text-[15px] !font-medium mt-1`}>
                    Share
                  </Button>
                </Dropdown>
              )}
            </div>
            <div className={`pl-3 md:pr-[260px] font-medium text-[20px] md:text-[30px] my-3 ${styles.header}`}>
              {itinerary?.title}
              {isGenerating ? (
                <LoadingOutlined style={{ fontSize: 24, color: 'black', marginRight: 12 }} />
              ) : (
                <EditOutlined className={`text-[18px] ml-3 text-[#666] md:hidden ${styles.titleEdit}`} onClick={onEditTitle} />
              )}
            </div>
            <div className={'px-3 font-medium flex items-center justify-between flex-wrap'}>
              <div className={'flex items-center mr-3'}>
                <span className="f-center">
                  <IconCamera className={'text-[#DE3151] mr-1'} />
                  <span>{attraction_count} attractions</span>
                  <span className={'text-[18px] mx-2 text-[#6B7280]'}>·</span>
                </span>
                <span className="f-center">
                  <ClockCircleOutlined className={'text-[#DE3151] mr-1'} />
                  {itinerary?.daily_itineraries?.length} {itinerary?.daily_itineraries?.length > 1 ? 'days' : 'day'}
                  <span className={'text-[18px] mx-2 text-[#6B7280]'}>·</span>
                </span>
                {pickedIntent?.companions?.number_of_adults && (
                  <span className="f-center">
                    {pickedIntent?.companions?.number_of_adults + pickedIntent?.companions?.number_of_kids} travelers
                    <span className={'text-[18px] mx-2 text-[#6B7280]'}>·</span>
                  </span>
                )}
                {pickedIntent?.destination_places?.length <= 1 ? (
                  <span className={'text-[#6B7280]'}>{getTripDestination(pickedIntent)}</span>
                ) : (
                  <Popover
                    zIndex={2000}
                    placement="bottom"
                    content={
                      <ul>
                        {pickedIntent?.destination_places.map(item => (
                          <li key={item.place_id} className={'mb-1'}>
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    }
                  >
                    <span className={'cursor-pointer text-[#6B7280] border-b-[#6B7280] border-b-0.5'}>
                      {pickedIntent?.destination_places?.length} {pickedIntent?.destination_places?.length > 1 ? 'destinations' : 'destination'}
                    </span>
                  </Popover>
                )}
              </div>
            </div>
            <div className={'flex flex-col items-start md:flex-row md:items-center md:justify-between px-3 md:mb-2'}>
              <div className={`${styles.tabs} flex items-center`}>
                <span className={`${styles.tab} ${tab == 'Itinerary' ? styles.active : ''}`} onClick={() => onTabChange('Itinerary')}>
                  <IconLocation className={'mr-1'} />
                  Itinerary
                </span>
                <span className={`${styles.tab} ${tab == 'Flights' ? styles.active : ''}`} onClick={() => onTabChange('Flights')}>
                  <IconFlight className={'mr-1'} />
                  Flights
                </span>
                <span className={`${styles.tab} ${tab == 'Hotels' ? styles.active : ''}`} onClick={() => onTabChange('Hotels')}>
                  <IconHotel className={'mr-1'} />
                  Hotels
                </span>
              </div>
              {tab === 'Flights' && !isEmpty(itinerary?.flights) && (
                <Button onClick={() => onUpload(ZZUploadType.flight)} className={'!font-medium my-3 md:mt-0'} icon={<IconFlight />}>
                  Add Flight
                </Button>
              )}
              {tab === 'Hotels' && !isEmpty(itinerary?.hotels) && (
                <Button onClick={() => onUpload(ZZUploadType.hotel)} className={'!font-medium my-3 md:mt-0'} icon={<IconHotel />}>
                  Add Hotel
                </Button>
              )}
            </div>
            <ItineraryPanel className={`${tab === 'Itinerary' ? '' : 'hidden'}`} />
            <FlightsPanel className={`${tab === 'Flights' ? '' : 'hidden'}`} />
            <HotelsPanel className={`${tab === 'Hotels' ? '' : 'hidden'}`} />
          </div>
          <PreviewModal open={isShowPreview} onCancel={setLeft} />
          {contextHolder}
        </>
      )}
    </div>
  );
}

function ItineraryEmpty() {
  const { md } = useResponsive();
  const isCollecting = useGenerateStoreSelectors.use.isCollecting();

  return isCollecting ? (
    <div className={'h-full'}>
      <img src="https://storage.googleapis.com/solaris_melange/resources/home_bg.webp" alt="" className={'h-full object-cover'} />
    </div>
  ) : (
    <div className={'pt-16 md:pt-7 px-5 relative overflow-x-hidden'}>
      {getArray(md ? 4 : 3).map(item => {
        return (
          <div className={'flex items-center mb-5'} key={item}>
            <Skeleton.Image active style={{ width: 160, height: 160, marginRight: 12 }} />
            <Skeleton
              active
              loading
              paragraph={{
                rows: 4
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default ItineraryContainer;
