import usePlaceV4 from '@/hooks/usePlace_v4.jsx';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useCallback, useEffect, useState } from 'react';
import { logEvent } from '@/utils/ga.js';
import ImagesCarousel from '@/components/commons/ImagesCarousel/index.jsx';
import { Button, Dropdown } from 'antd';
import IconStar from '../../../assets/svgs/icon_star.svg?react';
import { formatNumber } from '@/utils/index.js';
import { PlusOutlined } from '@ant-design/icons';
import useSource from '@/hooks/useSource.js';
import { ZZEmittedEvent, ZZSourceType } from '@/utils/const.js';
import emitter from '@/utils/emitter.js';

export default function PopoverContent({ data, isPlace, onClickSource }) {
  const { getSourceName } = useSource();

  const onClick = useCallback(() => {
    onClickSource(data);
  }, []);

  return isPlace ? (
    <AttractionContent place={data} />
  ) : (
    <div className={'flex flex-col max-w-[400px] min-w-[200px] p-3 cursor-pointer'}>
      <div className={'flex items-center font-medium text-[12px] text-[#777]'}>
        <SourceLogo type={data.type} url={data?.url} />
        <span>{getSourceName({ source: data })}</span>
      </div>
      <div className={`mt-1 text-[13px] font-medium ellipsis-1`} onClick={onClick}>
        {data.title}
      </div>
      <div className={`mt-1 text-[13px] ellipsis-4`} onClick={onClick}>
        {data.content}
      </div>
    </div>
  );
}

function AttractionContent({ place }) {
  const { onAddPlace } = usePlaceV4();
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();
  const deleteAttraction = useTripStoreSelectorsV4.use.deleteAttraction();
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const [inItineraryDay, setInItineraryDay] = useState(-1);

  useEffect(() => {
    if (place?.place_id) {
      const { itinerary } = useTripStoreV4.getState();
      const d = itinerary?.daily_itineraries.findIndex(day => {
        const idList = day.places.map(p => p.place_id);
        return !!idList.includes(place?.place_id);
      });
      setInItineraryDay(d);
    }
  }, [place, itinerary]);

  const onDetail = () => {
    toggleIsShowPlaceInfo({
      flag: true,
      placeId: place.place_id
    });
  };

  const onDetailWithReview = e => {
    e.stopPropagation();
    toggleIsShowPlaceInfo({
      flag: true,
      placeId: place.place_id,
      isToReviews: true
    });
  };

  const onDelete = () => {
    logEvent({
      name: 'itinerary_attraction_delete_confirm'
    });
    deleteAttraction(inItineraryDay, place);
    setInItineraryDay(-1);
  };

  const onMoveAttraction = () => {
    const attractionIndex = itinerary?.daily_itineraries[inItineraryDay].places.findIndex(p => p.place_id === place.place_id);
    emitter.emit(ZZEmittedEvent.attractionMoveTo, {
      day_index_from: inItineraryDay,
      attraction_index_from: attractionIndex
    });
  };

  const getItems = () => {
    const items = [
      {
        key: 'detail',
        label: 'View details',
        onClick: onDetail
      }
    ];
    if (inItineraryDay >= 0) {
      items.push({
        key: 'move',
        label: 'Move to',
        onClick: onMoveAttraction
      });
      items.push({
        key: 'remove',
        label: 'Delete',
        onClick: onDelete
      });
    }
    return items;
  };

  return place ? (
    <div className={'w-[300px] relative shadow-2xl'}>
      <div onClick={e => e.stopPropagation()} className={'h-[200px] w-full object-cover'}>
        <ImagesCarousel images={place?.photos || []} />
      </div>
      <div className={'py-4 px-5'}>
        <div className={'font-medium text-[18px] mb-1.5 flex items-center justify-between'}>
          <span className={'mr-2 flex-shrink'}>{place.name}</span>
          <Dropdown menu={{ items: getItems() }} placement="bottom">
            <Button size={'small'} shape={'circle'} icon={<i className={'icon iconfont icon-gengduo f-center'} />} />
          </Dropdown>
        </div>
        <div className={'flex items-center mb-1.5 cursor-pointer'} onClick={onDetailWithReview}>
          <span className={'font-medium'}>{place?.user_rating}</span>
          <IconStar className={'ml-0.5 mr-1 mb-0.5'} />
          <span className={'text-[#374151]'}>({formatNumber(place?.total_user_reviews)} reviews)</span>
        </div>
        <div className={'text-[14px] text-[#6B7280] ellipsis-3 mb-1.5'}>{place.description}</div>
        {inItineraryDay < 0 && (
          <Button onClick={e => onAddPlace(e, place)} icon={<PlusOutlined />}>
            Add to trip
          </Button>
        )}
      </div>
    </div>
  ) : null;
}

export function SourceLogo({ type, url }) {
  let type2 = type || ZZSourceType.attraction;
  const maps = {
    [ZZSourceType.webpage]: (
      // <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
      //   <use xlinkHref="#icon-website"></use>
      // </svg>
      <img
        src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=256`}
        alt=""
        className={'w-4 h-4 mr-1.5'}
      />
    ),
    [ZZSourceType.youtube]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-youtube1"></use>
      </svg>
    ),
    [ZZSourceType.reddit]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-reddit"></use>
      </svg>
    ),
    [ZZSourceType.hotel]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-hotel-line"></use>
      </svg>
    ),
    [ZZSourceType.restaurant]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-restaurant-15"></use>
      </svg>
    ),
    [ZZSourceType.city]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-basesettingCityCate"></use>
      </svg>
    ),
    [ZZSourceType.attraction]: (
      <svg className="icon w-4 h-4 mr-1.5" aria-hidden="true">
        <use xlinkHref="#icon-place"></use>
      </svg>
    )
  };
  let logo = maps[type2] || null;
  if (type2 === 'webpage' && !!url) {
    if (url?.includes('yelp')) {
      logo = (
        <svg className="icon w-4 h-4 mr-1.5 shrink-0" aria-hidden="true">
          <use xlinkHref="#icon-yelp"></use>
        </svg>
      );
    } else if (url?.includes('tripadvisor')) {
      logo = (
        <svg className="icon w-4 h-4 mr-1.5 shrink-0" aria-hidden="true">
          <use xlinkHref="#icon-tripadvisor"></use>
        </svg>
      );
    }
  }
  return logo;
}
