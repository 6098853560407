function Page(props) {
  let { className, ...otherProps } = props;
  return (
    <div
      style={{ backgroundColor: "white" }}
      {...otherProps}
      className={`flex flex-col ${className} w-full h-full`}
    >
      {props.children}
    </div>
  );
}

export default Page;
