import styles from './day-hotels.module.scss';
import { ZZUploadType } from '@/utils/const.js';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import toast from '@/utils/toast.js';
import { get } from 'lodash-es';
import { getImageUrl } from '@/utils/index.js';
import IconNoStay from '../../../assets/svgs/icon_no_stay.svg?react';
import dayjs from 'dayjs';
import IconHotel from '../../../assets/svgs/icon_hotel_line.svg?react';
import { Button } from 'antd';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import ImageWrapper from '@/components/commons/ImageWrapper/index.jsx';

function DayHotels({ place, dayIndex, className, ...props }) {
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const toggleIsShowUpload = useGlobalStoreSelectors.use.toggleIsShowUpload();

  // const onClickHotel = hotel => {
  //   if (isReadOnly) {
  //     return;
  //   }
  //   if (isGenerating) {
  //     toast.info('The itinerary is generating...');
  //     return;
  //   }
  //   if (hotel.place_type === 'place_to_stay') {
  //     emitter.emit(ZZEmittedEvent.editStayCity, {
  //       hotel,
  //       day,
  //       dayIndex
  //     });
  //   } else if (hotel.place_type === 'hotel') {
  //     emitter.emit(ZZEmittedEvent.changeTripTab, 'Hotels');
  //   }
  // };

  const onUpload = () => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    toggleIsShowUpload(true, ZZUploadType.hotel, null);
  };

  const getHotelDates = () => {
    if (!place.check_in_time && !place.check_out_time) {
      return '';
    }
    const formatDate = date => {
      return `${date.year}-${date.month}-${date.day}`;
    };
    const checkin_day = dayjs(formatDate(place.check_in_time.date)).format('MMM D, YYYY');
    if (!place.check_out_time) {
      return checkin_day;
    }
    const checkout_day = dayjs(formatDate(place.check_out_time?.date)).format('MMM D, YYYY');
    return checkin_day === checkout_day ? checkin_day : `${checkin_day} - ${checkout_day}`;
  };

  return (
    <div className={`${className}`} {...props}>
      {place && (
        <div className={`${styles.place} relative flex items-start md:items-center justify-between`}>
          <div className={'flex items-center'}>
            <div className={'w-20 h-20 flex-shrink-0 mr-5'}>
              {get(place, 'photos.0.url') ? (
                <ImageWrapper
                  src={getImageUrl(get(place, 'photos.0.url', ''), 300)}
                  alt=""
                  className={'h-full w-full object-cover shrink-0 rounded-xl'}
                />
              ) : (
                <div className={'f-center w-full h-full border rounded-xl'}>
                  <IconNoStay />
                </div>
              )}
            </div>
            <div className={`flex flex-col`}>
              <div className={'text-[14px]'}>{getHotelDates()}</div>
              <div className={'flex items-center font-medium text-[20px] text-[#374151]'}>{place.name}</div>
              <div className={'text-[16px] text-[#6B7280]'}>{place.address}</div>
            </div>
          </div>
          {place.place_type === 'place_to_stay' && !isReadOnly && (
            <Button onClick={onUpload} className={'mb-4 !font-medium'} icon={<IconHotel />}>
              Add Hotel
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default DayHotels;
