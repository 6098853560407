import { useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import client from '@/utils/algolia.js';
import { debounce, get, uniqBy } from 'lodash-es';
import { SearchOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { getImageUrl } from '@/utils/index.js';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';

function HotelSearchBox(props) {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const pickedIntent = useTripStoreSelectorsV4.use.pickedIntent();

  const debounceSearch = useMemo(() => {
    const onSearch = async text => {
      try {
        setFetching(true);
        const destination = pickedIntent.destination_places[0];
        const { results } = await client.search({
          requests: [
            {
              indexName: 'france_attraction',
              query: text,
              restrictSearchableAttributes: ['name'],
              hitsPerPage: 15,
              filters: 'place_type:HOTEL',
              aroundLatLng: `${destination.latitude},${destination.longitude}`,
              aroundRadius: 500000 // 半径设置为500km（单位：米）
            }
          ]
        });
        console.log('results', results);
        let list = results[0]?.hits || [];
        list = uniqBy(list, item => item.place_id);
        list = list.map(o => {
          return {
            value: o.place_id,
            label: o.name,
            ...o
          };
        });
        setOptions(list);
      } catch (e) {
        console.log('e', e);
      }
    };
    return debounce(onSearch, 400);
  }, []);

  const onSelect = (_, option) => {
    console.log('item-->', option);
    if (props.onChange) {
      props.onChange(option);
    }
  };

  return (
    <Select
      {...props}
      showSearch
      options={options}
      onSearch={debounceSearch}
      filterOption={false}
      onSelect={onSelect}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      suffixIcon={<SearchOutlined className={'text-[20px]'} />}
      optionRender={option => (
        <div className={`flex items-center ${styles.item} ${styles.option}`}>
          <img src={getImageUrl(get(option, 'data.photos.0.url'), 300)} alt="" className={'w-10 h-10 rounded-md mr-3'} />
          <div className="flex flex-col w-0 flex-grow mr-3">
            <span className={'text-[14px] font-medium'}>{option.data.name}</span>
            <span className={'text-[13px] text-[#666] ellipsis-1'}>{option.data.city || option.data.region || option.data.country}</span>
          </div>
        </div>
      )}
    />
  );
}

export default HotelSearchBox;
