import { Button, Modal } from 'antd';
import extractVideoId, { ensureHttps, getQueryParam } from '@/utils/index.js';
// import useSource from '@/hooks/useSource.js';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import styles from '@/components/home-v4/modals/youtube-player-modal.module.scss';
import { useResponsive } from 'ahooks';

function YoutubePlayerModal({ open, onOk, video }) {
  const [isError] = useState(false);
  const [url, setUrl] = useState('');
  const { md } = useResponsive();
  // const { onExtractPlaces } = useSource();

  const onAfterOpenChange = open => {
    if (open) {
      init();
    }
  };

  const init = () => {
    const timestamp = dayjs().valueOf();
    let originUrl = ensureHttps(video?.url);
    let isShorts = originUrl.includes('shorts') && originUrl.includes('youtube');
    if (isShorts) {
      let url = originUrl.replace('shorts', 'embed');
      url = `${url}?autoplay=1&v=${timestamp}`;
      console.log('url--->', url);
      setUrl(url);
    } else if (originUrl) {
      const id = extractVideoId(originUrl);
      let t = getQueryParam(originUrl, 't') || 0;
      if (t) {
        t = t.replace('s', '');
      }
      let url = `https://www.youtube-nocookie.com/embed/${id}?autoplay=1&v=${timestamp}&start=${t}`;
      console.log('url--->', url);
      setUrl(url);
    }
  };

  // const init = () => {
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  //     window.onYouTubeIframeAPIReady = initializePlayer;
  //   } else {
  //     initializePlayer();
  //   }
  // };

  // const initializePlayer = () => {
  //   let url = null;
  //   const t = getQueryParam(video?.url, 't') || 0;
  //   const timestamp = dayjs().valueOf();
  //   if (video.isShorts) {
  //     url = video.url.replace('shorts', 'embed');
  //     url = `${url}?autoplay=1&v=${timestamp}`;
  //   } else if (video?.url) {
  //     const id = extractVideoId(video?.url);
  //     url = `https://www.youtube-nocookie.com/embed/${id}?autoplay=1&v=${timestamp}&start=${t}`;
  //   }
  //   const player = new window.YT.Player('player', {
  //     height: '500px',
  //     width: '100%',
  //     playerVars: {
  //       autoplay: 1,
  //       fs: 0
  //     },
  //     events: {
  //       onReady: () => {
  //         console.log('onReady-->', url, t);
  //         player?.loadVideoByUrl(url);
  //       },
  //       onStateChange: event => {
  //         console.log('onStateChange', event);
  //         if (event.data === window.YT.PlayerState.ENDED) {
  //           console.log('onStateChange end');
  //         } else if (event.data === window.YT.PlayerState.PAUSED) {
  //           console.log('onStateChange paused');
  //         } else if (event.data === window.YT.PlayerState.PLAYING) {
  //           console.log('onStateChange playing');
  //         }
  //       },
  //       onError: event => {
  //         console.log('error', event);
  //         setIsError(true);
  //       }
  //     }
  //   });
  // };

  const onPlayOrigin = useCallback(() => {
    window.open(video?.url);
  }, []);

  return (
    <Modal
      centered
      destroyOnClose
      afterOpenChange={onAfterOpenChange}
      title={video.title}
      open={open}
      onCancel={onOk}
      width={900}
      okText="Close"
      onOk={onOk}
      zIndex={2000}
      className={`${styles.youtubeModal} ${!md ? styles.mobile : ''}`}
      okButtonProps={{
        style: {
          display: 'none'
        }
      }}
      cancelButtonProps={{
        style: {
          display: 'none'
        }
      }}
    >
      {url && (
        <iframe
          width="100%"
          height="550px"
          src={url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          title={video?.title}
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      )}
      {/*<div*/}
      {/*  id="player"*/}
      {/*  style={{*/}
      {/*    width: '100%',*/}
      {/*    height: 550*/}
      {/*  }}*/}
      {/*/>*/}
      {[video?.isCanExtract, isError].some(o => o) ? (
        <div className={'f-center'}>
          {isError && (
            <Button className={'!rounded-[20px] !mx-4'} onClick={onPlayOrigin}>
              Play on Origin
            </Button>
          )}
          {/*{video?.isCanExtract && (*/}
          {/*  <Button type="primary" className={'!rounded-[20px] !mx-4'} onClick={e => onExtractPlaces({ source: video }, e)}>*/}
          {/*    Extract Places*/}
          {/*  </Button>*/}
          {/*)}*/}
        </div>
      ) : null}
    </Modal>
  );
}

export default YoutubePlayerModal;
