import { useState, useEffect, useRef } from 'react';
import { Row, Col, Empty, Button, Modal, Input, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './trips.module.scss';
import cache from '@/utils/cache.js';
import { getMyTrips, deleteTrip, updateTrip } from '@/apis/trips.js';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import dayjs from 'dayjs';
import { logEvent } from '@/utils/ga.js';
import { useResponsive } from 'ahooks';
import toast from '@/utils/toast.js';

function MyTrip() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const { md } = useResponsive();
  const state = useRef({
    inputName: ''
  });

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    if (!cache.getUser()) {
      navigate('/', {
        replace: true
      });
      return;
    }
    setLoading(true);
    const data = await getMyTrips({
      user_id: cache.getUser().user_id
    });
    const list = (data || []).map((item, index) => {
      return {
        ...item,
        cover: getImage(index)
      };
    });
    setList(list);
    setLoading(false);
  };

  const onEdit = item => {
    navigate(`/chat?id=${item.id}&session_id=${item.session_id}`);
  };

  const getImage = index => {
    // const index = random(1, 7);
    const n = (index % 7) + 1;
    return `https://storage.googleapis.com/solaris_melange/resources/bg${n}.jpg`;
  };

  const onChange = e => {
    state.current.inputName = e.target.value;
  };

  const onEditName = async (item, e) => {
    logEvent({
      name: 'trip-edit-title'
    });
    e.stopPropagation();
    state.current.inputName = item.extra_info.title;
    await modal.confirm({
      centered: true,
      width: md ? 750 : '90vw',
      className: 'confirmModal full-footer',
      icon: null,
      closable: true,
      title: 'Edit trip name',
      content: (
        <>
          <Input
            defaultValue={item.extra_info.title}
            placeholder="Please input trip name."
            onChange={onChange}
            allowClear
            size={'large'}
            style={{
              height: 52,
              marginTop: 8,
              fontSize: 15
            }}
          />
        </>
      ),
      onOk: async close => {
        const data = {
          ...item,
          extra_info: {
            ...item.extra_info,
            title: state.current.inputName
          }
        };
        await updateItem(data);
        toast.success('Edit trip name success.');
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const updateItem = async data => {
    await updateTrip(data);
    setList(list.map(item => (item.id === data.id ? data : item)));
  };

  const onDelete = async (item, e) => {
    logEvent({
      name: 'trip-delete'
    });
    e.stopPropagation();
    await modal.confirm({
      centered: true,
      title: 'Delete Confirm',
      content: 'Are you sure to delete this trip?',
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      onOk: async close => {
        const data = {
          ...item,
          state: 2
        };
        await deleteTrip(data);
        setList(list.filter(i => i.id !== item.id));
        toast.success('Delete trip success.');
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  return (
    <div className="w-full h-full">
      {loading ? (
        <Spin
          style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          spinning={loading}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 32,
                color: 'black'
              }}
              spin
            />
          }
        />
      ) : isEmpty(list) ? (
        <div className="h-full w-full f-center">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : (
        <div className="p-4">
          <Row gutter={16}>
            {list.map(item => (
              <Col key={item.id} span={24} md={6}>
                <div
                  className={`h-[200px] relative w-full f-center cursor-pointer ${styles.item} mb-4 rounded-xl overflow-hidden px-8`}
                  onClick={() => onEdit(item)}
                >
                  <img src={item.cover} alt="" className="absolute w-full h-full object-cover brightness-75" />
                  <span className="z-10 text-white text-xl font-medium flex items-center">
                    <span className={'mr-2'}>{item.extra_info?.title}</span>
                    <EditOutlined
                      className="text-[18px]"
                      onClick={e => {
                        onEditName(item, e);
                      }}
                    />
                  </span>
                  <span
                    className={`absolute py-2 px-2 bottom-0 inset-x-0 flex items-center justify-between`}
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.4)'
                    }}
                  >
                    <span className="text-white pl-1">{dayjs(item.created_time).format('YYYY-MM-DD HH:mm')}</span>
                    <span className="flex items-center justify-around">
                      <Button icon={<DeleteOutlined />} size="small" onClick={e => onDelete(item, e)} className="mr-4" />
                      <Button icon={<EditOutlined />} size="small" className="mr-1" />
                    </span>
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
      {contextHolder}
    </div>
  );
}

export default MyTrip;
