// import Lottie from 'react-lottie';
// import TypingJson from '@/assets/lotties/typing.json';
import styles from './typing.module.scss';

// function Typing() {
//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: TypingJson
//   };
//
//   return <Lottie options={defaultOptions} height={24} width={40} />;
// }

function Typing({ className }) {
  return (
    <span className={`${styles.loader} ${className}`}>
      <span />
      <span />
      <span />
    </span>
  );
}

export default Typing;
