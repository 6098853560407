import { Button, Modal } from 'antd';
import styles from './move-to-modal.module.scss';
import { useResponsive } from 'ahooks';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import dayjs from 'dayjs';
import { CheckOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { swapAttraction } from '@/apis/trips_v4.js';
import toast from '@/utils/toast.js';

function MoveToModal({ onCancel, attractionMoveToParams, ...props }) {
  const { md } = useResponsive();
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const [toIndex, setToIndex] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();

  useEffect(() => {
    setToIndex(attractionMoveToParams.day_index_from);
  }, [attractionMoveToParams]);

  const onChangeIndex = index => {
    setToIndex(index);
  };

  const getDate = date => {
    const formatDate = date => {
      return `${date.year}-${date.month}-${date.day}`;
    };
    return dayjs(formatDate(date)).format('MMM D, YYYY');
  };

  const onSubmit = async () => {
    try {
      if (attractionMoveToParams.day_index_from === toIndex) {
        onCancel();
      } else {
        setIsLoading(true);
        const { sessionId, itineraryId } = useTripStoreV4.getState();
        const attraction_index_to = itinerary.daily_itineraries[toIndex]?.places.length + 1;
        const data = await swapAttraction({
          session_id: sessionId,
          itinerary_id: itineraryId,
          day_index_from: attractionMoveToParams.day_index_from + 1,
          day_index_to: toIndex + 1,
          attraction_index_from: attractionMoveToParams.attraction_index_from + 1,
          attraction_index_to: attraction_index_to
        });
        updateItinerary(data, false);
        toast.success('Move success');
        onCancel();
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className={styles.moveModal}
      {...props}
      footer={
        <div className={'flex items-center w-[330px] justify-around'}>
          <Button onClick={onCancel} className={'w-[42%]'} size="large">
            Cancel
          </Button>
          <Button type={'primary'} onClick={onSubmit} className={'w-[42%]'} size="large" loading={isLoading}>
            Confirm
          </Button>
        </div>
      }
      centered
      width={md ? 350 : '100%'}
      onCancel={onCancel}
      zIndex={2000}
      destroyOnClose
    >
      <div className={styles.title}>Move attraction</div>
      <div className={`${styles.list}`}>
        {itinerary?.daily_itineraries.map((day, index) => {
          return (
            <div className={`${styles.item} ${toIndex == index ? styles.active : ''}`} key={day.id} onClick={() => onChangeIndex(index)}>
              <span className={'f-center'}>
                <span className={'text-[16px] shrink-0'}>Day {index + 1}</span>
                {!!day.date && (
                  <>
                    <span className={'mx-2'}>•</span>
                    <span>{getDate(day.date)}</span>
                  </>
                )}
              </span>
              <CheckOutlined className={styles.checked} />
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

export default MoveToModal;
