import { create } from 'zustand';
import cache from '@/utils/cache.js';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';

const useUserStore = create(set => ({
  user: cache.getUser(),
  isLogin: !!cache.getUser(),
  isShowLogin: false,
  afterLoginFunc: null,

  setUser: value => {
    set(() => ({
      user: value,
      isLogin: !!value
    }));
  },

  setIsLogin: value => {
    set(() => ({
      isLogin: value
    }));
  },

  setIsShowLogin: (flag, afterLoginFunc = null) => {
    set(() => ({
      isShowLogin: flag,
      afterLoginFunc
    }));
  }
}));

export default useUserStore;
export const useUserStoreSelectors = createSelectorFunctions(useUserStore);
