import RichMessage from '@/components/home-v4/chat-elements/rich-message.jsx';
import { ZZGoogleMapKey } from '@/utils/config.js';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';

function MapMessage({ message }) {
  const [directions, setDirections] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [url, setUrl] = useState('');

  useEffect(() => {
    setDirections(message?.step?.action?.directions || []);
  }, []);

  useEffect(() => {
    const d = directions?.[activeIndex];
    if (d) {
      setUrl(
        `https://www.google.com/maps/embed/v1/directions?origin=${d.origin}&destination=${
          d.destination
        }&mode=${d.traffic_mode.toLowerCase()}&key=${ZZGoogleMapKey}`
      );
    }
  }, [directions, activeIndex]);

  const onChangeIndex = index => {
    setActiveIndex(index);
  };

  return (
    <div className={'relative'}>
      {!isEmpty(directions) && (
        <span
          className={'absolute right-4 top-4 bg-white rounded-md flex flex-col overflow-x-hidden overflow-y-auto max-h-[160px]'}
          id="day-select"
          style={{ zIndex: 1000, border: '2xp solid black' }}
        >
          {directions.map((item, index) => (
            <span
              className={`flex items-center justify-between p-4 py-1.5 text-[13px] cursor-pointer hover:bg-[#78b7ef] hover:text-[white] ${
                index == activeIndex ? 'bg-[#0587f0] text-[white] font-medium' : ''
              }`}
              key={item.value}
              onClick={() => onChangeIndex(index)}
            >
              <span className={'mr-3 flex-shrink-0'}>Route {index + 1}</span>
            </span>
          ))}
        </span>
      )}
      {!!url && <iframe className={'rounded-md mb-3'} height={400} width={'100%'} src={url} />}
      <RichMessage message={message} />
    </div>
  );
}

export default MapMessage;
