import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import cache from '@/utils/cache.js';
import { nanoid } from 'nanoid';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import { getItineraryIntent, updateItineraryIntent } from '@/apis/trips_v4.js';
import { cloneDeep, uniqBy } from 'lodash-es';
import { delete_day, clear_day, insert_day } from '@/apis/trips_v4.js';
import { ZZBgColors, ZZThemeColor } from '@/utils/config.js';
import { getUniqueId } from '@/utils/index.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import emitter from '@/utils/emitter.js';
import toast from '@/utils/toast.js';

const getInitialState = () => {
  return {
    isReadOnly: false,

    sessionId: `${cache.getDeviceId()}_${getUniqueId(6)}`,

    pickedDestinations: [],
    pickedWho: null,
    pickedWhen: null,

    pickedIntent: null,

    itinerary: null,
    itineraryId: null,
    attraction_count: 0,
    must_attraction_count: 0,

    mapPlaces: []

    // routes: [] //导航数据
  };
};

const useTripStoreV4 = create(
  subscribeWithSelector((set, get) => ({
    ...getInitialState(),

    reset: () => set({ ...getInitialState() }),

    resetItineraryAndIntent: () => {
      set(() => ({
        pickedIntent: null,
        itinerary: null,
        itineraryId: null,
        attraction_count: 0,
        must_attraction_count: 0,
        mapPlaces: []
        // routes: [] //导航数据
      }));
    },

    setIsReadOnly: value => {
      set(() => ({
        isReadOnly: value
      }));
    },

    setSessionId: value => {
      set(() => ({
        sessionId: value
      }));
    },

    setPickedIntent: value => {
      set(() => ({
        pickedIntent: value
      }));
    },

    updatePickedIntent: values => {
      set(() => ({
        pickedIntent: {
          ...get().pickedIntent,
          ...values
        }
      }));
    },

    setPickedDestinations: value => {
      set(() => ({
        pickedDestinations: value
      }));
    },

    setPickedWho: value => {
      set(() => ({
        pickedWho: value
      }));
    },

    setPickedWhen: value => {
      set(() => ({
        pickedWhen: value
      }));
    },

    addMapPlaces: values => {
      let list = get().mapPlaces;
      const list_new = uniqBy(list.concat(values), o => {
        return o.place_id;
      });
      set(() => ({
        mapPlaces: list_new
      }));
    },

    fetchItineraryAndIntent: async () => {
      const { updateItinerary } = get();
      const searchParams = new URLSearchParams(window.location.search);
      const { travel_intent, itinerary } = await getItineraryIntent({
        session_id: searchParams.get('session_id')
      });
      itinerary?.flights?.reverse();
      itinerary?.hotels?.reverse();
      set(() => ({
        pickedIntent: travel_intent
      }));
      updateItinerary(itinerary, false);
    },

    deleteAttraction: (dayIndex, place) => {
      const { itinerary, updateItinerary } = get();
      const day = itinerary.daily_itineraries[dayIndex];
      const findIndex = day.places.findIndex(o => o.place_id === place.place_id);
      if (findIndex >= 0) {
        day.places.splice(findIndex, 1);
      }
      toast.success(`${place.name} has been successfully deleted.`);
      updateItinerary(itinerary);
    },

    updateDayTitle: (day, title) => {
      const { itinerary, updateItinerary } = get();
      day.introduction = title;
      updateItinerary(itinerary);
    },

    updateAttractionDuration: (day, attraction) => {
      const { itinerary, updateItinerary } = get();
      const index = day.places.indexOf(attraction);
      day.places.splice(index, 1, attraction);
      toast.success(`${attraction?.name} tour duration has been modified`);
      updateItinerary(itinerary);
    },

    clearDay: async index => {
      const { itineraryId, updateItinerary, sessionId } = get();
      const data = await clear_day({
        session_id: sessionId,
        itinerary_id: itineraryId,
        day_index: index
      });
      toast.success(`Day ${index} been successfully cleared.`);
      updateItinerary(data, false);
    },

    insertSuggestDay: day => {
      let { itinerary, updateItinerary } = get();
      if (!itinerary) {
        itinerary = {
          daily_itineraries: [],
          destinations: [],
          flights: [],
          hotels: [],
          id: null,
          title: ''
        };
      }
      // emitter.emit(ZZEmittedEvent.scrollToDay, itinerary.daily_itineraries.length);
      itinerary.daily_itineraries.push(day);
      updateItinerary(itinerary, false);
    },

    deleteDay: async index => {
      const { itineraryId, updateItinerary, sessionId } = get();
      const data = await delete_day({
        session_id: sessionId,
        itinerary_id: itineraryId,
        day_index: index
      });
      toast.success(`Day ${index} has been successfully deleted.`);
      updateItinerary(data, false);
    },

    insertDay: async (index, position) => {
      const { itineraryId, sessionId, updateItinerary } = get();
      const data = await insert_day({
        session_id: sessionId,
        itinerary_id: itineraryId,
        day_index: index,
        position
      });
      toast.success(`Successfully added.`);
      updateItinerary(data, false);
    },

    updateAttractionCount: () => {
      const { itinerary } = get();
      const attraction_count = itinerary?.daily_itineraries.reduce((acc, cur) => {
        return acc + cur.places.length;
      }, 0);
      const must_attraction_count = itinerary?.daily_itineraries.reduce((acc, cur) => {
        return (
          acc +
          cur.places.filter(item => item.place_type === 'attraction' || item.preference_type === 'must_do' || item.preference_type === 'optional')
            .length
        );
      }, 0);
      set(() => ({
        attraction_count,
        must_attraction_count
      }));
    },

    updateIntent: async values => {
      updateItineraryIntent({
        session_id: get().sessionId,
        travel_intent: {
          ...(get().pickedIntent || {}),
          ...values
        }
      });
    },

    syncToItinerary: async values => {
      updateItineraryIntent({
        session_id: get().sessionId,
        itinerary: values
      });
    },

    updateItinerary: (value, isSync = true) => {
      emitter.emit(ZZEmittedEvent.itineraryUpdated);
      value?.daily_itineraries.forEach((daily_itinerary, dayIndex) => {
        daily_itinerary.id = daily_itinerary.id || nanoid(4);
        daily_itinerary.dayIndex = dayIndex;
        daily_itinerary.color = ZZBgColors[dayIndex] || ZZThemeColor;
        daily_itinerary.places?.forEach(attraction => {
          attraction.color = daily_itinerary.color;
          attraction.key = attraction.key || nanoid(8);
          attraction.duration_minutes = attraction.duration_minutes || 120;
        });
      });

      const { syncToItinerary, updateAttractionCount } = get();
      set(() => ({
        itinerary: cloneDeep(value),
        itineraryId: value.id
      }));
      updateAttractionCount();
      if (isSync) {
        syncToItinerary(value);
      }
    }
  }))
);

export const useTripStoreSelectorsV4 = createSelectorFunctions(useTripStoreV4);
export default useTripStoreV4;
