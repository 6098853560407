import { EnvironmentOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import AttractionPopContent from '@/components/home-v4/chat-elements/attraction-pop-content.jsx';
import { useResponsive } from 'ahooks';

function PlaceMarker({ place, color }) {
  const { md } = useResponsive();

  const getIcon = () => {
    if (place?.place_type === 'hotel') {
      return (
        <svg className="icon text-[20px]" aria-hidden="true">
          <use xlinkHref="#icon-hotel-line"></use>
        </svg>
      );
    } else if (place?.place_type === 'restaurant') {
      return (
        <svg className="icon text-[20px]" aria-hidden="true">
          <use xlinkHref="#icon-restaurant-15"></use>
        </svg>
      );
    }
    return <EnvironmentOutlined className={'text-[20px]'} />;
  };

  return (
    <Popover
      trigger={md ? 'hover' : 'contextMenu'}
      content={<AttractionPopContent data={place} isPlace={true} />}
      overlayInnerStyle={{
        padding: 0,
        overflow: 'hidden'
      }}
    >
      <div className={'f-center relative'}>
        <span
          className={`w-8 h-8 rounded-full f-center shrink-0 ${color ? 'text-white' : 'text-black'}`}
          style={{ backgroundColor: color || 'white' }}
        >
          {getIcon()}
        </span>
        <span className={`w-[10em] text-[13px] font-medium leading-[1.05] ${color ? 'text-white' : 'text-black'} `}>
          <span className={`box-decoration-clone px-1 rounded-sm`} style={{ backgroundColor: color || 'white' }}>
            {place.name}
          </span>
        </span>
      </div>
    </Popover>
  );
}

export default PlaceMarker;
