import { Form, Input, Modal, Progress, Radio } from 'antd';
import styles from './generate-from-modal.module.scss';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ZZThemeColor } from '@/utils/config.js';
import { logEvent } from '@/utils/ga.js';
import cache from '@/utils/cache.js';
import toast from '@/utils/toast.js';
import { logOnCall } from '@/utils/log.js';

// https://www.reddit.com/r/ParisTravelGuide/comments/xs35ew/6_days_in_paris_firsttime_review_my_itinerary/
//joe的 https://www.reddit.com/r/travel/comments/1f85blr/beach_day _trip_from_orlando_1_day/
//youtube https://www.youtube.com/watch?v=Y7HVDYGSJsI&ab_channel=SofiaAbrantes

const example_link = 'https://www.youtube.com/watch?v=Y7HVDYGSJsI&ab_channel=SofiaAbrantes';

function GenerateFromModal(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const sessionId = useTripStoreSelectorsV4.use.sessionId();
  const [progress, setProgress] = useState(0);
  const [isShowProgress, setIsShowProgress] = useState(false);
  const [stateText, setStateText] = useState('');

  const onSubmit = () => {
    logEvent({
      name: 'generate_plan_submit'
    });
    const values = form.getFieldsValue();
    if (!values.url) {
      values.platform = 1;
      values.url = example_link;
    }
    if (!values.url.includes('youtube') && !values.url.includes('youtu.be') && !values.url.includes('reddit')) {
      toast.info('Unsupported link, please replace and try again.');
      logOnCall({
        message: `Unsupported link ${values.url}`
      });
      return;
    }
    let url = `/chat?session_id=${sessionId}&type=generate`;
    const params = new URLSearchParams(window.location.search);
    params.forEach((val, key) => {
      url += `&${key}=${val}`;
    });
    if (values.platform == 0) {
      cache.setGenerateParams({
        reddit_post_id: extractPostId(values.url)
      });
    } else {
      cache.setGenerateParams({
        youtube_url: values.url
      });
    }
    navigate(url);
  };

  const extractPostId = url => {
    if (!url) {
      return null;
    }
    const regex = /comments\/([a-zA-Z0-9]+)\//;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const onAfterClose = () => {
    form.resetFields();
    setIsShowProgress(false);
    setProgress(0);
    setIsLoading(false);
    setStateText('');
  };

  // const detectClipboard = async () => {
  //   try {
  //     const text = await navigator.clipboard.readText();
  //     if (text) {
  //       if (text.includes('youtube.com')) {
  //         form.setFieldValue('platform', 1);
  //         form.setFieldValue('url', text);
  //       } else if (text.includes('reddit.com')) {
  //         form.setFieldValue('platform', 0);
  //         form.setFieldValue('url', text);
  //       }
  //     }
  //   } catch (e) {
  //     console.log('e', e);
  //   }
  // };

  const onAfterOpenChange = open => {
    if (open) {
      // detectClipboard();
    }
  };

  return (
    <Modal
      {...props}
      className={styles.generateModal}
      centered
      width={450}
      onOk={onSubmit}
      okText="Generate"
      title="Paste a URL to Generate Your Travel Plan"
      maskClosable={false}
      afterClose={onAfterClose}
      afterOpenChange={onAfterOpenChange}
      destroyOnClose
      okButtonProps={{
        loading: isLoading
      }}
    >
      <AnimatePresence mode={'wait'}>
        {isShowProgress ? (
          <motion.div key="div1" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
            <div className={'h-[140px] flex flex-col justify-center'}>
              <Progress percent={progress} strokeColor={ZZThemeColor} showInfo size={['100%', 12]} />
              <div className={'f-center mt-3 font-medium capitalize'}>{stateText} ...</div>
            </div>
          </motion.div>
        ) : (
          <motion.div key="div2" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
            <div className={'h-auto pt-2'}>
              <Form
                form={form}
                initialValues={{
                  platform: 1,
                  url: ''
                }}
              >
                <Form.Item label="Platform" name="platform" style={{ marginBottom: 20 }}>
                  <Radio.Group>
                    <Radio value={1}>Youtube</Radio>
                    <Radio value={0}>Reddit</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="" name="url" style={{ marginBottom: 30 }}>
                  <Input.TextArea
                    rows={2}
                    placeholder={example_link}
                    allowClear
                    style={{
                      padding: 4,
                      fontSize: 14,
                      resize: 'none'
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  );
}

export default GenerateFromModal;
