import Page from '@/components/page/index.jsx';
import { Button, Form, Input, notification } from 'antd';
import { addUserContact } from '@/apis/user.js';

function Beta() {
  const [form] = Form.useForm();
  const onFinish = async () => {
    const values = form.getFieldsValue();
    await addUserContact(values);
    notification.success({
      message: 'Successful',
      description: "Thank you for signing up! We'll notify you as soon as our AI travel planning launches."
    });
    form.resetFields();
  };

  return (
    <Page className="items-center">
      <div className={`h-0 flex-grow w-full overflow-y-auto relative f-center`}>
        <video
          className="absolute w-full h-full left-0 top-0 object-cover"
          loop
          muted
          playsInline
          data-object-fit="cover"
          data-wf-ignore="true"
          autoPlay
          controls={false}
        >
          <source src="https://storage.googleapis.com/solaris_melange/resources/hero-home-transcode.mp4" type="video/mp4" />
          <source src="https://storage.googleapis.com/solaris_melange/resources/hero-home-transcode.webm" type="video/webm" />
        </video>
        <div className="z-10 flex flex-col px-6 pt-6 pb-4 rounded-2xl w-[90%] md:w-[500px]" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}>
          <div className={'flex flex-col text-[16px] font-medium mb-6'}>
            <p className={'mb-2'}>🌍 Embark on a Smart Journey with Our AI Travel Planner! 🚀</p>
            <p>Be the first to experience tailor-made itineraries when our platform launches. Sign up now for early access and updates!</p>
          </div>
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 3
            }}
            wrapperCol={{
              span: 19
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your name!'
                }
              ]}
            >
              <Input placeholder="Please input your name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!'
                }
              ]}
            >
              <Input placeholder="Please input your email" />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24
              }}
            >
              <div className="w-full f-center">
                <Button type="primary" htmlType="submit" style={{ width: 120, marginTop: 10 }}>
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Page>
  );
}

export default Beta;
