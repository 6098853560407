import { create } from 'zustand';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import { subscribeWithSelector } from 'zustand/middleware';
import { ZZUploadType } from '@/utils/const.js';
import useUserStore from '@/stores/useUserStore.js';

let showPlaceTimes = 0;

const useGlobalStore = create(
  subscribeWithSelector((set, get) => ({
    isShowUpload: false,
    operateHotel: null,
    uploadType: ZZUploadType.all,

    isShowPlaceInfo: false,
    placeId: null,
    isToReviews: false,
    localVideos: [],

    isShowBooking: false,
    bookingInfo: null,

    focusPlayVideoKey: null,

    toggleIsShowUpload: (flag, uploadType, operateHotel = null) => {
      set(() => ({
        isShowUpload: flag,
        uploadType,
        operateHotel
      }));
    },

    toggleIsShowPlaceInfo: ({ flag, placeId, isToReviews = false, localVideos = [] }) => {
      if (flag) {
        const setIsShowLogin = useUserStore.getState().setIsShowLogin;
        const isLogin = useUserStore.getState().isLogin;
        showPlaceTimes += 1;
        if (!isLogin && showPlaceTimes >= 3) {
          setIsShowLogin(true);
        }
      }
      set(() => ({
        isShowPlaceInfo: flag,
        placeId,
        isToReviews,
        localVideos
      }));
    },

    toggleIsShowBooking: (flag, bookingInfo) => {
      set(() => ({
        isShowBooking: flag,
        bookingInfo
      }));
    },

    setFocusPlayVideoKey: focusPlayVideoKey => {
      set(() => ({
        focusPlayVideoKey
      }));
    }
  }))
);

export default useGlobalStore;
export const useGlobalStoreSelectors = createSelectorFunctions(useGlobalStore);
