import ajax from '@/utils/ajax';

export function doLogEvent(data) {
  return ajax({
    url: 'api/event',
    data
  });
}

export function onCall(data) {
  return ajax({
    url: '/api/oncall',
    data
  });
}
