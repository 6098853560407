import ajax from '@/utils/ajax.js';

export function addUserContact(data) {
  return ajax({
    url: '/api/user_contact',
    data
  });
}

export function addUser(data) {
  return ajax({
    url: '/api/add_user',
    data
  });
}

export function getTmpToken(params) {
  return ajax({
    // url: 'api/token',
    url: 'api/get_token',
    params,
    method: 'get'
  });
}
