import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, getDocs, query, serverTimestamp, writeBatch, doc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAzFyCGMhxZi1r3Ac7pBWIM0PVZzkJOVIk',
  authDomain: 'biblechatapp-b9ac6.firebaseapp.com',
  projectId: 'biblechatapp-b9ac6',
  storageBucket: 'biblechatapp-b9ac6.appspot.com',
  messagingSenderId: '642684041361',
  appId: '1:642684041361:web:1e869454a31364b5e7e730',
  measurementId: 'G-SHR3NL4C0N'
};

const app = initializeApp(firebaseConfig);

export const firebase_db = getFirestore(app);

export async function firebase_addDoc(key, object) {
  try {
    object.createdAt = serverTimestamp();
    object.updatedAt = serverTimestamp();
    const docRef = await addDoc(collection(firebase_db, key), object);
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function firebase_getDocs(key, { orderBy }) {
  try {
    const c = collection(firebase_db, key);
    const q = query(c, orderBy);
    const querySnapshot = await getDocs(q);
    const allData = [];
    querySnapshot.forEach(doc => {
      allData.push(doc.data());
    });
    return allData;
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function firebase_copyCollection(sourceCollectionName, destinationCollectionName) {
  const sourceCollection = collection(firebase_db, sourceCollectionName);
  const destinationCollection = collection(firebase_db, destinationCollectionName);
  // console.log('destinationCollection', destinationCollection.doc);
  const querySnapshot = await getDocs(sourceCollection);

  let batch = writeBatch(firebase_db);
  let batchSize = 0;

  querySnapshot.forEach(document => {
    const docData = document.data();
    const docId = document.id;
    const docRefB = doc(destinationCollection, docId);
    batch.set(docRefB, docData);
    batchSize++;
    // 如果批次达到500个操作，则提交批次
    if (batchSize === 500) {
      batch.commit();
      batch = writeBatch(firebase_db); // 开始新的批次
      batchSize = 0;
    }
  });

  // 提交剩余的操作
  if (batchSize > 0) {
    await batch.commit();
  }

  console.log('Collection copied successfully with batch!');
}

export const store_conversation_prefix = 'conversation_v3_';
