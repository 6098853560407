import { Form, InputNumber, Modal, TimePicker } from 'antd';
import dayjs from 'dayjs';
import toast from '@/utils/toast.js';
import { updateAttraction } from '@/apis/trips_v4.js';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useState } from 'react';
import styles from './edit-duration-modal.module.scss';

const format = 'HH:mm';

function EditDurationModal({ attraction, dayIndex, attractionIndex, open, onCancel }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();

  const onConfirm = async () => {
    try {
      const values = form.getFieldsValue();
      if (!values.startTime) {
        toast.info('Please select start time');
        return;
      }
      if (!values.duration) {
        toast.info('Please input tour duration');
        return;
      }
      setIsLoading(true);
      const { sessionId, itineraryId } = useTripStoreV4.getState();
      const params = {
        session_id: sessionId,
        itinerary_id: itineraryId,
        day_index: dayIndex + 1,
        attraction_index: attractionIndex + 1,
        attraction: {
          duration_minutes: values.duration,
          start_time: {
            hour: values.startTime.hour(),
            minute: values.startTime.minute()
          }
        }
      };
      const data = await updateAttraction(params);
      toast.success(`Update success`);
      updateItinerary(data, false);
      onCancel();
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onAfterOpen = open => {
    if (open) {
      const params = {
        duration: attraction?.duration_minutes || 120
      };
      if (attraction?.start_time) {
        params.startTime = dayjs(`${attraction?.start_time.hour}:${attraction?.start_time.minute}`, format);
      }
      console.log('params', params);
      form.setFieldsValue(params);
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      className={styles.editModal}
      title={<div className={'font-medium text-center'}>Modify Tour Duration</div>}
      open={open}
      onOk={onConfirm}
      onCancel={onCancel}
      width={400}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: isLoading
      }}
    >
      <Form
        form={form}
        style={{ marginTop: 30, marginBottom: 30 }}
        name="basic"
        labelCol={{
          style: {
            width: 120
          }
        }}
      >
        <Form.Item label="Start time" name="startTime">
          <TimePicker className={'w-[180px]'} format={format} showNow={false} minuteStep={15} />
        </Form.Item>
        <Form.Item label="Tour durations" name="duration">
          <InputNumber style={{ width: 180 }} placeholder="Tour duration..." addonAfter={'minutes'} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditDurationModal;
