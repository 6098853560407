import ReactGA from 'react-ga4';

let InteractionCount = 0;

export function initGA() {
  if (import.meta.env.VITE_ENV !== 'PROD') {
    return;
  }
  console.log('initGA');
  try {
    ReactGA.initialize('G-J553205W1J', {
      gaOptions: {
        debug_mode: import.meta.env.VITE_ENV !== 'PROD'
      },
      gtagOptions: {
        debug_mode: import.meta.env.VITE_ENV !== 'PROD'
      },
      gtagUrl: 'https://www.googletagmanager.com/gtag/js?id=AW-16706051350'
    });
    ReactGA.gtag('config', 'AW-16706051350');
  } catch (e) {
    console.log('e', e);
  }
}

export function logEvent({ name, params }) {
  if (import.meta.env.VITE_ENV !== 'PROD') {
    return;
  }
  ReactGA.event(name, params);
  window.ttq?.track(name, params);
}

export function tagEvent(...args) {
  ReactGA.gtag(...args);
}

export function tagDeepInteraction() {
  InteractionCount += 1;
  if (InteractionCount == 5) {
    console.log('tagDeepInteraction submit');
    if (import.meta.env.VITE_ENV !== 'PROD') {
      return;
    }
    ReactGA.gtag('event', 'conversion', {
      send_to: 'AW-16706051350/7mkoCPyA1dMZEJa6iJ4-',
      value: 10.0,
      currency: 'USD'
    });
  }
}
