import { algoliasearch } from 'algoliasearch';

const appID = 'K8N6RWHDVA';

const apiKey = '839f6001a699b2c4c6c5823ec72382ad';

const client = algoliasearch(appID, apiKey);

// export async function search() {
//   // Search for "test"
//   const { results } = await client.search({
//     requests: [
//       {
//         indexName,
//         query: 'Osaka'
//       }
//     ]
//   });
//   console.log('results--->', results);
// }

export default client;
