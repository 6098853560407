import { ZZGoogleAuthClientId, ZZThemeColor } from '@/utils/config.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { StyleProvider } from '@ant-design/cssinjs';
import { RouterProvider } from 'react-router-dom';
import router from '@/router/index.jsx';
import { ConfigProvider, message } from 'antd';
import { useEffect } from 'react';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function App() {
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    initEvent();
    return () => {
      emitter.off(ZZEmittedEvent.toast);
      emitter.off(ZZEmittedEvent.toastDestroy);
    };
  }, []);

  const initEvent = () => {
    emitter.on(ZZEmittedEvent.toast, configs => {
      messageApi.open(configs);
    });
    emitter.on(ZZEmittedEvent.toastDestroy, key => {
      messageApi.destroy(key);
    });
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Roboto',
          borderRadius: 8
        },
        components: {
          Button: {
            colorPrimary: ZZThemeColor,
            algorithm: true // 启用算法
          },
          Slider: {
            handleColor: 'black',
            handleActiveColor: 'black'
          },
          FloatButton: {
            colorPrimary: 'black',
            algorithm: true // 启用算法
          },
          Tabs: {
            itemColor: '#00000080',
            inkBarColor: 'black',
            itemActiveColor: 'black',
            itemSelectedColor: 'black',
            itemHoverColor: 'black',
            fontSize: 16
          }
        }
      }}
    >
      <GoogleOAuthProvider clientId={ZZGoogleAuthClientId}>
        <StyleProvider hashPriority="high">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </StyleProvider>
      </GoogleOAuthProvider>
      {contextHolder}
    </ConfigProvider>
  );
}
