import { useState, useEffect } from 'react';
import { logEvent } from '@/utils/ga.js';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { getQueryParam } from '@/utils/index.js';

const ImageWrapper = ({ src, alt, fallbackSrc, placeId, ...props }) => {
  const [currentSrc, setCurrentSrc] = useState(src);
  const sessionId = useTripStoreSelectorsV4.use.sessionId();

  useEffect(() => {
    if (currentSrc?.includes('maps.googleapis.com')) {
      const photoreference = getQueryParam(currentSrc, 'photoreference');
      logEvent({
        name: `image_open_session_${sessionId}`
      });
      logEvent({
        name: `image_open_reference_${photoreference}`
      });
      if (placeId) {
        logEvent({
          name: `image_open_place_${placeId}`
        });
      }
    }
  }, []);

  const onLoad = event => {
    if (currentSrc?.includes('maps.googleapis.com')) {
      const imgElement = event.target;
      if (imgElement.naturalWidth <= 100 || imgElement.naturalHeight <= 100) {
        onError();
      } else {
        // logGoogleImage('success');
      }
    }
  };

  const onError = () => {
    // logGoogleImage('error');
    setCurrentSrc(fallbackSrc || 'https://storage.googleapis.com/solaris_melange/resources/placeholer.jpg'); // 设置备用图片
  };

  // const logGoogleImage = stage => {
  //   if (currentSrc?.includes('maps.googleapis.com')) {
  //     logEvent({
  //       name: `image_${stage}_${sessionId}`
  //     });
  //   }
  // };

  return <img src={currentSrc} alt={alt} onLoad={onLoad} onError={onError} {...props} />;
};

export default ImageWrapper;
