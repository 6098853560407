import { useState } from 'react';
import styles from './booking-modal.module.scss';
import { Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { getProduct } from '@/apis/trips_v4.js';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import IconRight from '../../../assets/svgs/icon_arrow_right.svg?react';
import { logEvent, tagEvent } from '@/utils/ga.js';

function BookingModal(props) {
  const [isLoading, setIsLoading] = useState(true);
  const sessionId = useTripStoreSelectorsV4.use.sessionId();
  const toggleIsShowBooking = useGlobalStoreSelectors.use.toggleIsShowBooking();
  const bookingInfo = useGlobalStoreSelectors.use.bookingInfo();
  const [info, setInfo] = useState({});

  const onClose = () => {
    toggleIsShowBooking(false, null);
  };

  const fetchInfo = async () => {
    try {
      setIsLoading(true);
      const data = await getProduct({
        session_id: sessionId,
        ...(bookingInfo || {})
      });
      setInfo(data);
    } catch {
      /* empty */
    } finally {
      setIsLoading(false);
    }
  };

  const onAfterOpenChange = open => {
    if (open) {
      logEvent({
        name: 'chat_see_tickets'
      });
      fetchInfo();
    } else {
      setIsLoading(true);
    }
  };

  const onBooking = item => {
    logEvent({
      name: `bookingPage_booking_click_${item.brand}`
    });
    tagEvent('event', 'conversion', {
      send_to: 'AW-16706051350/ymseCLKk8dcZEJa6iJ4-'
    });
    if (item.booking_link) {
      window.open(item.booking_link);
    } else {
      window.open(bookingInfo?.booking_url);
    }
  };

  const getHost = item => {
    const regex = /https?:\/\/(www\.)?([\w.-]+\.[a-z]{2,6})/i;
    const url = ['Direct', 'Official'].includes(item.brand) ? bookingInfo?.booking_url : item.booking_link;
    const match = url?.match(regex);
    return match ? match[2] : null;
  };

  const getLogo = item => {
    if (['Direct', 'Official'].includes(item.brand)) {
      return 'https://storage.googleapis.com/solaris_melange/resources/icon_official.png';
    }
    return `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${item.booking_link}&size=256`;
  };

  return (
    <Modal
      zIndex={2000}
      className={styles.bookingModal}
      centered
      footer={null}
      {...props}
      afterOpenChange={onAfterOpenChange}
      onCancel={onClose}
      width={750}
    >
      {/*edit title for sean*/}
      <div className={'text-center text-[20px] font-medium mb-6'}>{info?.title || 'Booking'}</div>
      {isLoading ? (
        <div className={'f-center h-32'}>
          <Spin spinning={isLoading} indicator={<LoadingOutlined className={'!text-[28px] text-black'} />} />
        </div>
      ) : (
        <div className={'flex flex-col md:px-4 max-h-[600px] overflow-y-auto'}>
          {info?.products?.map(item => (
            <div key={item.brand} className={'flex items-center justify-between py-4 border-b last:border-none'}>
              <div className={'flex items-center'}>
                <img
                  className={`w-[72px] h-[72px] rounded-[16px] mr-3 md:mr-6 border border-[#F3F4F6] ${
                    ['Direct', 'Official'].includes(item.brand) ? 'border-none' : ''
                  }`}
                  src={getLogo(item)}
                  alt=""
                />
                <div className={'flex flex-col'}>
                  <span className={'text-[20px] text-[#374151] font-medium'}>{item.brand}</span>
                  {item.starting_price && (
                    <span className={'flex items-center'}>
                      <span className={'mr-1.5 text-[14px]'}>from</span>
                      <span className={'font-medium text-[16px] text-theme'}>
                        {item.currency}
                        <span className={'ml-0.5'}>{item.starting_price}</span>
                      </span>
                    </span>
                  )}
                  <span className={'text-[14px] text-[#6B7280]'}>Book at {getHost(item)}</span>
                </div>
              </div>
              <span
                className={'f-center py-1 px-3 md:px-4 rounded-md border border-black cursor-pointer font-medium'}
                onClick={() => onBooking(item)}
              >
                Booking
                <IconRight className={'ml-1.5'} />
              </span>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
}

export default BookingModal;
