import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { isString } from 'lodash-es';

export default {
  info(msg) {
    let configs = {};
    if (isString(msg)) {
      configs.content = msg;
    } else {
      configs = msg;
    }
    emitter.emit(ZZEmittedEvent.toast, {
      type: 'info',
      ...configs
    });
  },

  success(msg) {
    let configs = {};
    if (isString(msg)) {
      configs.content = msg;
    } else {
      configs = msg;
    }
    emitter.emit(ZZEmittedEvent.toast, {
      type: 'success',
      ...configs
    });
  },

  error(msg) {
    let configs = {};
    if (isString(msg)) {
      configs.content = msg;
    } else {
      configs = msg;
    }
    emitter.emit(ZZEmittedEvent.toast, {
      type: 'error',
      ...configs
    });
  },

  loading(configs) {
    emitter.emit(ZZEmittedEvent.toast, {
      type: 'loading',
      ...configs
    });
  },

  destroy(key) {
    emitter.emit(ZZEmittedEvent.toastDestroy, key);
  }
};
