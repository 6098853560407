function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy for SnapTrip.ai</h1>
      <p className="text-gray-600 mb-8">Last Updated: 09/01/2024</p>

      <p className="mb-4">
        This Privacy Policy describes the privacy practices of SnapTrip.ai ("SnapTrip," "we," "us," or "our") and how we handle personal information
        that we collect through our website located at{' '}
        <a href="http://www.snaptrip.ai" className="text-blue-600 underline">
          http://www.snaptrip.ai
        </a>{' '}
        (the “Site”) and any other applications, websites, or services that we own or control and that link to this Privacy Policy (collectively, the
        “Service”). The Service is designed to be an AI-powered platform for travel planning and itinerary creation based on user preferences.
      </p>

      <p className="mb-8">
        We care about the protection and confidentiality of your personal information and have developed this Privacy Policy to describe the
        information we collect through the Service and to explain how it is used and to whom it may be disclosed.
      </p>

      <h2 className="text-2xl font-bold mb-4">1. Personal Information We Collect</h2>

      <h3 className="text-xl font-semibold mb-2">Information You Provide to Us:</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Contact and Account Information:</strong> When you use the Service, we may collect information such as your first and last name,
          email address, and password. If you choose to create an account or log into the Service using third-party services, such as Google, we will
          receive personal information about you (such as your name, email address) as permitted by your profile settings on the third-party service
          to authenticate you. The information we receive when you authenticate through a third-party service depends on the settings, permissions,
          and privacy policy controlled by that third-party service. You should always check the privacy settings and notices in the relevant
          third-party services to understand what data may be disclosed to us or shared with our Service.
        </li>
        <li>
          <strong>Interests and Preferences:</strong> You may provide information about your travel preferences, interests, and planned destinations
          when using the Service. This information helps us customize your experience and provide relevant travel planning suggestions.
        </li>
        <li>
          <strong>Communications:</strong> We collect the content of any messages or inquiries you send using the Service or when you contact us
          directly, including responses to surveys, feedback, or questions.
        </li>
        <li>
          <strong>Other Data:</strong> Any other data you provide directly to us through your use of the Service, which may include various
          preferences or specific requests.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Automatic Data Collection:</h3>
      <p className="mb-4">
        We and our service providers may automatically collect and combine information about you, your computer or mobile device, and your interaction
        over time with the Service, online resources, and our communications, such as:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Device Data:</strong> This includes your computer's or mobile device's operating system type and version, manufacturer and model,
          browser type, screen resolution, device type (e.g., phone, tablet), IP address, unique identifiers (including those used for advertising
          purposes), language settings, and general location information such as city, state, or geographic area.
        </li>
        <li>
          <strong>Online Activity Data:</strong> Information such as pages or screens you viewed, how long you spent on a page or screen, the website
          you visited before browsing to the Service, navigation paths between pages or screens, activity on a page or screen, access times, duration
          of access, and engagement with our communications.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Tools for Automatic Data Collection:</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Cookies:</strong> These are text files that websites store on a visitor's device to uniquely identify the visitor's browser or to
          store information or settings in the browser.
        </li>
        <li>
          <strong>Local Storage Technologies:</strong> Technologies like HTML5 that provide cookie-equivalent functionality but can store larger
          amounts of data.
        </li>
        <li>
          <strong>Web Beacons:</strong> Also known as pixel tags or clear GIFs, these are used to demonstrate that a webpage or email was accessed or
          opened, or that certain content was viewed or clicked.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">2. How We Use Personal Information</h2>
      <p className="mb-4">We use personal information for the following purposes or as otherwise described at the time of collection:</p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Provide Our Service:</strong> We use personal information to operate, maintain, and provide you with the Service, including creating
          personalized travel itineraries based on your preferences.
        </li>
        <li>
          <strong>Communicate with You About Our Service:</strong> We use personal information to respond to your requests, provide user support, and
          communicate with you about our Service, including sending announcements, surveys, reminders, updates, security alerts, and support and
          administrative messages.
        </li>
        <li>
          <strong>Improve, Monitor, and Personalize Our Service:</strong> We use your personal information to improve and personalize the Service,
          ensuring it meets user needs and interests. This includes troubleshooting, testing, research, and keeping the Service secure.
        </li>
        <li>
          <strong>Research and Development:</strong> We may use personal information for research and development purposes, including analyzing
          trends, usage, activities, and statistics, and improving the Service and our business. We may create aggregated, de-identified, or
          anonymized data from personal information for these purposes.
        </li>
        <li>
          <strong>Compliance and Protection:</strong> We may use personal information to comply with legal obligations and to protect our rights,
          privacy, safety, or property, including by making and defending legal claims, conducting audits, and preventing fraud.
        </li>
      </ul>

      {/* Additional sections can follow the same pattern */}
    </div>
  );
}

export default PrivacyPolicy;
