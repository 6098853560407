import { createBrowserRouter } from 'react-router-dom';
import Layout from '@/layouts/index.jsx';
// import Itinerary from '@/pages/guides/itinerary/index.jsx';
// import Preview from '@/pages/guides/itinerary/preview.jsx';
// import Test from '@/pages/others/test.jsx';
// import Test2 from '@/pages/others/test2.jsx';
// import Places from '@/pages/guides/places/index.jsx';
// import RoadBook from '@/pages/guides/books/index.jsx';
// import PlaceInfo from '@/pages/guides/places/info.jsx';
// import Admin from '@/pages/admin/index.jsx';
import Beta from '@/pages/signup/beta.jsx';
import Trips from '@/pages/home-v4/trips.jsx';
// import Home from '@/pages/home/index.jsx';
import Home from '@/pages/home-v4/index.jsx';
import Agoda from '@/pages/home-v4/agoda';
// import Itinerary from '@/pages/home/itinerary.jsx';
import Chat from '@/pages/home-v4/chat.jsx';
import Share from '@/pages/home-v4/share.jsx';
import Privacy from '@/pages/home-v4/privacy.jsx';
import Terms from '@/pages/home-v4/terms.jsx';
import Test from '@/pages/others/test.jsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        // element: <Guide />
        element: <Home />
      },
      // {
      //   path: 'itinerary',
      //   element: <Itinerary />
      // },
      // {
      //   path: 'preview',
      //   element: <Preview />
      // },
      // {
      //   path: 'places',
      //   element: <Places />
      // },
      // {
      //   path: 'placeInfo',
      //   element: <PlaceInfo />
      // },
      // {
      //   path: 'roadBook',
      //   element: <RoadBook />
      // },
      // {
      //   path: 'test',
      //   element: <Test />
      // },
      // {
      //   path: 'test2',
      //   element: <Test2 />
      // },
      // {
      //   path: 'admin',
      //   element: <Admin />
      // },
      {
        path: 'beta',
        element: <Beta />
      },
      {
        path: 'trips',
        element: <Trips />
      },
      {
        path: '/chat',
        element: <Chat />
      },
      {
        path: '/share',
        element: <Chat />
        // element: <Share />
      },
      {
        path: '/privacy',
        element: <Privacy />
      },
      {
        path: '/privacy_app',
        element: <Privacy />
      },
      {
        path: '/terms',
        element: <Terms />
      },
      {
        path: '/test',
        element: <Test />
      },
      {
        path: '/AgodaPartnerVerification',
        element: <Agoda />
      }
    ]
  }
]);

export default router;
