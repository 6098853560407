import { useCallback, useState } from 'react';

export default function usePopover() {
  const [open, setOpen] = useState(false);

  const setOpen2 = useCallback(value => {
    setOpen(value);
  }, []);

  return [open, setOpen2];
}
