import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { ZZUploadType } from '@/utils/const.js';
import styles from './hotels-panel.module.scss';
import dayjs from 'dayjs';
import { Button, Modal } from 'antd';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { deleteHotel } from '@/apis/trips_v4.js';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import IconNoHotel from '../../../assets/svgs/icon_no_hotel.svg?react';
import IconHotel from '../../../assets/svgs/icon_hotel_line.svg?react';
import IconNoStay from '../../../assets/svgs/icon_no_stay.svg?react';
import IconEdit from '../../../assets/svgs/icon_edit.svg?react';
import IconDelete from '../../../assets/svgs/icon_delete_v2.svg?react';
import { get, isEmpty } from 'lodash-es';
import { getImageUrl } from '@/utils/index.js';
import { useResponsive } from 'ahooks';
import ImageWrapper from '@/components/commons/ImageWrapper/index.jsx';

function HotelsPanel({ className }) {
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const toggleIsShowUpload = useGlobalStoreSelectors.use.toggleIsShowUpload();
  const [modal, contextHolder] = Modal.useModal();
  const fetchItineraryAndIntent = useTripStoreSelectorsV4.use.fetchItineraryAndIntent();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const { md } = useResponsive();

  const getDates = item => {
    const formatDate = date => `${date.year}-${date.month}-${date.day}`;
    const checkin_day = dayjs(formatDate(item.check_in_time.date)).format('MMM D, YYYY');
    const checkout_day = dayjs(formatDate(item.check_out_time.date)).format('MMM D, YYYY');
    return checkin_day === checkout_day ? checkin_day : `${checkin_day} - ${checkout_day}`;
  };

  const onUpload = () => {
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    toggleIsShowUpload(true, ZZUploadType.hotel, null);
  };

  const onConfirmDelete = flightId => {
    modal.confirm({
      centered: true,
      title: 'Delete Confirm',
      content: 'Are you sure to delete this hotel?',
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      onOk: async close => {
        await onDelete(flightId);
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onDelete = async hotelId => {
    const { sessionId, itineraryId } = useTripStoreV4.getState();
    await deleteHotel({
      session_id: sessionId,
      itinerary_id: itineraryId,
      hotel_id: hotelId
    });
    toast.success('Hotel deleted successfully');
    fetchItineraryAndIntent();
  };

  const onEdit = hotel => {
    toggleIsShowUpload(true, ZZUploadType.hotel, hotel);
  };

  return (
    <div className={`py-2 px-3 ${className}`}>
      {isEmpty(itinerary?.hotels) ? (
        <div className="f-center flex-col">
          <IconNoHotel className={'mt-9'} />
          <div className={'text-[15px] md:text-[20px] font-medium mt-9'}>You don’t have any hotels yet for this trip</div>
          <div className={'text-[15px] md:text-[20px] mb-6'}>Please add or book first</div>
          <Button onClick={onUpload} className={'mb-4 !font-medium'} icon={<IconHotel />}>
            Add Hotel
          </Button>
        </div>
      ) : (
        <div className={'flex flex-col'}>
          {itinerary?.hotels?.map(item => {
            return (
              <div key={item.id} className={`${styles.item} relative flex items-start md:items-center`}>
                <div className="flex items-start md:items-center w-0 flex-grow">
                  <div className={'w-20 h-20 flex-shrink-0 mr-5'}>
                    {get(item, 'photos.0.url') ? (
                      <ImageWrapper
                        src={getImageUrl(get(item, 'photos.0.url', ''), 300)}
                        alt=""
                        className={'h-full w-full object-cover shrink-0 rounded-xl'}
                      />
                    ) : (
                      <div className={'f-center w-full h-full border rounded-xl'}>
                        <IconNoStay />
                      </div>
                    )}
                  </div>
                  <div className={`flex flex-col`}>
                    <div className={'text-[14px]'}>{getDates(item)}</div>
                    <div className={'flex items-center font-medium text-[20px] text-[#374151]'}>{item.name}</div>
                    <div className={'text-[16px] text-[#6B7280]'}>{item.address}</div>
                  </div>
                </div>
                <span className={`flex items-center justify-end ${styles.btns} md:hidden`}>
                  <IconEdit onClick={() => onEdit(item)} className={'mr-3 md:mr-5 cursor-pointer'} />
                  <IconDelete onClick={() => onConfirmDelete(item)} className={'cursor-pointer'} />
                  {/*<Button type="text" className={`${styles.delete} mr-2`} onClick={() => onEdit(item)} icon={<EditOutlined />} />*/}
                  {/*<Button type="text" className={`${styles.delete}`} onClick={() => onConfirmDelete(item.id)} icon={<DeleteOutlined />} />*/}
                </span>
              </div>
            );
          })}
        </div>
      )}
      {contextHolder}
    </div>
  );
}

export default HotelsPanel;
