import dayjs from 'dayjs';

export const ZZGoogleMapKey = 'AIzaSyDKRwIdzo4JryE1eV2FAtEL2XYp4lsQkrc';

export const ZZGoogleAuthClientId = '283418202370-8ecvsnanhe6ef0l3hffl97b5u65ptugc.apps.googleusercontent.com';
export const ZZBaseUrl = import.meta.env.MODE === 'dev' ? window.location.origin : import.meta.env.VITE_API_HOST;

export const ZZIsMock = false;

export const ZZAdditionalInitialValues = {
  residence: '',
  departureTime: null
};

export const ZZIntentInitialValues = {
  origin: undefined,
  origin_place: null,
  destination: [],
  destination_places: [],
  start_date: dayjs()
    .add(1, 'week')
    .format('YYYY-MM-DD'),
  duration_in_days: 2,
  companions: {
    companion_type: 'Couple',
    number_of_adults: 2,
    number_of_kids: 0
  }
  // travel_interest: ["Lover of the classics", "Immerse myself in local culture"]
};

export const ZZDurationOptions = [
  {
    label: 'Weekend',
    value: 2
  },
  {
    label: '1 week',
    value: 7
  },
  {
    label: '2 weeks',
    value: 14
  },
  {
    label: '1 month',
    value: 30
  }
];

export const ZZBgColors = [
  '#DE3151',
  '#D9BA3E',
  '#1E88E5', // 天蓝
  '#43A047', // 深绿
  '#FB8C00', // 明橙
  '#8E44AD', // 紫罗兰
  '#F06292', // 粉色
  '#4A148C', // 紫黑
  '#7B1FA2', // 深紫
  '#512DA8', // 靛蓝
  '#303F9F', // 深蓝
  '#0288D1', // 湖蓝
  '#C2185B', // 蔷薇红
  '#AD1457', // 深红
  '#AB47BC', // 亮紫
  '#FFA726', // 亮橙
  '#00ACC1', // 浅青
  '#33a69d', // 深棕
  '#D81B60', // 亮粉红
  '#6A1B9A', // 紫色
  '#C62828', // 强红
  '#2E7D32', // 暗绿
  '#D32F2F', // 番茄红
  '#6D4C41', // 咖啡棕
  '#1976D2', // 宝蓝
  '#E64A19', // 锈橙
  '#388E3C', // 鲜绿
  '#FBC02D', // 柠檬黄
  '#455A64', // 深蓝灰
  '#0097A7', // 青色
  '#F57C00', // 橙黄
  '#00796B', // 钴绿
  '#5D4037', // 深褐
  '#E53935', // 鲜红
  '#8E24AA', // 深洋红
  '#1565C0', // 亮海蓝
  '#558B2F', // 橄榄绿
  '#AFB42B', // 柠檬绿
  '#00838F', // 靛青
  '#D84315', // 暗橙
  '#C0CA33' // 酸橙
];

export const ZZThemeColor = '#DE3151';

export const ZZChatScenes = {
  attraction: 0, //聊景点
  picked: 1, //聊选中的地点
  itinerary: 2 //聊行程
};
