import AttractionItem from '@/components/home-v4/itinerary-elements/attraction-item.jsx';
import { get, isEmpty } from 'lodash-es';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import DirectionBox from '@/components/home-v4/itinerary-elements/direction-box.jsx';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';

function MustDosPanel({ day, dayIndex, onEditDuration }) {
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();

  return (
    <Droppable droppableId={day.id}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {day.places?.map((attraction, index) => {
            return (
              <Draggable index={index} draggableId={attraction.key} key={attraction.key} isDragDisabled={isReadOnly || isGenerating}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={``}>
                    <AttractionItem
                      key={get(attraction, 'place_id')}
                      place={attraction}
                      dayIndex={dayIndex}
                      attractionIndex={index}
                      isLast={index == day.places?.length - 1}
                      onEditDuration={() => onEditDuration({ dayIndex: dayIndex, attractionIndex: index, attraction })}
                      isDragging={snapshot.isDragging}
                    />
                    <DirectionBox
                      isShowContent={!snapshot.isDragging && attraction?.next_route && index != day.places?.length - 1}
                      route={attraction?.next_route}
                      dayIndex={dayIndex}
                      attractionIndex={index}
                      type="next_route"
                    />
                  </div>
                )}
              </Draggable>
            );
          })}
          {isEmpty(day.places) && <div className={'f-center p-3 text-[#888]'}>No attraction</div>}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default MustDosPanel;
