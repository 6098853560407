import DayItem from './day-item.jsx';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useEffect, useRef, useState } from 'react';
import ArrangeDaysModal from '@/components/home-v4/modals/arrange-days-modal.jsx';
import { DragDropContext } from 'react-beautiful-dnd';
import { cloneDeep, trim } from 'lodash-es';
import { Input, Modal, Popover, Spin } from 'antd';
import AddPlaceModal from '@/components/home-v4/modals/add-place-modal.jsx';
import EditDurationModal from '@/components/home-v4/modals/edit-duration-modal.jsx';
import AddCityModal from '@/components/home-v4/modals/add-city-modal.jsx';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { swapAttraction, updateDay } from '@/apis/trips_v4.js';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './itinerary-panel.module.scss';
import IconArrange from '../../../assets/svgs/icon_arrange.svg?react';
import IconAdd from '../../../assets/svgs/icon_add.svg?react';
import IconArrowBottom from '../../../assets/svgs/icon_arrow_bottom.svg?react';
import { useResponsive } from 'ahooks';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import MoveToModal from '@/components/home-v4/modals/move-to-modal.jsx';
import { useUserStoreSelectors } from '@/stores/useUserStore.js';

function ItineraryPanel({ className }) {
  const [modal, contextHolder] = Modal.useModal();
  const isLogin = useUserStoreSelectors.use.isLogin();
  const setIsShowLogin = useUserStoreSelectors.use.setIsShowLogin();
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();
  const updateAttractionDuration = useTripStoreSelectorsV4.use.updateAttractionDuration();
  const updateDayTitle = useTripStoreSelectorsV4.use.updateDayTitle();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const insertDay = useTripStoreSelectorsV4.use.insertDay();
  const [isShowRearrange, setIsShowRearrange] = useState(false);
  const [isShowAddPlace, setIsShowAddPlace] = useState(false);
  const [isShowEditDuration, setIsShowEditDuration] = useState(false);
  const [operateDay, setOperateDay] = useState(null);
  const [operateDayIndex, setOperateDayIndex] = useState(0);
  const [operateAttractionIndex, setOperateAttractionIndex] = useState(0);
  const [operateAttraction, setOperateAttraction] = useState(null);
  const [isShowDestination, setIsShowDestination] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState('All');
  const [attractionMoveToParams, setAttractionMoveToParams] = useState({});
  const [isShowMoveTo, setIsShowMoveTo] = useState(false);
  const { md } = useResponsive();
  const state = useRef({
    dayTitle: ''
  });

  useEffect(() => {
    initEvent();
    return () => {
      emitter.off(ZZEmittedEvent.editStayCity);
      // emitter.off(ZZEmittedEvent.scrollToDay);
      emitter.off(ZZEmittedEvent.attractionMoveTo);
    };
  }, []);

  const initEvent = () => {
    emitter.on(ZZEmittedEvent.editStayCity, params => {
      const hotel = params.hotel;
      if (hotel.place_type === 'place_to_stay') {
        setOperateDay(params.day);
        setOperateDayIndex(params.dayIndex);
        setIsShowDestination(true);
      }
    });

    // emitter.on(ZZEmittedEvent.scrollToDay, dayIndex => {
    //   const t = setTimeout(() => {
    //     clearTimeout(t);
    //     const id = `itinerary-day-${dayIndex}`;
    //     console.log('element id-->', id);
    //     const element = document.getElementById(id);
    //     console.log('element-->', element);
    //     if (element) {
    //       element.scrollIntoView({ behavior: 'smooth' });
    //     }
    //   }, 600);
    // });

    emitter.on(ZZEmittedEvent.attractionMoveTo, params => {
      setAttractionMoveToParams(params);
      setIsShowMoveTo(true);
    });
  };

  const onRearrangeDay = () => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    setIsShowRearrange(true);
  };

  const onDragEnd = async result => {
    try {
      const { source, destination } = result;
      if (!destination || (destination.index === source.index && destination.droppableId === source.droppableId)) {
        return;
      }
      const itinerary = cloneDeep(useTripStoreV4.getState().itinerary);
      let days = itinerary.daily_itineraries;
      let sourceDay = days.find(o => o.id === source.droppableId);
      let sourceDayIndex = days.indexOf(sourceDay);
      const [remove] = sourceDay.places.splice(source.index, 1);
      let targetDay = days.find(o => o.id === destination.droppableId);
      let targetDayIndex = days.indexOf(targetDay);
      targetDay.places.splice(destination.index, 0, remove);
      const { sessionId, itineraryId } = useTripStoreV4.getState();
      updateItinerary(itinerary, false);
      setIsLoading(true);
      const data = await swapAttraction({
        session_id: sessionId,
        itinerary_id: itineraryId,
        day_index_from: sourceDayIndex + 1,
        day_index_to: targetDayIndex + 1,
        attraction_index_from: source.index + 1,
        attraction_index_to: destination.index + 1
      });
      updateItinerary(data, false);
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onAddPlaceModal = day => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    setOperateDay(day);
    setOperateDayIndex(itinerary.daily_itineraries.indexOf(day));
    setIsShowAddPlace(true);
  };

  const onAddPlaceSuccess = () => {
    setIsShowAddPlace(false);
  };

  const onEditDuration = options => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    console.log('options-->', options);
    setOperateDayIndex(options.dayIndex);
    setOperateAttractionIndex(options.attractionIndex);
    setOperateAttraction(options.attraction);
    setIsShowEditDuration(true);
  };

  const onFinishDuration = duration => {
    operateAttraction.duration_minutes = duration;
    updateAttractionDuration(operateDay, operateAttraction);
  };

  const onEditDayTitle = async day => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    state.current.dayTitle = day.introduction;
    await modal.confirm({
      centered: true,
      title: 'Edit Title',
      width: md ? 750 : '90vw',
      className: 'confirmModal full-footer',
      icon: null,
      closable: true,
      content: (
        <>
          <Input
            onChange={({ target }) => (state.current.dayTitle = target.value)}
            defaultValue={day.introduction}
            placeholder="Enter the title"
            size={'large'}
            style={{
              height: 52,
              marginTop: 8,
              fontSize: 15
            }}
          />
        </>
      ),
      onOk: close => {
        if (!trim(state.current.dayTitle)) {
          return;
        }
        updateDayTitle(day, trim(state.current.dayTitle));
        toast.success('Edit day title success.');
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onModifyStayCity = async options => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    console.log('options-->', options);
    const { sessionId, itineraryId } = useTripStoreV4.getState();
    const data = await updateDay({
      session_id: sessionId,
      itinerary_id: itineraryId,
      day_index: operateDayIndex + 1,
      daily_content: {
        place_to_stay: {
          name: options.name
        }
      }
    });
    toast.success('Modify stay city success.');
    updateItinerary(data, false);
  };

  const onAddDay = () => {
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    modal.confirm({
      centered: true,
      title: 'Add Confirm',
      content: 'Do you want to add a new day?',
      width: md ? 400 : '90vw',
      className: 'confirmModal',
      icon: null,
      closable: true,
      okText: 'Confirm',
      onOk: async close => {
        await insertDay(itinerary.daily_itineraries.length, 'after');
        close();
      },
      onCancel: () => {
        console.log('cancel');
      }
    });
  };

  const onChangeDay = dayIndex => {
    setSelectedDay(dayIndex);
    let id = '';
    if (isLogin) {
      if (dayIndex == 'All') {
        id = `itinerary-day-0`;
      } else {
        id = `itinerary-day-${dayIndex}`;
      }
    } else {
      if (dayIndex == 'All' || dayIndex == 0) {
        id = `itinerary-day-0`;
      } else {
        id = 'lockedSection';
      }
    }
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getDailyItineraries = () => {
    const list = itinerary?.daily_itineraries || [];
    if (isLogin || import.meta.env.MODE === 'dev') {
      return list;
    } else {
      return list.slice(0, 1);
    }
  };

  const onForEntireItinerary = () => {
    setIsShowLogin(true);
  };

  return (
    <div className={`pb-2 relative ${className} mt-1 md:-mt-2`}>
      <Spin wrapperClassName={styles.spinWrapper2} indicator={<LoadingOutlined style={{ fontSize: 24 }} />} spinning={isLoading}>
        <div>
          <DayTabs onRearrangeDay={onRearrangeDay} onAddDay={onAddDay} selectedDay={selectedDay} onChangeDay={onChangeDay} />
          <DragDropContext onDragEnd={onDragEnd}>
            {getDailyItineraries().map((day, index) => {
              return (
                <DayItem
                  key={day.id}
                  index={index}
                  day={day}
                  onAddPlace={onAddPlaceModal}
                  onEditDuration={onEditDuration}
                  onEditDayTitle={onEditDayTitle}
                />
              );
            })}
          </DragDropContext>
          {!isLogin && (
            <div className={'relative'} id="lockedSection">
              <img src="https://storage.googleapis.com/solaris_melange/resources/blur_itinerary.webp" alt="" className={'w-full'} />
              <span onClick={onForEntireItinerary} className={`${styles.entireBtn} w-60`}>
                Sign in for entire itinerary
              </span>
            </div>
          )}
        </div>
      </Spin>
      <ArrangeDaysModal
        open={isShowRearrange}
        onCancel={() => {
          setIsShowRearrange(false);
        }}
      />
      <AddPlaceModal open={isShowAddPlace} onCancel={() => setIsShowAddPlace(false)} onOk={onAddPlaceSuccess} dayIndex={operateDayIndex} />
      <EditDurationModal
        open={isShowEditDuration}
        dayIndex={operateDayIndex}
        attractionIndex={operateAttractionIndex}
        attraction={operateAttraction}
        onOk={onFinishDuration}
        onCancel={() => setIsShowEditDuration(false)}
      />
      <AddCityModal isPromiseSubmit={true} open={isShowDestination} onCancel={() => setIsShowDestination(false)} onSelectedPlace={onModifyStayCity} />
      <MoveToModal open={isShowMoveTo} onCancel={() => setIsShowMoveTo(false)} attractionMoveToParams={attractionMoveToParams} />
      {contextHolder}
    </div>
  );
}

function DayTabs({ selectedDay, onChangeDay, onRearrangeDay, onAddDay }) {
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const [isShowMore, setIsShowMore] = useState(false);
  const boxRef = useRef(null);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setIsShowMore(boxRef.current.scrollWidth > boxRef.current.clientWidth);
  };

  return (
    <div className={`flex items-start ${styles.dayTabs} px-2 md:px-3 py-3 md:mr-6`}>
      <div className="flex items-center overflow-x-auto mr-2.5" ref={boxRef}>
        <span
          className={`f-center ${styles.tab} ${selectedDay == 'All' ? styles.active : ''}`}
          onClick={() => {
            onChangeDay('All');
          }}
        >
          All
        </span>
        {itinerary?.daily_itineraries.map(day => {
          return (
            <span
              key={day.dayIndex}
              className={`f-center ${styles.tab} ${selectedDay == day.dayIndex ? styles.active : ''}`}
              onClick={() => {
                onChangeDay(day.dayIndex);
              }}
            >
              Day {day.dayIndex + 1}
            </span>
          );
        })}
      </div>
      {isShowMore && (
        <Popover
          overlayInnerStyle={{ padding: '8px 0' }}
          content={
            <div className={'flex flex-col max-h-64 overflow-y-auto'}>
              <span
                className={`f-center ${styles.tab2} ${selectedDay == 'All' ? styles.active : ''}`}
                onClick={() => {
                  onChangeDay('All');
                }}
              >
                All Days
                <CheckOutlined className={styles.checked} />
              </span>
              {itinerary?.daily_itineraries.map(day => {
                return (
                  <span
                    key={day.dayIndex}
                    className={`f-center ${styles.tab2} ${selectedDay == day.dayIndex ? styles.active : ''}`}
                    onClick={() => {
                      onChangeDay(day.dayIndex);
                    }}
                  >
                    Day {day.dayIndex + 1}
                    <CheckOutlined className={styles.checked} />
                  </span>
                );
              })}
            </div>
          }
          placement={'bottom'}
        >
          <span className={`f-center ${styles.tab} ${styles.circle}`}>
            <IconArrowBottom />
          </span>
        </Popover>
      )}
      <span className={`f-center ${styles.tab} ${styles.circle} ${isReadOnly ? '!hidden' : ''}`} onClick={onAddDay}>
        <IconAdd />
      </span>
      <span className={`f-center ${styles.tab} ${styles.circle} ${isReadOnly ? '!hidden' : ''}`} onClick={onRearrangeDay}>
        <IconArrange />
      </span>
    </div>
  );
}

export default ItineraryPanel;
