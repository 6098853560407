import styles from './preview-modal.module.scss';
import { Modal } from 'antd';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import { useUserStoreSelectors } from '@/stores/useUserStore.js';
import { formatItineraryDate, getImageUrl } from '@/utils/index.js';
import * as htmlToImage from 'html-to-image';
import toast from '@/utils/toast.js';
import { useResponsive } from 'ahooks';
import { isArray } from 'lodash-es';
import { logOnCall } from '@/utils/log.js';

function PreviewModal(props) {
  const itinerary = useTripStoreSelectorsV4.use.itinerary();
  const pickedIntent = useTripStoreSelectorsV4.use.pickedIntent();
  const isLogin = useUserStoreSelectors.use.isLogin();
  const user = useUserStoreSelectors.use.user();
  const { md } = useResponsive();

  const onDownload = async () => {
    const node = document.getElementById('share-itinerary-image');

    console.log('node', node);
    htmlToImage
      .toPng(node, { skipFonts: true, preferredFontFormat: 'Roboto' })
      .then(function(dataUrl) {
        const link = document.createElement('a');
        link.download = `${itinerary.daily_itineraries.length} Days-${itinerary.daily_itineraries.length - 1} Nights Itinerary.png`;
        link.href = dataUrl;
        link.click();
        toast.success('Download success.');
        props.onCancel && props.onCancel();
      })
      .catch(function(error) {
        console.error('oops, something went wrong!', error);
      });
  };

  // const getCoverImage = () => {
  //   const url = get(itinerary, 'daily_itineraries.0.places.0.photos.0.url');
  //   if (url) {
  //     if (!url.includes('https://') && !url.includes('http://')) {
  //       return getImageUrl(url, 900);
  //     } else {
  //       // return `https://melange-test.solaris.chat/proxy_image/?external_image=${encodeURIComponent(url)}`;
  //       return `https://melange-test.solaris.chat/tkb/redirect_image?url=${encodeURIComponent(url)}`;
  //     }
  //   } else {
  //     return null;
  //   }
  // };
  //
  // const getDayImage = index => {
  //   const day = itinerary.daily_itineraries[index];
  //   let url = '';
  //   if (index == 0) {
  //     url = get(day, 'places.0.photos.1.url');
  //     if (!url) {
  //       url = get(day, 'places.0.photos.0.url');
  //     }
  //   } else {
  //     url = get(day, 'places.0.photos.0.url');
  //   }
  //   if (url) {
  //     if (!url.includes('https://') && !url.includes('http://')) {
  //       return getImageUrl(url, 900);
  //     } else {
  //       // return `https://melange-test.solaris.chat/proxy_image/?external_image=${encodeURIComponent(url)}`;
  //       return `https://melange-test.solaris.chat/tkb/redirect_image?url=${encodeURIComponent(url)}`;
  //     }
  //   } else {
  //     return null;
  //   }
  // };

  const getCoverImage = () => {
    if (!isArray(itinerary?.daily_itineraries)) {
      logOnCall({
        message: `itinerary daily_itineraries is not an array`
      });
      return;
    }
    try {
      for (const day of itinerary?.daily_itineraries || []) {
        for (const place of day?.places || []) {
          const photos = place?.photos;
          for (const photo of photos) {
            if ((!photo.url.includes('https://') && !photo.url.includes('http://')) || photo.url.includes('storage.googleapis.com/solaris_melange')) {
              return photo.url;
            }
          }
        }
      }
      return null;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  };

  const getDayImage = index => {
    try {
      const day = itinerary.daily_itineraries[index];
      if (!isArray(day?.places)) {
        logOnCall({
          message: `day places is not an array`
        });
      }
      for (const place of day.places) {
        if (!isArray(place?.photos)) {
          logOnCall({
            message: `places photos is not an array`
          });
        }
        const photos = place?.photos || [];
        for (const photo of photos) {
          if ((!photo.url.includes('https://') && !photo.url.includes('http://')) || photo.url.includes('storage.googleapis.com/solaris_melange')) {
            return photo.url;
          }
        }
      }
      return null;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  };

  return (
    <Modal {...props} className={styles.previewModal} closeIcon={false} centered width={md ? 800 : '100%'} footer={null}>
      {!!itinerary && (
        <>
          <div className={`${styles.container}`}>
            <div className={`${styles.content}`} id="share-itinerary-image">
              <div className={'w-full h-[400px] relative'}>
                {getCoverImage() && <img src={getImageUrl(getCoverImage())} alt="" className={'absolute left-0 top-0 w-full h-full object-cover'} />}
                <div className={`absolute inset-0 ${styles.mask} flex flex-col items-stretch justify-center px-3`}>
                  <div className={'text-center font-medium text-[32px]'}>{itinerary.title}</div>
                  <div className={'text-[20px] f-center'}>
                    {itinerary?.daily_itineraries?.length} Days-{itinerary.daily_itineraries?.length - 1} Nights Itinerary
                    <span className={'w-1 h-1 inline-block rounded-full bg-white mx-2'} />
                    {pickedIntent?.destination}
                  </div>
                  {isLogin && (
                    <div className={'f-center'}>
                      <img src={user?.extra?.avatar} alt="" className={'w-8 h-8 rounded-full'} />
                      <div className={'f-center text-[14px] whitespace-nowrap font-medium ml-2'}>{user?.extra?.name}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.list} pt-14`}>
                {itinerary?.daily_itineraries?.map((day, index) => {
                  return (
                    <div key={day.id} className={`${styles.day}`}>
                      <div className={styles.info}>
                        <span className={'text-[#3a3a3a] font-medium text-[24px]'}>Day {index + 1}</span>
                        {!!day.date && <span className={'text-[#3a3a3a] text-[16px] mt-3'}>{formatItineraryDate(day.date)}</span>}
                        <ul>
                          {day.places.map(place => {
                            return (
                              <li key={place.place_id} className={'my-2'}>
                                {place.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {getDayImage(index) ? (
                        <img src={getImageUrl(getDayImage(index), 500)} alt="" className={`${styles.cover}`} />
                      ) : (
                        <span className={'flex-1'} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={'bg-white p-8 f-center'}>
            <span className={`${styles.download} py-2 px-10`} onClick={onDownload}>
              Download Image
            </span>
          </div>
        </>
      )}
    </Modal>
  );
}

export default PreviewModal;
