import { Button, Modal } from 'antd';
import styles from './delete-modal.module.scss';
import { useResponsive } from 'ahooks';

function DeleteModal(props) {
  const { md } = useResponsive();

  const onSubmit = e => {
    e.stopPropagation();
    props.onSubmit && props.onSubmit();
  };

  const onCancel = e => {
    e.stopPropagation();
    props.onCancel && props.onCancel();
  };

  return (
    <Modal
      className={styles.deleteModal}
      {...props}
      footer={
        <div className={'flex items-center w-[330px] justify-around'}>
          <Button onClick={onCancel} className={'w-[42%]'} size="large">
            Cancel
          </Button>
          <Button type={'primary'} onClick={onSubmit} className={'w-[42%]'} size="large">
            Delete
          </Button>
        </div>
      }
      centered
      width={md ? 400 : '100%'}
      onCancel={onCancel}
      zIndex={2000}
      destroyOnClose
    >
      <div className={styles.title}>Are you sure?</div>
      <div className={styles.desc}>You will delete the event from your itinerary.</div>
    </Modal>
  );
}

export default DeleteModal;
