import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Drawer } from 'antd';
import Masonry from 'react-masonry-css';
import styles from './images-drawer.module.scss';
import ImageWrapper from '@/components/commons/ImageWrapper/index.jsx';

function ImagesDrawer({ images, ...props }) {
  return (
    <Drawer {...props} destroyOnClose placement={'bottom'} height={'100%'}>
      {props.open && (
        <PhotoProvider>
          <Masonry breakpointCols={3} className={styles['my-masonry-grid']} columnClassName={styles['my-masonry-grid_column']}>
            {images?.map((item, index) => (
              <PhotoView key={index} src={item}>
                <ImageWrapper src={item} alt="" className={'object-cover mb-3 bg-gray-100 cursor-pointer'} />
              </PhotoView>
            ))}
          </Masonry>
        </PhotoProvider>
      )}
    </Drawer>
  );
}

export default ImagesDrawer;
