const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
      <p className="text-gray-600 mb-8">Date of Last Revision: 09/01/2024</p>

      <p className="mb-8">
        Welcome to SnapTrip.ai! These Terms of Service ("Terms") govern your access to and use of the SnapTrip.ai website and services (collectively,
        the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you
        may not use the Service.
      </p>

      <h2 className="text-2xl font-bold mb-4">1. Acceptance of Terms</h2>
      <p className="mb-8">
        By accessing or using the Service, you confirm that you have read, understood, and agree to be bound by these Terms and any additional terms
        and conditions referenced herein or that otherwise may apply to specific sections of the Service. If you do not accept these Terms, you must
        not access or use the Service.
      </p>

      <h2 className="text-2xl font-bold mb-4">2. Changes to Terms</h2>
      <p className="mb-8">
        We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page and updating
        the "Date of Last Revision" above. You are encouraged to review these Terms periodically for any changes. Your continued use of the Service
        after any such changes are posted constitutes your acceptance of the new Terms.
      </p>

      <h2 className="text-2xl font-bold mb-4">3. Description of Service</h2>
      <p className="mb-8">
        SnapTrip.ai is an AI-powered platform designed to help users create personalized travel itineraries based on their preferences. The Service
        allows users to input their travel preferences and receive customized itinerary suggestions. These itineraries may include recommended
        destinations, activities, and points of interest.
      </p>

      <h2 className="text-2xl font-bold mb-4">4. Registration and Account Security</h2>
      <ul className="list-disc list-inside mb-8">
        <li className="mb-2">
          Optional Registration: Users may access certain features of the Service without registering for an account. However, to access some features
          or save itineraries, registration may be required.
        </li>
        <li className="mb-2">
          User Information: If you choose to register, you agree to provide accurate, current, and complete information about yourself. You are
          responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </li>
        <li className="mb-2">
          Account Termination: SnapTrip.ai reserves the right to suspend or terminate your account if any information provided during the registration
          process or thereafter proves to be inaccurate, not current, or incomplete.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">5. User Conduct and Content</h2>
      <ul className="list-disc list-inside mb-8">
        <li className="mb-2">
          Prohibited Conduct: You agree not to use the Service to upload, post, transmit, or otherwise make available any content that is unlawful,
          harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable. You also agree not to use the Service for
          any illegal or unauthorized purpose.
        </li>
        <li className="mb-2">
          Content Responsibility: You are solely responsible for all content that you upload, post, email, transmit, or otherwise make available via
          the Service. SnapTrip.ai does not control the content posted by users and, as such, does not guarantee the accuracy, integrity, or quality
          of such content.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">6. Intellectual Property Rights</h2>
      <ul className="list-disc list-inside mb-8">
        <li className="mb-2">
          Service Content: All content and materials on the Service, including but not limited to text, graphics, logos, icons, images, and software,
          are the property of SnapTrip.ai or its content suppliers and are protected by copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </li>
        <li className="mb-2">
          User Content License: By submitting or posting any content through the Service, you grant SnapTrip.ai a worldwide, non-exclusive,
          royalty-free, fully paid-up, transferable, sublicensable license to use, reproduce, distribute, prepare derivative works of, display, and
          perform the content in connection with the Service and SnapTrip.ai’s business.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">7. Third-Party Services and Links</h2>
      <p className="mb-8">
        The Service may contain links to third-party websites or services that are not owned or controlled by SnapTrip.ai. SnapTrip.ai has no control
        over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge
        and agree that SnapTrip.ai shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused
        by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.
      </p>

      <h2 className="text-2xl font-bold mb-4">8. Privacy</h2>
      <p className="mb-8">
        Your privacy is important to us. Please review our Privacy Policy, which also governs your use of the Service, to understand our practices
        regarding the collection, use, and disclosure of personal data.
      </p>

      <h2 className="text-2xl font-bold mb-4">9. Modifications to the Service</h2>
      <p className="mb-8">
        SnapTrip.ai reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.
        You agree that SnapTrip.ai will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service.
      </p>

      <h2 className="text-2xl font-bold mb-4">10. Indemnification</h2>
      <p className="mb-8">
        To the extent permitted under applicable law, you agree to defend, indemnify, and hold harmless SnapTrip.ai, its affiliates, and their
        respective officers, directors, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs,
        or debt, and expenses (including but not limited to attorney's fees) arising from your use of the Service, your violation of these Terms, or
        your violation of any rights of another.
      </p>

      <h2 className="text-2xl font-bold mb-4">11. Limitation of Liability</h2>
      <p className="mb-8">
        To the fullest extent permitted by applicable law, SnapTrip.ai shall not be liable for any indirect, incidental, special, consequential, or
        punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other
        intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or
        any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service.
      </p>

      <h2 className="text-2xl font-bold mb-4">12. Governing Law and Dispute Resolution</h2>
      <p className="mb-8">
        These Terms and any dispute arising out of or related to them will be governed by and construed in accordance with the laws of [Your
        Jurisdiction], without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms will be brought
        exclusively in the federal or state courts located in [Your Jurisdiction], and the parties irrevocably consent to the personal jurisdiction
        and venue therein.
      </p>

      <h2 className="text-2xl font-bold mb-4">13. Termination</h2>
      <p className="mb-8">
        SnapTrip.ai may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever,
        including without limitation if you breach these Terms. Upon termination, your right to use the Service will cease immediately.
      </p>

      <h2 className="text-2xl font-bold mb-4">14. Miscellaneous</h2>
      <ul className="list-disc list-inside mb-8">
        <li className="mb-2">
          Entire Agreement: These Terms constitute the entire agreement between you and SnapTrip.ai regarding the use of the Service.
        </li>
        <li className="mb-2">
          Severability: If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid
          and enforceable.
        </li>
        <li className="mb-2">
          Waiver: No waiver of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition
          or any other term or condition.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">15. Contact Information</h2>
      <p>
        If you have any questions about these Terms, please contact us at{' '}
        <a href="mailto:help@snaptrip.ai" className="text-blue-600 underline">
          help@snaptrip.ai
        </a>
        .
      </p>
    </div>
  );
};

export default TermsOfService;
