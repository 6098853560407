import { Modal, Form } from 'antd';
import styles from './add-place-modal.module.scss';
import CitySearchBox from '@/components/commons/CitySearchBox/index.jsx';
import { useResponsive } from 'ahooks';
import { useRef, useState } from 'react';
import toast from '@/utils/toast.js';

function AddPlaceModal({ onCancel, title = 'Edit city', isPromiseSubmit, onSelectedPlace, ...props }) {
  const { md } = useResponsive();
  const [loading, setLoading] = useState(false);
  const state = useRef({
    selected: null
  });

  const onSubmit = async () => {
    try {
      if (!state.current.selected) {
        toast.info('Please select city.');
        return;
      }
      if (isPromiseSubmit) {
        setLoading(true);
        await onSelectedPlace(state.current.selected);
        onCancel();
      } else {
        onSelectedPlace && onSelectedPlace(state.current.selected);
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className={styles.addModal}
      {...props}
      centered
      width={md ? 450 : '100%'}
      onOk={onSubmit}
      okButtonProps={{
        loading
      }}
      onCancel={onCancel}
      title={title}
      zIndex={2000}
      destroyOnClose
    >
      <Form
        style={{ marginTop: 20 }}
        name="basic"
        labelCol={{
          span: 5
        }}
        wrapperCol={{
          span: 18
        }}
      >
        <Form.Item label="City" name="city">
          <CitySearchBox
            className={'w-full'}
            placeholder="Select city"
            onSelect={(_, item) => {
              state.current.selected = item;
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddPlaceModal;
