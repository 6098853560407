import { useEffect, useState, useCallback } from 'react';
import { ZZSourceType } from '@/utils/const.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import emitter from '@/utils/emitter.js';
// import { ZZChatScenes } from '@/utils/config.js';
import styles from './source-operate.module.scss';
// import { nanoid } from 'nanoid';

function SourceOperate({ source }) {
  // const [isCanTalk, setIsCanTalk] = useState(false);
  const [isCanPick, setIsCanPick] = useState(false);
  const [isCanDiscover, setIsCanDiscover] = useState(false);
  // const [isCanAdd, setIsCanAdd] = useState(false);

  useEffect(() => {
    if (!source) {
      return;
    }
    // const talk = [ZZSourceType.attraction, ZZSourceType.hotel, ZZSourceType.restaurant].includes(source.type);
    const pick = [ZZSourceType.attraction, ZZSourceType.hotel, ZZSourceType.restaurant].includes(source.type);
    // const add = [ZZSourceType.youtube, ZZSourceType.webpage].includes(source.type);
    const discover = [ZZSourceType.city].includes(source.type);
    // setIsCanTalk(talk);
    setIsCanDiscover(discover);
    // setIsCanAdd(add);
    setIsCanPick(pick);
  }, [source]);

  // const onTalk = useCallback(() => {
  //   if (isCanTalk) {
  //     emitter.emit(ZZEmittedEvent.askAI, {
  //       scene: ZZChatScenes.attraction,
  //       ui_context: `Talk about ${source.title}`,
  //       context: {
  //         place: source.title
  //       }
  //     });
  //   }
  // }, [isCanTalk]);

  const onPick = useCallback(() => {
    emitter.emit(ZZEmittedEvent.triggerAddPickAttraction, source.title);
  }, [isCanPick]);

  const onDiscover = useCallback(() => {
    emitter.emit(ZZEmittedEvent.triggerAddCity, source.title);
  }, [isCanDiscover]);

  // const onAddMedia = useCallback(() => {
  //   emitter.emit(ZZEmittedEvent.triggerAddMedia, {
  //     id: nanoid(6),
  //     ...source
  //   });
  // }, [isCanAdd]);

  return (
    <div className={'mt-2'}>
      {/*{isCanTalk && (*/}
      {/*  <span className={styles.popBtn} onClick={onTalk}>*/}
      {/*    Talk about*/}
      {/*  </span>*/}
      {/*)}*/}
      {isCanDiscover && (
        <span className={styles.popBtn} onClick={onDiscover}>
          Discover
        </span>
      )}
      {isCanPick && (
        <span className={styles.popBtn} onClick={onPick}>
          Add picked
        </span>
      )}
      {/*{isCanAdd && (*/}
      {/*  <span className={styles.popBtn} onClick={onAddMedia}>*/}
      {/*    Add Media*/}
      {/*  </span>*/}
      {/*)}*/}
    </div>
  );
}

export default SourceOperate;
