import { uniq } from 'lodash-es';
import { getUniqueId } from '@/utils/index.js';

export default {
  getDeviceId() {
    let prefix = localStorage.getItem('zz-getDeviceId');
    if (!prefix) {
      prefix = getUniqueId(5);
      localStorage.setItem('zz-getDeviceId', prefix);
    }
    return prefix;
  },

  getUser() {
    try {
      return JSON.parse(localStorage.getItem('zz-user1-v4'));
    } catch (e) {
      console.log('e', e);
      return null;
    }
  },

  setUser(user) {
    try {
      localStorage.setItem('zz-user1-v4', JSON.stringify(user));
    } catch (e) {
      console.log('e', e);
    }
  },

  clearUser() {
    localStorage.removeItem('zz-user1-v4');
    this.clearToken();
  },

  setToken(token) {
    localStorage.setItem('zz-token-v8', token);
  },

  getToken() {
    return localStorage.getItem('zz-token-v8');
  },

  clearToken() {
    localStorage.removeItem('zz-token-v8');
  },

  getYoutubeHistoryList() {
    return JSON.parse(localStorage.getItem('zz-youtubeHistoryList')) || [];
  },

  setYoutubeHistoryList(list) {
    localStorage.setItem('zz-youtubeHistoryList', JSON.stringify(uniq(list)));
  },

  setIs12HourFormat(flag) {
    localStorage.setItem('zz-is-12-format-v2', flag ? '12' : '24');
  },

  getIs12HourFormat() {
    try {
      return (localStorage.getItem('zz-is-12-format-v2') ?? 12) == 12;
    } catch (e) {
      console.log('e', e);
      return true;
    }
  },

  setLastChooseDay(day) {
    localStorage.setItem('zz-lastChooseDay', `${day}`);
  },

  getLastChooseDay() {
    return localStorage.getItem('zz-lastChooseDay') || '1';
  },

  setGenerateParams(params) {
    localStorage.setItem('zz-generateParams', JSON.stringify(params));
  },

  getGenerateParams() {
    if (!localStorage.getItem('zz-generateParams')) {
      return {
        youtube_url: 'https://www.youtube.com/watch?v=Y7HVDYGSJsI&ab_channel=SofiaAbrantes'
      };
    } else {
      return JSON.parse(localStorage.getItem('zz-generateParams'));
    }
  }
};
