import { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import client from '@/utils/algolia.js';
import { debounce, uniqBy } from 'lodash-es';

function DestinationSearchBox(props) {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const selectRef = useRef();

  const debounceSearch = useMemo(() => {
    const onSearch = async text => {
      try {
        setFetching(true);
        const { results } = await client.search({
          requests: [
            {
              indexName: 'destination_v2',
              query: text,
              restrictSearchableAttributes: ['name'],
              filters: 'destination_type:city OR destination_type:region OR destination_type:country',
              hitsPerPage: 15
            }
          ]
        });
        console.log('results', results);
        let list = results[0]?.hits || [];
        list = uniqBy(list, item => `${item.name}_${item.country}`);
        const groupedAndSortedData = Object.values(
          list.reduce((acc, cur) => {
            acc[cur.name] = acc[cur.name] || [];
            acc[cur.name].push(cur);
            acc[cur.name].sort((a, b) => b.attraction_count - a.attraction_count);
            return acc;
          }, {})
        ).flat();
        // list = uniqBy(list, 'name');
        // setOptions(list);
        setOptions(
          groupedAndSortedData.map(o => {
            console.log('o', o);
            return {
              value: o.objectID,
              label: o.name,
              ...o
            };
          })
        );
      } catch (e) {
        console.log('e', e);
      }
    };
    return debounce(onSearch, 400);
  }, []);

  return (
    <Select
      {...props}
      ref={selectRef}
      onSelect={(...args) => {
        props.onSelect(...args);
        selectRef.current.blur();
      }}
      showSearch
      options={options}
      onSearch={debounceSearch}
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      optionRender={option => (
        <div className={'flex items-center justify-between'}>
          <span className={'font-medium ellipsis-1 mr-3'}>{option.data.name}</span>
          {option.data.destination_type != 'country' && <span className={'text-[#888] pr-2 !font-normal'}>{option.data.country}</span>}
        </div>
      )}
    />
  );
}

export default DestinationSearchBox;
