import styles from './attraction-item.module.scss';
import { Button, Dropdown, Tooltip } from 'antd';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { get, isEmpty, random } from 'lodash-es';
import { convertMinutesToHours, formatNumber, getImageUrl } from '@/utils/index.js';
import { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { logEvent } from '@/utils/ga.js';
import IconStar from '../../../assets/svgs/icon_star.svg?react';
import IconMore from '../../../assets/svgs/icon_more.svg?react';
import IconDrag from '../../../assets/svgs/icon_drag.svg?react';
import IconMustGo from '../../../assets/svgs/icon_mustgo.svg?react';
import { ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import toast from '@/utils/toast.js';
import { useGenerateStoreSelectors } from '@/stores/useGenerateStore.js';
import DeleteModal from '@/components/home-v4/modals/delete-modal.jsx';
import IconYoutube from '../../../assets/svgs/icon_youtube_avatar.svg?react';
import IconIns from '../../../assets/svgs/icon_ins_avatar.svg?react';
import IconTiktok from '../../../assets/svgs/icon_tiktok_avatar.svg?react';
import ImagesCarousel from '@/components/commons/ImagesCarousel/index.jsx';
import YoutubeCover from '@/components/commons/YoutubeCover/index.jsx';
import { logOnCall } from '@/utils/log.js';

function AttractionItem({ place, dayIndex, attractionIndex, onEditDuration, isLast, isDragging }) {
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const toggleIsShowPlaceInfo = useGlobalStoreSelectors.use.toggleIsShowPlaceInfo();
  const toggleIsShowBooking = useGlobalStoreSelectors.use.toggleIsShowBooking();
  const deleteAttraction = useTripStoreSelectorsV4.use.deleteAttraction();
  const isGenerating = useGenerateStoreSelectors.use.isGenerating();
  const [youtubes, setYoutubes] = useState([]);
  const [isShowDelete, setIsShowDelete] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (place && isEmpty(place?.photos)) {
      logOnCall({
        message: `place photos is empty, name-${place.name}, placeId-${place.place_id}`
      });
    }
    setYoutubes(place?.references?.filter(item => item.source === 'youtube'));
  };

  const onBeforeDelete = e => {
    e?.stopPropagation();
    if (isReadOnly) {
      toast.info('Please save as your own itinerary first.');
      return;
    }
    if (isGenerating) {
      toast.info('The itinerary is generating...');
      return;
    }
    logEvent({
      name: 'itinerary_attraction_delete_before'
    });
    setIsShowDelete(true);
  };

  const onDeleteAttraction = () => {
    logEvent({
      name: 'itinerary_attraction_delete_confirm'
    });
    deleteAttraction(dayIndex, place);
  };

  const onDetails = () => {
    logEvent({
      name: 'itinerary_attraction_detail'
    });
    console.log('youtubes', youtubes);
    toggleIsShowPlaceInfo({
      flag: true,
      placeId: place.place_id
      // localVideos: (youtubes?.length > 3 ? youtubes : []).map(item => {})
    });
  };

  const onDetailsToReviews = e => {
    e.stopPropagation();
    logEvent({
      name: 'itinerary_attraction_detail'
    });
    toggleIsShowPlaceInfo({
      flag: true,
      placeId: place.place_id,
      isToReviews: true
      // localVideos: (youtubes?.length > 3 ? youtubes : []).map(item => {})
    });
  };

  const getTourTimes = place => {
    if (place.start_time && place?.duration_minutes) {
      const start = dayjs()
        .hour(place.start_time.hour)
        .minute(place.start_time.minute)
        .second(0);
      const end = start.add(place.duration_minutes, 'minute');
      return `${start.format('hh:mm A')} - ${end.format('hh:mm A')}`;
    } else if (place?.duration_minutes) {
      return convertMinutesToHours(place?.duration_minutes);
    } else {
      return null;
    }
  };

  // const getTourTimes = place => {
  //   if (place.start_time && place?.duration_minutes) {
  //     const start = dayjs()
  //       .hour(place.start_time.hour)
  //       .minute(place.start_time.minute)
  //       .second(0);
  //     const end = start.add(place.duration_minutes, 'minute');
  //     // return `${start.format('hh:mm A')} - ${end.format('hh:mm A')}`;
  //     return {
  //       start: start.format('hh:mm A'),
  //       end: end.format('hh:mm A')
  //     };
  //   }
  // };

  const getHourRange = place => {
    const hours = place.duration_minutes / 60;
    if (hours < 1) {
      return '0.5-1 hour';
    }
    const lowerBound = Math.floor(hours);
    const upperBound = lowerBound + 1;
    return `${lowerBound}-${upperBound} hours`;
  };

  const onBooking = e => {
    e.stopPropagation();
    logEvent({
      name: 'itinerary_booking_click'
    });
    toggleIsShowBooking(true, {
      place_id: place.place_id,
      booking_url: place?.booking_url
    });
  };

  // const onPlayYoutube = (source, e) => {
  //   e?.stopPropagation();
  //   logEvent({
  //     name: 'itinerary_YouTube_click'
  //   });
  //   emitter.emit(ZZEmittedEvent.showPlayer, {
  //     title: `The youtube video about ${place?.name}`,
  //     url: source.url,
  //     type: source.reference_type
  //   });
  // };

  const onPlayImage = e => {
    e.stopPropagation();
    if (!isEmpty(youtubes)) {
      // if (md) {
      //   return;
      // }
      // onPlayYoutube(youtubes[0], e);
    } else {
      emitter.emit(
        ZZEmittedEvent.showPhotos,
        place?.photos?.map(item => getImageUrl(item.url))
      );
    }
  };

  const onMoveAttraction = () => {
    emitter.emit(ZZEmittedEvent.attractionMoveTo, {
      day_index_from: dayIndex,
      attraction_index_from: attractionIndex
    });
  };

  const getMenus = () => {
    const items = [
      {
        key: '1',
        label: (
          <div className="py-1 w-24" onClick={onDetails}>
            View details
          </div>
        )
      }
    ];
    if (!isReadOnly) {
      items.push(
        {
          key: '2',
          label: (
            <div className="py-1 w-24" onClick={onMoveAttraction}>
              Move to
            </div>
          )
        },
        {
          key: '3',
          label: (
            <div className="py-1 w-24" onClick={onBeforeDelete}>
              Delete
            </div>
          )
        }
      );
    }
    return {
      items
    };
  };

  function getRandomCount(count = 0) {
    return (count || 1) * 10 + random(1, 9);
  }

  return (
    <div
      className={`${styles.attractionItem} relative ${isDragging ? styles.dragging : ''}`}
      onClick={onDetails}
      id={`itinerary-attraction-${place.key}`}
    >
      <div className={'flex flex-col items-center mr-2'}>
        <span className={`w-[1px] flex-1 flex flex-col items-center ${attractionIndex == 0 && !place?.previous_route ? 'invisible' : ''}`}>
          <span className="w-1.5 h-1.5 rounded-full bg-[#6B7280]" />
          <span className={'w-0 flex-grow'} style={{ borderRight: '1px dashed #6B7280' }} />
        </span>
        <span className={'w-6 h-6 my-1 rounded-full text-white f-center flex-shrink-0'} style={{ backgroundColor: place.color }}>
          {attractionIndex + 1}
        </span>
        <span className={`w-[1px] flex-1 flex flex-col items-center ${isLast && !place?.next_route ? 'invisible' : ''}`}>
          <span className={'w-0 flex-grow'} style={{ borderRight: '1px dashed #6B7280' }} />
          <span className="w-1.5 h-1.5 rounded-full bg-[#6B7280]" />
        </span>
      </div>
      <IconDrag className={'self-center mr-1'} />
      <div className={'flex flex-col md:flex-row w-0 flex-grow'}>
        <div
          onClick={e => onPlayImage(e)}
          className={'relative md:mr-3 shrink-0 mb-2 md:mb-0 rounded-md md:rounded-[12px] overflow-hidden w-full h-[210px] md:w-[310px]'}
        >
          {!isEmpty(youtubes) ? (
            <YoutubeCover
              key2={place.key}
              staticImage={getImageUrl(get(place, 'photos.0.url'), 400)}
              item={youtubes[0]}
              className={'w-full h-full'}
              placeId={place?.place_id}
              logoComponent={
                <span className={'flex items-center'}>
                  <span className="f-center">
                    <IconTiktok />
                    <IconIns className={'-ml-3'} />
                    <IconYoutube className={'-ml-3'} />
                  </span>
                  <span className={'text-[14px] text-[#0a0a0a] ml-2'}>{getRandomCount(place?.references?.length)} Recommended</span>
                </span>
              }
            />
          ) : (
            <ImagesCarousel images={place?.photos || []} />
          )}
        </div>
        <div className={'flex-1 md:w-0 flex flex-col justify-around relative'}>
          <span
            className={`absolute right-3 top-0 cursor-pointer ${styles.more} md:hidden`}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Dropdown menu={getMenus()} placement={'bottomRight'}>
              <IconMore />
            </Dropdown>
          </span>
          {place?.duration_minutes && (
            <div
              className={'text-[14px] mb-1'}
              onClick={e => {
                e.stopPropagation();
                if (isReadOnly) {
                  toast.info('Please save as your own itinerary first.');
                  return;
                }
                onEditDuration();
              }}
            >
              <ClockCircleOutlined className={'mr-2'} />
              {getTourTimes(place)} {!isReadOnly && <EditOutlined className={`ml-1`} />}
            </div>
          )}
          <div className={'font-medium text-[20px] text-[#374151] ellipsis-1 pr-9 flex items-center'}>
            {place?.duration_reason && (
              <span className={'f-center'}>
                <Tooltip title={<div className={'text-[#374151] text-[15px]'}>{place?.duration_reason}</div>} color={'white'}>
                  <IconMustGo className={'mr-2'} />
                </Tooltip>
              </span>
            )}
            <span>{place?.name}</span>
          </div>
          <div className={'w-10 h-[1px] bg-[#E5E7EB] my-1.5'} />
          {place?.duration_minutes && <div className={'text-[14px]'}>Duration: {getHourRange(place)}</div>}
          <div className={'text-[14px] text-[#6B7280] ellipsis-2'}>{place?.description}</div>
          <div className={'w-10 h-[1px] bg-[#E5E7EB] my-1.5'} />
          <div className="flex items-end justify-between" onClick={onDetailsToReviews}>
            <span className={`f-center ${place?.user_rating ? 'visible' : 'invisible'}`}>
              <span className={'font-medium'}>{place?.user_rating}</span>
              <IconStar className={'ml-0.5 mr-1 mb-0.5'} />
              {<span className={'text-[#374151]'}>({formatNumber(place?.total_user_reviews)} reviews)</span>}
            </span>
            {!!place?.booking_url && <Button onClick={onBooking}>See Tickets</Button>}
          </div>
        </div>
      </div>
      <DeleteModal open={isShowDelete} onCancel={() => setIsShowDelete(false)} onSubmit={onDeleteAttraction} />
    </div>
  );
}

export default AttractionItem;
