import { Button, Col, DatePicker, Input, Modal, Row, Tabs } from 'antd';
import styles from './upload-modal.module.scss';
import useGlobalStore, { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import { ZZEmittedEvent, ZZUploadType } from '@/utils/const.js';
import { useEffect, useState } from 'react';
import { capitalize, cloneDeep } from 'lodash-es';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import dayjs from 'dayjs';
import emitter from '@/utils/emitter.js';
import { addFlight, addHotel, updateHotel } from '@/apis/trips_v4.js';
import AirportSearchBox from '@/components/commons/AirportSearchBox/index.jsx';
import HotelSearchBox from '@/components/commons/HotelSearchBox/index.jsx';
import AirlineSearchBox from '@/components/commons/AirlineSearchBox/index.jsx';
import { useResponsive } from 'ahooks';
import { DateField, DateTimeField } from '@mui/x-date-pickers';
import toast from '@/utils/toast.js';

const items = [
  {
    key: ZZUploadType.flight,
    label: 'Flight'
  },
  {
    key: ZZUploadType.hotel,
    label: 'Hotel'
  }
];

const disabledDate = current => {
  return current && current < dayjs().startOf('day');
};

function UploadModal(props) {
  const toggleIsShowUpload = useGlobalStoreSelectors.use.toggleIsShowUpload();
  const updateItinerary = useTripStoreSelectorsV4.use.updateItinerary();
  const uploadType = useGlobalStoreSelectors.use.uploadType();
  const operateHotel = useGlobalStoreSelectors.use.operateHotel();
  const [tab, setTab] = useState(items[0].key);
  const [isDisable, setIsDisable] = useState(true);
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { md } = useResponsive();

  useEffect(() => {
    const unsub1 = useGlobalStore.subscribe(
      state => state.uploadType,
      value => {
        if (value == ZZUploadType.all) {
          setTab(ZZUploadType.flight);
        } else {
          setTab(value);
        }
      }
    );
    return () => unsub1();
  }, []);

  useEffect(() => {
    if (formData) {
      if (tab === ZZUploadType.flight) {
        const flag = ['depart_time', 'depart_air_port', 'arrive_time', 'arrive_air_port'].some(key => !formData[key]);
        console.log('formData', formData);
        setIsDisable(flag);
      } else if (tab === ZZUploadType.hotel) {
        const flag = ['address', 'checkin', 'checkout'].some(key => !formData[key]);
        setIsDisable(flag);
      }
    } else {
      setFormData(true);
    }
  }, [formData]);

  const onCancel = () => {
    toggleIsShowUpload(false, null);
  };

  const onTabChange = key => {
    setTab(key);
  };

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      const { sessionId, itineraryId } = useTripStoreV4.getState();
      const params = cloneDeep(formData);
      if (Reflect.has(params, 'arrive_time')) {
        params.arrive_time = dayjs(params.arrive_time).format('YYYY-MM-DD HH:mm');
        params.depart_time = dayjs(params.depart_time).format('YYYY-MM-DD HH:mm');
      } else if (Reflect.has(params, 'checkin')) {
        params.checkin = dayjs(params.checkin).format('YYYY-MM-DD');
        params.checkout = dayjs(params.checkout).format('YYYY-MM-DD');
      }
      if (tab === ZZUploadType.flight) {
        await addFlight({
          session_id: sessionId,
          itinerary_id: itineraryId,
          flight: params
        });
        emitter.emit(ZZEmittedEvent.uploadFlightSuccess);
      } else if (tab === ZZUploadType.hotel) {
        if (operateHotel) {
          params.id = operateHotel.id;
          params.place_id = params.place_id == operateHotel.name ? operateHotel.place_id : params.place_id;
        }
        const param2 = {
          session_id: sessionId,
          itinerary_id: itineraryId,
          hotel: params
        };
        let data = null;
        if (operateHotel) {
          data = await updateHotel(param2);
        } else {
          data = await addHotel(param2);
        }
        updateItinerary(data, false);
      }
      toast.success(`${operateHotel ? 'Update' : 'Upload'} ${tab} successfully!`);
      onCancel();
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const init = () => {
    if (uploadType == ZZUploadType.hotel) {
      if (operateHotel) {
        const formatDate = date => dayjs(`${date.year}-${date.month}-${date.day}`);
        console.log('operateHotel', operateHotel);
        const params = {
          place_id: operateHotel.name,
          address: operateHotel.name,
          confirmation: operateHotel.confirmation_number,
          checkin: formatDate(operateHotel.check_in_time.date),
          checkout: formatDate(operateHotel.check_out_time.date)
        };
        setFormData(params);
      }
    }
  };

  const onAfterOpenChange = flag => {
    if (flag) {
      init();
    }
  };

  return (
    <Modal
      title={`Add ${capitalize(tab)}`}
      className={styles.uploadModal}
      centered
      placement={'bottom'}
      destroyOnClose
      width={750}
      footer={null}
      {...props}
      afterOpenChange={onAfterOpenChange}
      styles={{
        content: {
          overflow: 'hidden'
        }
      }}
      onCancel={onCancel}
    >
      <div className={'flex flex-col md:px-4'}>
        {uploadType === ZZUploadType.all && <Tabs defaultActiveKey={tab} items={items} onChange={onTabChange} />}
        <div className={'px-0 mt-3'}>
          {tab === ZZUploadType.flight && <FlightUpload onChange={setFormData} />}
          {tab === ZZUploadType.hotel && <HotelUpload onChange={setFormData} form={formData} />}
        </div>
        <div className="f-center mt-10 mb-2 w-full">
          <Button size={'large'} className={`mx-3 !flex-1 ${md ? '!h-11' : '!h-10'}`} onClick={onCancel}>
            Back to itinerary
          </Button>
          <Button
            size={'large'}
            type={'primary'}
            className={`mx-3 !flex-1 ${styles.confirm} !py-[9px] ${md ? '!h-11' : '!h-10'}`}
            onClick={onConfirm}
            disabled={isDisable}
            loading={isLoading}
          >
            Yes, confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function FlightUpload({ onChange }) {
  const { md } = useResponsive();
  const [form, setForm] = useState({
    airline: null,
    flight: '',
    depart_time: null,
    depart_air_port: null,
    depart_air_port_place_id: null,
    arrive_time: null,
    arrive_air_port: null,
    arrive_air_port_place_id: null,
    confirmation: '',
    notes: '',
    cost: null
  });

  const onChangeValue = (keys, values) => {
    const params = {
      ...form
    };
    keys.forEach((key, index) => {
      params[key] = values[index];
    });
    setForm(params);
    onChange && onChange(params);
  };

  return (
    <>
      <div className={'flex flex-col'}>
        <Row className={'w-full'} justify={'space-between'} gutter={12}>
          <Col span={md ? 8 : 12}>
            <span className={'font-medium text-[12px] mb-1 text-black'}>FLIGHT</span>
            <AirlineSearchBox
              placeholder={'Airline'}
              className={'w-full'}
              value={form.airline}
              onChange={value => onChangeValue(['airline'], [value])}
            />
          </Col>
          <Col span={md ? 8 : 12}>
            <span className={'font-medium text-[12px] mb-1 text-black invisible'}>Flight</span>
            <Input onChange={e => onChangeValue(['flight'], [e.target.value])} value={form.flight} placeholder={'Flight number'} />
          </Col>
          <Col span={md ? 8 : 24} className={'mt-4 md:mt-0'}>
            <div className={'flex flex-col'}>
              <span className={'font-medium text-[12px] mb-1 text-black'}>CONFIRMATION #</span>
              <Input onChange={e => onChangeValue(['confirmation'], [e.target.value])} value={form.confirmation} placeholder={''} />
            </div>
            {/*<div className={'flex flex-col mt-4'}>*/}
            {/*  <span className={'font-medium text-[12px] mb-1 text-black'}>NOTES</span>*/}
            {/*  <Input onChange={e => onChangeValue('notes', e.target.value)} value={form.notes} placeholder={'Optional notes'} />*/}
            {/*</div>*/}
            {/*<div className={'flex flex-col mt-4'}>*/}
            {/*  <span className={'font-medium text-[12px] mb-1 text-black'}>COST</span>*/}
            {/*  <InputNumber onChange={value => onChangeValue('cost', value)} value={form.cost} placeholder={'Cost'} prefix={'$'} className={'!w-full'} />*/}
            {/*</div>*/}
          </Col>
        </Row>
      </div>
      <div className={'flex flex-col mt-4 w-full'}>
        <span className={'font-medium text-[12px] mb-1 text-black required'}>DEPART</span>
        <Row className={'w-full'} justify={'space-between'} gutter={12}>
          <Col span={md ? 8 : 24}>
            {md ? (
              <DatePicker
                inputReadOnly={!md}
                className={'w-full'}
                showNow={false}
                showTime={{
                  format: 'HH:mm'
                }}
                format="YYYY-MM-DD HH:mm"
                onOk={value => onChangeValue(['depart_time'], [value])}
              />
            ) : (
              <DateTimeField
                value={form.depart_time}
                fullWidth
                size="small"
                variant="outlined"
                className={'dateTimeField'}
                onChange={value => onChangeValue(['depart_time'], [dayjs(value).isValid() ? value : null])}
              />
            )}
          </Col>
          <Col span={md ? 16 : 24} className={'mt-4 md:mt-0'}>
            <AirportSearchBox
              className={'w-full'}
              placeholder="Depart airport"
              value={form.depart_air_port_place_id}
              onChange={item => {
                onChangeValue(['depart_air_port', 'depart_air_port_place_id'], [item.label, item.value]);
              }}
            />
          </Col>
        </Row>
      </div>
      <div className={'flex flex-col mt-4 w-full'}>
        <span className={'font-medium text-[12px] mb-1 text-black required'}>ARRIVE</span>
        <Row className={'w-full'} justify={'space-between'} gutter={12}>
          <Col span={md ? 8 : 24}>
            {md ? (
              <DatePicker
                inputReadOnly={!md}
                className={'w-full'}
                showNow={false}
                showTime={{
                  format: 'HH:mm'
                }}
                format="YYYY-MM-DD HH:mm"
                onOk={value => onChangeValue(['arrive_time'], [value])}
              />
            ) : (
              <DateTimeField
                fullWidth
                size="small"
                variant="outlined"
                className={'dateTimeField'}
                value={form.arrive_time}
                onChange={value => onChangeValue(['arrive_time'], [dayjs(value).isValid() ? value : null])}
              />
            )}
          </Col>
          <Col span={md ? 16 : 24} className={'mt-4 md:mt-0'}>
            <AirportSearchBox
              className={'w-full'}
              value={form.arrive_air_port_place_id}
              placeholder="Arrive airport"
              onChange={item => {
                onChangeValue(['arrive_air_port', 'arrive_air_port_place_id'], [item.label, item.value]);
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

function HotelUpload({ onChange, form }) {
  const { md } = useResponsive();
  console.log('form-->', form);
  const onChangeValue = (keys, values) => {
    const params = {
      ...form
    };
    keys.forEach((key, index) => {
      params[key] = values[index];
    });
    onChange && onChange(params);
  };

  return (
    <div className={'border rounded-xl'}>
      <div className={'flex items-center flex-col md:flex-row'}>
        <div className={`${md ? 'flex-1' : 'w-full border-b'} flex flex-col items-start md:border-r`}>
          <span className={styles.label}>CHECK IN</span>
          {md ? (
            <DatePicker
              disabledDate={disabledDate}
              placeholder={'Enter check in date'}
              variant={'borderless'}
              inputReadOnly={!md}
              className={`w-full ${styles.datePicker}`}
              onChange={value => onChangeValue(['checkin'], [value])}
              value={form.checkin}
            />
          ) : (
            <DateField
              fullWidth
              size="small"
              variant="outlined"
              className={'dateTimeField'}
              onChange={value => onChangeValue(['checkin'], [dayjs(value).isValid() ? value : null])}
            />
          )}
        </div>
        <div className={`${md ? 'flex-1' : 'w-full'} flex-1 flex flex-col items-start`}>
          <span className={styles.label}>CHECK OUT</span>
          {md ? (
            <DatePicker
              disabledDate={disabledDate}
              placeholder={'Enter check out date'}
              variant={'borderless'}
              inputReadOnly={!md}
              className={`w-full ${styles.datePicker}`}
              onChange={value => onChangeValue(['checkout'], [value])}
              value={form.checkout}
            />
          ) : (
            <DateField
              fullWidth
              size="small"
              variant="outlined"
              className={'dateTimeField'}
              onChange={value => onChangeValue(['checkout'], [dayjs(value).isValid() ? value : null])}
            />
          )}
        </div>
      </div>
      <div className={'border-t !pt-1.5'}>
        <span className={`${styles.label}`}>HOTEL</span>
        <HotelSearchBox
          variant="borderless"
          className={'w-full'}
          value={form.place_id}
          placeholder="Enter your hotel name or stay address"
          onChange={item => {
            onChangeValue(['address', 'place_id'], [item.label, item.value]);
          }}
        />
      </div>
    </div>
  );
}

export default UploadModal;
