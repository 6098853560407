import Page from '@/components/page/index.jsx';
import { Button } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { ReactTyped } from 'react-typed';

let list = [
  '11111This is the beginning of the sentence.',
  // '\n',
  '222This is the beginning of the sentence.',
  '333This is the beginning of the sentence.'
];

function Test() {
  const [typedSentence, setTypedSentence] = useState([]);
  const [string, setString] = useState('');
  const state = useRef({
    ref: null
  });

  // useEffect(() => {
  //   // 只在组件首次挂载时初始化 Typed 实例
  //   typed.current = new Typed(el.current, {
  //     strings: [sentence],
  //     typeSpeed: 50,
  //     showCursor: true,
  //     loop: false
  //   });
  //
  //   return () => {
  //     // 销毁实例以防内存泄漏
  //     typed.current.destroy();
  //   };
  // }, []);

  useEffect(() => {
    setString(list[0]);
  }, []);

  // useEffect(() => {
  //   if (typed.current) {
  //     typed.current.strings = [sentence]; // 更新句子
  //     typed.current.reset(false); // 不重置动画，只追加内容
  //   }
  // }, [sentence]);

  const addNewText = () => {
    //   // setSentence(prevSentence => prevSentence + ' And this part was added!');
    //   typed.current.strings = [sentence + ' And this part was added!'];
    //   typed.current.reset();
  };

  const onComplete = e => {
    console.log('e', e);
    setTypedSentence(prev => {
      return [...prev, e.strings];
    });
    setString(list[1]);
    state.current.ref.reset();
  };

  return (
    <Page className={'w-full flex flex-col items-center py-4 overflow-y-auto whitespace-pre'}>
      <Button className={'mb-3'} onClick={addNewText}>
        Add More Text
      </Button>
      {/*<span ref={el} className={'mt-4'}></span>*/}
      <div className={'w-[50vw] bg-gray-300 p-4 flex break-all flex-wrap'}>
        {typedSentence.map(p => (
          <span>{p}</span>
        ))}
        <ReactTyped
          typedRef={r => {
            state.current.ref = r;
          }}
          strings={[string]}
          typeSpeed={60}
          onComplete={onComplete}
        />
      </div>
    </Page>
  );
}

export default Test;
