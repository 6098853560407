import { useState } from 'react';
import { Modal, Spin } from 'antd';
import { isPhone } from '@/utils/index.js';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './login-modal.module.scss';
import IconGoogle from '../../../assets/svgs/icon_google_black.svg?react';
import IconFacebook from '../../../assets/svgs/icon_facebook_black.svg?react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { logEvent, tagEvent } from '@/utils/ga.js';
import { addUser } from '@/apis/user.js';
import cache from '@/utils/cache.js';
import toast from '@/utils/toast.js';
import emitter from '@/utils/emitter.js';
import { ZZEmittedEvent } from '@/utils/const.js';
import { useUserStoreSelectors } from '@/stores/useUserStore.js';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

function LoginModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const setIsShowLogin = useUserStoreSelectors.use.setIsShowLogin();
  const afterLoginFunc = useUserStoreSelectors.use.afterLoginFunc();
  const setUser = useUserStoreSelectors.use.setUser();

  const onTriggerGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const res = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
      });
      onAddUser(res.data, 'google');
    },
    onError: () => {
      console.log('onError');
      setIsLoading(false);
    },
    onNonOAuthError: () => {
      console.log('onNonOAuthError');
      setIsLoading(false);
    }
  });

  const onGoogleLogin = () => {
    logEvent({
      name: 'login'
    });
    setIsLoading(true);
    onTriggerGoogle();
  };

  const onAddUser = async (userInfo, source) => {
    try {
      const data = await addUser({
        device_id: cache.getDeviceId(),
        language: 'en',
        username: userInfo.email,
        source,
        extra: {
          email: userInfo.email,
          name: userInfo.name,
          avatar: userInfo.picture
        }
      });
      cache.setUser(data);
      cache.setToken(data.token);
      setUser(data);
      toast.success('Login success');
      tagEvent('event', 'conversion', {
        send_to: 'AW-16706051350/Qm9cCLiUktUZEJa6iJ4-'
      });
      logEvent({
        name: 'login-success'
      });
      afterLoginFunc && afterLoginFunc();
      emitter.emit(ZZEmittedEvent.loginSuccess);
    } catch (e) {
      toast.info('Login failed, please try again later');
    } finally {
      setIsShowLogin(false);
      setIsLoading(false);
    }
  };

  const onFaceBookFailure = () => {
    console.log('facebook login failed, please try again later');
    setIsLoading(false);
  };

  const onResponseFacebook = response => {
    console.log('response', response);
    onAddUser(
      {
        email: response.email,
        name: response.name,
        picture: response?.picture?.data?.url
      },
      'facebook'
    );
  };

  const onCancel = () => {
    setIsShowLogin(false);
  };

  return (
    <Modal onCancel={onCancel} footer={null} width={isPhone ? 350 : 400} centered destroyOnClose {...props}>
      <Spin
        spinning={isLoading}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
              color: 'black'
            }}
            spin
          />
        }
      >
        <div className={'flex flex-col items-center min-h-60'}>
          <h3 className={'font-medium text-[20px] py-2'}>🤗 Join SnapTrip</h3>
          <div className={'text-[#6B7280] text-[16px]'}>Sign in to save itinerary and plan together.</div>
          <div className="py-6 f-center flex-col w-full">
            <div className={styles.btn} onClick={onGoogleLogin}>
              <IconGoogle className={'mr-1'} />
              <span className="">Sign in with Google</span>
            </div>
            <FacebookLogin
              appId="363610983458556"
              fields="name,email,picture.width(480).height(480)"
              callback={onResponseFacebook}
              onFailure={onFaceBookFailure}
              render={renderProps => (
                <div className={styles.btn} onClick={renderProps.onClick}>
                  <IconFacebook className={'mr-1'} />
                  <span className="">Sign in with Facebook</span>
                </div>
              )}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default LoginModal;
