import { useEffect, useState } from 'react';
import styles from './direction-box.module.scss';
import useTripStoreV4, { useTripStoreSelectorsV4 } from '@/stores/useTripStore_v4.js';
import IconCar from '../../../assets/svgs/icon_car.svg?react';
import IconWalk from '../../../assets/svgs/icon_walk.svg?react';
import IconBike from '../../../assets/svgs/icon_bike.svg?react';
import IconTransit from '../../../assets/svgs/icon_transit.svg?react';
import IconCarBlack from '../../../assets/svgs/icon_car_black.svg?react';
import IconWalkBlack from '../../../assets/svgs/icon_walk_black.svg?react';
import IconBikeBlack from '../../../assets/svgs/icon_bike_black.svg?react';
import IconTransitBlack from '../../../assets/svgs/icon_transit_black.svg?react';
import IconArrowBottom from '../../../assets/svgs/icon_arrow_bottom_v2.svg?react';
import { getDirections } from '@/apis/trips.js';
import { updateAttraction } from '@/apis/trips_v4.js';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { get } from 'lodash-es';
import toast from '@/utils/toast.js';

function DirectionBox({ isShowContent, isShowFromText, isShowEndText = false, route, dayIndex, attractionIndex, type }) {
  const [afterRoute, setAfterRoute] = useState(null);
  const isReadOnly = useTripStoreSelectorsV4.use.isReadOnly();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAfterRoute(route);
  }, []);

  const onDirection = () => {
    let f = null;
    let t = null;
    if (afterRoute?.place_id_a) {
      f = getPlace(afterRoute?.place_id_a);
    }
    if (afterRoute?.place_id_b) {
      t = getPlace(afterRoute?.place_id_b);
    }
    if (!f) {
      toast.info('Missing start point');
      return;
    }
    if (!t) {
      toast.info('Missing end point');
      return;
    }
    const from = `${f.name},${f.city}`;
    const to = `${t.name},${t.city}`;
    const url = `https://www.google.com/maps/dir/${from}/${to}`;
    window.open(url, '_blank');
  };

  const getPlace = placeId => {
    const { itinerary } = useTripStoreV4.getState();
    for (const day of itinerary.daily_itineraries) {
      const target = day?.places?.find(place => place.place_id === placeId);
      if (target) {
        return target;
      } else if (day?.hotel?.place_id === placeId) {
        return day?.hotel;
      } else if (day?.place_to_stay?.place_id === placeId) {
        return day?.place_to_stay;
      }
    }
    return null;
  };

  const onChangeMode = async mode => {
    try {
      const { sessionId, itineraryId } = useTripStoreV4.getState();
      setIsLoading(true);
      const a = getPlace(afterRoute?.place_id_a);
      const b = getPlace(afterRoute?.place_id_b);
      if (!a || !b) {
        toast.info('No route found');
        return;
      }
      const data = await getDirections({
        session_id: sessionId,
        requests: [
          {
            from_place: {
              longitude: a?.longitude,
              latitude: a?.latitude
            },
            to_place: {
              longitude: b?.longitude,
              latitude: b?.latitude
            },
            traffic_mode: mode.toLowerCase()
          }
        ]
      });
      if (get(data, '0.duration_minutes') != null) {
        const value = data[0];
        setAfterRoute({
          ...afterRoute,
          ...value
        });
        if (!isReadOnly) {
          await updateAttraction({
            session_id: sessionId,
            itinerary_id: itineraryId,
            day_index: dayIndex + 1,
            attraction_index: attractionIndex + 1,
            attraction: {
              [type]: {
                duration_minutes: value.duration_minutes,
                distance_in_km: value.distance_in_km,
                traffic_mode: mode
              }
            }
          });
        }
      } else {
        toast.info('No route found');
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  };

  const getIcon = method => {
    return {
      DRIVING: <IconCar />,
      WALKING: <IconWalk />,
      BICYCLING: <IconBike />,
      TRANSIT: <IconTransit />
    }[method];
  };

  const getDuration = duration => {
    if (duration < 60) {
      return `${Math.ceil(duration)} mins`;
    } else {
      let hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      if (minutes > 30) {
        hours += 1;
      } else if (minutes > 0) {
        hours += 0.5;
      }
      return `${hours} ${hours > 1 ? 'hrs' : 'hr'}`;
    }
  };

  return isShowContent ? (
    <div className={`relative py-3 ${styles.box} flex flex-col items-start md:flex-row md:items-center`}>
      <span className="f-center">
        {isLoading && <LoadingOutlined className={'mr-2'} />}
        {isShowFromText && <span className={'mr-2 font-medium'}>&nbsp;from {afterRoute?.attraction_a}</span>}
        {isShowEndText && <span className={'mr-2 font-medium'}>&nbsp;to {afterRoute?.attraction_b}</span>}
      </span>
      <div className="flex items-center w-full md:w-auto">
        {!!afterRoute?.distance_in_km != null && (
          <>
            <span>{afterRoute?.distance_in_km} km</span>
            <span className={'mx-2'}>•</span>
          </>
        )}
        {afterRoute?.duration_minutes != null && (
          <>
            <Popover
              content={<MethodsContent traffic_mode={afterRoute?.traffic_mode} onChangeMode={onChangeMode} />}
              arrow={false}
              placement={'bottom'}
            >
              <span className={`f-center cursor-pointer ${styles.duration}`}>
                {getIcon(afterRoute?.traffic_mode)}
                <span className={'mx-1'}>{getDuration(afterRoute?.duration_minutes)}</span>
                <IconArrowBottom />
              </span>
            </Popover>
            <span className={'mr-2'}>•</span>
          </>
        )}
        <span onClick={onDirection} className={'text-[#313131] cursor-pointer underline'}>
          Directions
        </span>
      </div>
    </div>
  ) : null;
}

const MethodsContent = ({ traffic_mode, onChangeMode }) => {
  return (
    <div className={'text-[14px] w-48'}>
      <div className={styles.methodOption} onClick={() => onChangeMode('WALKING')}>
        <span className="f-center">
          <IconWalkBlack className={'mr-2'} />
          <span>Walking</span>
        </span>
        {traffic_mode === 'WALKING' && <CheckOutlined />}
      </div>
      <div className={styles.methodOption} onClick={() => onChangeMode('DRIVING')}>
        <span className="f-center">
          <IconCarBlack className={'mr-2'} />
          <span>Driving</span>
        </span>
        {traffic_mode === 'DRIVING' && <CheckOutlined />}
      </div>
      <div className={styles.methodOption} onClick={() => onChangeMode('TRANSIT')}>
        <span className="f-center">
          <IconTransitBlack className={'mr-2'} />
          <span>Transit</span>
        </span>
        {traffic_mode === 'TRANSIT' && <CheckOutlined />}
      </div>
      <div className={styles.methodOption} onClick={() => onChangeMode('BICYCLING')}>
        <span className="f-center">
          <IconBikeBlack className={'mr-2'} />
          <span>Cycling</span>
        </span>
        {traffic_mode === 'BICYCLING' && <CheckOutlined />}
      </div>
    </div>
  );
};

export default DirectionBox;
