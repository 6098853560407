import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import extractVideoId, { ensureHttps, getQueryParam } from '@/utils/index.js';
import styles from './index.module.scss';
import IconPlay from '../../../assets/svgs/icon_play.svg?react';
import IconYoutube from '../../../assets/svgs/icon_youtube_2.svg?react';
import { useGlobalStoreSelectors } from '@/stores/useGlobalStore.js';
import ImageWrapper from '@/components/commons/ImageWrapper/index.jsx';

export default function YoutubeCover({ item, className, staticImage = false, key2 = true, logoComponent, placeId }) {
  const [videoUrl, setVideoUrl] = useState('');
  const focusPlayVideoKey = useGlobalStoreSelectors.use.focusPlayVideoKey();
  const setFocusPlayVideoKey = useGlobalStoreSelectors.use.setFocusPlayVideoKey();
  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const timestamp = dayjs().valueOf();
    let originUrl = ensureHttps(item?.link || item?.url);
    let isShorts = originUrl.includes('shorts') && originUrl.includes('youtube');
    if (isShorts) {
      let url = originUrl.replace('shorts', 'embed');
      url = `${url}?autoplay=1&v=${timestamp}`;
      setVideoUrl(url);
    } else if (originUrl) {
      const id = extractVideoId(originUrl);
      let t = getQueryParam(originUrl, 't') || 0;
      if (t) {
        t = t.replace('s', '');
      }
      let url = `https://www.youtube-nocookie.com/embed/${id}?autoplay=1&v=${timestamp}&start=${t}&mute=1`;
      setVideoUrl(url);
    }
  };

  const renderContent = () => {
    if (focusPlayVideoKey && focusPlayVideoKey == key2) {
      return (
        <iframe
          className={'bg-black rounded-[10px] overflow-hidden'}
          width="100%"
          height="100%"
          src={videoUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      );
    } else {
      return (
        <ImageWrapper
          src={staticImage || item.thumbnail?.static}
          alt=""
          className={`w-full h-full rounded-md object-cover ${styles.img}`}
          placeId={placeId}
        />
      );
    }
  };

  const onFocus = () => {
    setFocusPlayVideoKey(key2);
  };

  const onUnFocus = () => {
    setFocusPlayVideoKey(null);
  };

  return (
    <div className={`relative ${className} overflow-hidden ${styles.box}`} onMouseEnter={onFocus} onMouseOut={onUnFocus} id={`youtube-cover-${key2}`}>
      {focusPlayVideoKey == key2 ? null : (
        <span className={'absolute right-2 top-2 bg-white border border-black py-1 px-2 rounded-md cursor-pointer z-10'}>
          {logoComponent ? logoComponent : <IconYoutube />}
        </span>
      )}
      {renderContent()}
      {focusPlayVideoKey == key2 ? null : (
        <div className={`${styles.mask}`}>
          <IconPlay />
        </div>
      )}
    </div>
  );
}
